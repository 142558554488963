import { useState } from 'react';
import { useDispatch } from 'react-redux';
import EditableInput from "../../../../utils/EditableInput";
import ImageUpload from '../../../../utils/ImageUpload';
import { genUUID, Notification } from "../../../../utils/Misc";
import { updateElement } from "../../../../utils/UpdateFunctions";
import { editElement } from "../../../../../actions/UserActions";
import { getDefaultElement } from "../../CampaignAddDefaults";
import SoccerLineUpSelectorCategoryModal from './SoccerLineUpSelectorCategoryModal';

import '../../../../../scss/pages/Poll.scss'

function SoccerLineUpSelectorPlayers({ currentCampaignId, currentPage, currentElement, elementCopy, setElementCopy }) {
	const dispatch = useDispatch();
	const [assignModal, setAssignModal] = useState(null);
	const { players, categories } = elementCopy;
	const update = (key, value, extra) => {
		for (let i = 0; i < players.length; i++) {
			if (players[i]['id'] == extra.option_id) {
				elementCopy.players[i][key] = value;
			}
		}
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const add = () => {
		elementCopy.players.push(getDefaultElement('soccerLineUpPlayer'));
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const duplicateOption = option => {
		const newOption = { ...option };
		newOption.id = genUUID()
		newOption.image = null;
		elementCopy.players.push(newOption);
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const deleteOption = option_id => {
		if (elementCopy.players.length <= 11) {
			Notification('There should be at least 11 options for football.', 'warning')
			return
		}
		const o = elementCopy.players.filter(current => current.id !== option_id)
		elementCopy.players = o;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const getCategoryTitle = category_id => {
		return categories.find( ct => ct.id === category_id).title
	}
	return (
		<div className="mtop--20">
			{ players.map((option, idx) => {
				return (
					<div className = "poll__option" key={`player__${idx}`}>
						<div className="poll__option-actions">
							<div className="poll__option-action" onClick={() => duplicateOption(option)}>
								<span className="material-icons">
									content_copy
								</span>
							</div>
							<div className="poll__option-action">
								<span className="material-icons text--danger poll__option-delete" onClick={() => deleteOption(option.id)}>
									delete
								</span>
							</div>
						</div>
						<div className="poll__option-img">
							<ImageUpload dimensions={'W:170px x H:250px'}  extra={{ option_id: option.id }} campaign_id={currentCampaignId} file={option.image} item="image" updateAction={update} />
						</div>
						<div className="poll__option-text">
							<div>
								<EditableInput extra={{ option_id: option.id }} item="name" type="text" startValue={option.name} updateAction={update} placeholder={'Type the name...'} />
							</div>
							<div onClick={() => setAssignModal(option)}>
								{ option.categories.map((category, idx) => {
									return (
										<span className="btn btn-primary-pastel btn-rounded btn-sm" key={`assigned__category${idx}`} >
											#
											&nbsp;
											{getCategoryTitle(category)}
										</span>
									)
								})}
								{ option.categories.length === 0 && <span className="font--11 text--muted">No category selected for this player</span>}
							</div>
							<button onClick={() => setAssignModal(option)} className="btn btn-warning-pastel btn-rounded btn-sm mtop--20">Assign category</button>
						</div>
					</div>
				)
			})}
			<div className="text--center">
				<button className="btn btn-primary-pastel mtop--10" onClick={() => add()}>
					<span className="material-icons font--20 vm--align">add</span>
					Add Player
				</button>
			</div>
			{assignModal && <SoccerLineUpSelectorCategoryModal categories={categories} player={assignModal} update={update} closeCb={setAssignModal}  />}
		</div>
	);
}

export default SoccerLineUpSelectorPlayers;
