
const initialState = {
	organizations: [],
	incomingRequests: [],
}
export default function OrganizationsReducer(state = initialState, action) {
	switch (action.type) {
		case 'ORGANIZATIONS_ADD':
			return {
				...state,
				organizations: [...state.organizations, ...[action.data]]
			}
		case 'ORGANIZATIONS_SET':
			return {
                ...state,
                organizations: [...action.data]
            }
		case 'ORGANIZATIONS_CLEAR':
			return {
				...state,
				organizations: []
			}
		case 'ORGANIZATIONS_DELETE_ORGANIZATION':
			return {
				...state, 
				organizations: state.organizations.filter((organization) => organization.id !== action.data)
			}
		case 'ORGANIZATIONS_ADD_INCOMING_REQUEST':
			return {
				...state,
				incomingRequests: [...state.incomingRequests, ...[action.data]]
			}
		case 'ORGANIZATIONS_EDIT_INCOMING_REQUEST':
			return {
				...state,
				incomingRequests: state.incomingRequests.map((request) => {
					if (request.id === action.data.id) {
						return action.data
					} else {
						return request
					}
				})
			}
		case 'ORGANIZATIONS_CLEAR_INCOMING_REQUESTS':
			return {
				...state,
				incomingRequests: []
			}
		default:
			return state;
	}
}