import { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import ColorPallete from "../../../utils/ColorPallete";
import RichText from "../../../utils/RichText";
import EditableInput from "../../../utils/EditableInput";
import Dropdown from "../../../utils/Dropdown";
import {genUUID} from "../../../utils/Misc";
import {updateElement} from "../../../utils/UpdateFunctions";
import { editElement } from "../../../../actions/UserActions";
import { deleteField } from "firebase/firestore";
import imgChoice1 from '../../../../images/imgChoice1.png';
import imgChoice2 from '../../../../images/imgChoice2.png';
import imgChoice3 from '../../../../images/imgChoice3.png';
import ImageUpload from "../../../utils/ImageUpload";

/* Fields have to become options */
function CategorizedPollElementOptions() {
	const dispatch = useDispatch();
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const { categories, marginTop, background, textColor} = elementCopy;
	const update = (key, value) => {
		if(key == 'marginTop') value += 'px';
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage.id, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const updateCategory = (key, value, extra) => {
		/* let category =
		for (let i = 0; i < options.length; i++) {
			if(options[i]['id'] == extra.option_id){
				currentElement.options[i][key]  = value;
			}
		}
		updateElement(currentCampaignId, currentPage.id, currentElement.id, currentElement, dispatch, editElement) */
	}
	const updateOption = (key, value, extra) => {
		/* let category =
		for (let i = 0; i < options.length; i++) {
			if(options[i]['id'] == extra.option_id){
				currentElement.options[i][key]  = value;
			}
		}
		updateElement(currentCampaignId, currentPage.id, currentElement.id, currentElement, dispatch, editElement) */
	}
	const addCategory = (data) => {
		data.id = genUUID();
		elementCopy.categories.push(data);
		updateElement(currentCampaignId, currentPage.id, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const addOption = (data) => {
		/* data.id = genUUID();
		currentElement.options.push(data);
		updateElement(currentCampaignId, currentPage.id, currentElement.id, currentElement, dispatch, editElement); */
	}
	const deleteOption = option_id => {
		/* const o = currentElement.fields.filter( current => current.id !== option_id )
		currentElement.options = o;
		updateElement(currentCampaignId, currentPage.id, currentElement.id, currentElement, dispatch, editElement) */
	}
	return (
		<div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Categories</label>
				<div className="mtop--15">
					{	categories.map((category, index) => {
							return (
								<div className="campaign__option categorized__poll-category">
									<div className="mtop--10 flex flex--row">
										<EditableInput item="title" type="text" startValue={category.title} updateAction={updateCategory} placeholder={'Type here...'} />
										<button className="btn btn-secondary mleft--10 float--right">
											<span className="material-icons font--20 text--danger">
												delete
											</span>
										</button>
									</div>
									<div className="mtop--20" key={`category_${index}`}>
										{ category.options.map((option, index) => {
											return (
												<div className="registration__element" key={`pollOption_${index}`}>
													<div className="flex flex--row">
														<div>
															<ImageUpload extra={{category_id: category.id, option_id: option.id}} campaign_id={currentCampaignId} file={option.image} item="image" updateAction={updateOption} />
														</div>
														<div className="registration__element-inp mleft--10">
															<EditableInput extra={{category_id: category.id, option_id: option.id}} item="label" type="text" startValue={option.label} updateAction={updateOption} placeholder={'Type here...'} />
														</div>
														<span className="material-icons font--25 text--danger mtop--10" onClick={() => deleteOption(option.id)}>
															clear
														</span>
													</div>
												</div>
											)
										})}
										<div className="text--center">
											<button className="btn btn-primary-pastel mtop--10" onClick={() => addOption()}>
												<span className="material-icons font--20 vm--align">add</span>
												Add Option
											</button>
										</div>
									</div>
								</div>
							)
						})
					}
					<button className="btn btn-primary btn-100 mtop--10" onClick={() => addCategory()}>
						<span className="material-icons font--20 vm--align">add</span>
						Add Category
					</button>
				</div>
			</div>
			<div className="mtop--20">
				<EditableInput type="checkbox" isSwitch={false} startValue={textColor} updateAction={update}/>
				<label className="text--bold">Allow multiple answers</label>
			</div>
			<div className="mtop--10">
				<EditableInput type="checkbox" isSwitch={false} startValue={textColor} updateAction={update}/>
				<label className="text--bold">Answer is required to procceed</label>
			</div>
			<div className="campaign__option mtop--20">
				<label className="text--bold">Answers layout</label>
				<div className="flex flex--row flex--wrap">
					<div className="card__check-option">
						<div className="card__check-radio">
							<span className="material-icons">
								done
							</span>
						</div>
						<img src={imgChoice1} className="img__responsive"/>
					</div>
					<div className="card__check-option">
						<div className="card__check-radio">
							<span className="material-icons">
								done
							</span>
						</div>
						<img src={imgChoice2} className="img__responsive"/>
					</div>
					<div className="card__check-option card__check-optionActive">
						<div className="card__check-radio">
							<span className="material-icons">
								done
							</span>
						</div>
						<img src={imgChoice3} className="img__responsive"/>
					</div>
				</div>
				<div className="mtop--20">
					<label className="text--bold">Image size</label>
					<div className="mtop--10 flex flex--row flex--wrap">
						<div className="btn btn-secondary">Small</div>
						<div className="btn btn-secondary">Medium</div>
						<div className="btn btn-primary">Large</div>
					</div>
				</div>
			</div>
			<div className="campaign__option mtop--20">
				<label className="text--bold">Top Margin (pixels)</label>
				<div className="font--12 text--muted">The space the element has from the top</div>
				<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
			</div>
			<div className="flex flex--row flex--wrap mtop--20">
				<div className="campaign__option campaign__option-50">
					<label className="text--bold">Text color</label>
					<div className="campaign__option-act">
						<ColorPallete item="textColor" value={textColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="campaign__option campaign__option-50 mleft--5">
					<label className="text--bold">Background color</label>
					<div className="campaign__option-act">
						<ColorPallete item="background" value={background} type={'rgba'} updateAction={update} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default CategorizedPollElementOptions;
