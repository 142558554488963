import { useState, useEffect, useCallback } from "react";
import {useSelector} from 'react-redux';
import useDebounce from "../../../utils/UseDebounce";
import 'react-tippy/dist/tippy.css';
import { getRegistrationResults } from './ResultsCreator';
import RegistrationTableResults from "./RegistrationTableResults";

function RegistrationResults() {
	const {resultsCurrentElement, unMappedUserResults} = useSelector(state => state.user);
	const [mappedResults, setMappedResults] = useState([])
	const [calculatedResults, setCalculatedResults] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [loading, setLoading] = useState(false);
	const throttledSearchTerm = useDebounce(searchTerm, 600);

	const mapResults = useCallback(async () => {
		setLoading(true);
		const data = await getRegistrationResults(unMappedUserResults, resultsCurrentElement.rowPlaceholder);
		setMappedResults(data);
		setLoading(false);
	}, [resultsCurrentElement, unMappedUserResults])

	const calculateMappedResults = useCallback(() => {
		if (!mappedResults) return ;
		let o = [];
		if (throttledSearchTerm) {
			o = mappedResults.filter((row) => {
				return row.find(cell => {
					return cell.toString().toLowerCase().search(throttledSearchTerm.toLowerCase()) >= 0
				});
			})
		} else {
			o = mappedResults
		}
		setCalculatedResults(o)
	}, [throttledSearchTerm, mappedResults])
	useEffect(() => {
		calculateMappedResults();
	}, [mappedResults, throttledSearchTerm, calculateMappedResults])
	useEffect(() => {
		if(resultsCurrentElement && unMappedUserResults) mapResults();
	}, [resultsCurrentElement, unMappedUserResults, mapResults])
	return (
		<div className="registration__results">
			<div className="inner mtop--10">
				<div>
					<input type="text" placeholder="Search by name, email..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
				</div>
				<div className="total__results">
					<span className="material-icons">
						how_to_vote
					</span>
					<span className='vm--align mtop--5 animate__animated animate__tada'>
						{calculatedResults.length}
						<div className="font--15 text--muted" style={{marginTop: '-15px'}}>
							Votes
						</div>
					</span>
				</div>
				<div className="mtop--20">
					<RegistrationTableResults header={resultsCurrentElement.tableHeader} rows={calculatedResults} searchTerm={searchTerm} />
					{ loading &&
						<div className="text--center">
							<span className="loader loader__md"></span>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default RegistrationResults;
