import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import ColorPallete from "../../../utils/ColorPallete";
import RichText from "../../../utils/RichText";
import EditableInput from "../../../utils/EditableInput";
import { genUUID } from "../../../utils/Misc";
import { getDefaultElement } from '../CampaignAddDefaults';
import { updateElement } from "../../../utils/UpdateFunctions";
import { editElement } from "../../../../actions/UserActions";
import imgChoiceRow from '../../../../images/imageChoiceRow.png';
import imgChoiceColumn from '../../../../images/imageChoiceColumn.png';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import '../../../../scss/pages/Poll.scss'
import MvpElementOptionsOption from "./MvpElementOptionsOption";

function MvpElementOptions() {
	const dispatch = useDispatch();
	const { currentCampaignId, currentPage, currentElement } = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const { background, question, marginTop, textColor, min, max, layout, optionsTextColor, optionsBackgroundColor, options } = elementCopy;
	const [dragLoader, setDragLoader] = useState(false);

	const update = (key, value) => {
		if (key == 'marginTop') value += 'px';
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const addOption = () => {
		const d = getDefaultElement('mvpOption')
		elementCopy.options.push(d);
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const onSortEnd = (data) => {
		setDragLoader(true);
		const toBeMoved = options[data.oldIndex];
		let filteredOptions = options.filter((option) => option.id != toBeMoved.id)
		filteredOptions.splice(data.newIndex, 0, toBeMoved);
		elementCopy['options'] = filteredOptions;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
		setTimeout(() => {
			setDragLoader(false);
		}, 1000)
	}
	const SortableItem = SortableElement(({ option }) => (
		<li style={{ width: '100%' }}>
			<MvpElementOptionsOption currentCampaignId={currentCampaignId} currentPage={currentPage} currentElement={currentElement} elementCopy={elementCopy} setElementCopy={setElementCopy} option={option} />
		</li>
	));
	const SortableList = SortableContainer(({ items }) => {
		return (
			<ul className="sortable__container sortable__inline">
				{
					items.map((value, index) => (
						<SortableItem key={`imgChoiceOption_${index}`} i={index} index={index} option={value} />
					))
				}
			</ul>
		);
	});
	return (
		<div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Question</label>
				<RichText item={'question'} value={question} updateAction={update} />
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Options</label>
				<div className="mtop--15 image__choice-options">
					{dragLoader && <div className="page__selector-loader text--center"><span className="loader"></span></div>}
					{!dragLoader && <SortableList axis="y" useDragHandle items={options} onSortEnd={onSortEnd} />}
					<button className="btn btn-primary-soft btn-100 mtop--10" onClick={() => addOption()}>
						<span className="material-icons font--20 vm--align">add</span>
						Add Option
					</button>
				</div>
				<div className="flex flex--row mtop--20">
					<div className="flex--50">
						<label className="text--bold font--12">Minimum Selections</label>
						<EditableInput type="number" item="min" startValue={min} updateAction={update} />
					</div>
					<div className="flex--50 mleft--15">
						<label className="text--bold font--12">Maximum Selections</label>
						<EditableInput type="number" item="max" startValue={max} updateAction={update} />
					</div>
				</div>
				<div className="flex flex--row mtop--20">
					<div className="flex--50">
						<label className="text--bold font--12">Option Background</label>
						<ColorPallete item="optionsBackgroundColor" value={optionsBackgroundColor} type={'rgba'} updateAction={update} />
					</div>
					<div className="flex--50 mleft--15">
						<label className="text--bold font--12">Option Text Color</label>
						<ColorPallete item="optionsTextColor" value={optionsTextColor} type={'hex'} updateAction={update} />
					</div>
				</div>
			</div>
			<div className="campaign__option mtop--20">
				<label className="text--bold">Answers layout</label>
				<div className="flex flex--row flex--wrap">
					<div className={`card__check-option ${layout == 'row' && 'card__check-optionActive'}`} onClick={() => update('layout', 'row')}>
						<div className="card__check-radio">
							<span className="material-icons">
								done
							</span>
						</div>
						<img src={imgChoiceRow} className="img__responsive img__rounded" />
					</div>
					<div className={`card__check-option ${layout == 'column' && 'card__check-optionActive'}`} onClick={() => update('layout', 'column')}>
						<div className="card__check-radio">
							<span className="material-icons">
								done
							</span>
						</div>
						<img src={imgChoiceColumn} className="img__responsive img__rounded" />
					</div>
				</div>
			</div>
			<div className="campaign__option mtop--20">
				<label className="text--bold">Top Margin (pixels)</label>
				<div className="font--12 text--muted">The space the element has from the top</div>
				<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
			</div>
			<div className="flex flex--row flex--wrap mtop--20">
				<div className="campaign__option campaign__option-50">
					<label className="text--bold">Text color</label>
					<div className="campaign__option-act">
						<ColorPallete item="textColor" value={textColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="campaign__option campaign__option-50 mleft--5">
					<label className="text--bold">Background color</label>
					<div className="campaign__option-act">
						<ColorPallete item="background" value={background} type={'rgba'} updateAction={update} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default MvpElementOptions;
