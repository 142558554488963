import {useState, useEffect, useCallback, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import CampaignDateSelector from '../../utils/CampaignDateSelector';
import { modifyCampaignData } from '../../../firebaseActions.mjs';
import { editCampaign } from '../../../actions/UserActions'

function formatDate(timestamp){
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth()+1).padStart(2, '0');
    const year = String(date.getFullYear()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

function ChangeCampaignDateTime({endDate}){
    const dispatch = useDispatch()
    const loadRef = useRef(null);
    const [timestampDate, setTimestampDate] = useState(endDate)
    const [readableDate, setReadableDate] = useState(formatDate(endDate));
    const [showModal, setShowModal] = useState(false);
    const [expired, setExpired] = useState(false)
    const {currentCampaignId} = useSelector( state => state.user );
    const update = (newDate) => {
        const o = {'endDate': newDate}
        modifyCampaignData(currentCampaignId, o)
        .then(() => {
            dispatch(editCampaign(o))
        })
	}
    const calculate = useCallback(() => {
        if (loadRef.current){
            const u = (new Date(timestampDate)).getTime();
            if (u !== endDate) update(u)
        }
        loadRef.current = true;
    }, [timestampDate])
    
    useEffect(() => {
        if(!timestampDate){
            update(null)
        }else{
            calculate()
        }
    }, [timestampDate, calculate])

    useEffect(() => {
        if (!timestampDate) return;
        const u = (new Date(timestampDate)).getTime();
        setReadableDate(formatDate(timestampDate))
        if( Date.now() > u){
            setExpired(true)
        }else{
            setExpired(false)
        }
    }, [timestampDate])
    return (
        <>
            { endDate &&
                <>
                    { expired &&
                        <button className="btn btn-danger-soft btn-md" onClick={() => setShowModal(true)}>
                            Expired: {readableDate}
                        </button>
                    }
                    { !expired &&
                        <button className="btn btn-warning-pastel btn-md" onClick={() => setShowModal(true)}>
                            Expires: {readableDate}
                        </button>
                    }
                </>
            }
            { !endDate &&
                <button className="btn btn-success-pastel btn-md" onClick={() => setShowModal(true)}>
                    <span className="material-icons font--20 vm--align mright--5">
                        edit_calendar
                    </span>
                    <span className="topbar__actionItem">Set expiration date</span>
                </button>
            }
            { showModal &&
                <div className="modal-overlay active">
                    <div className="modal active animate__animated animate__slideInRight modal--50">
                        <div className="modal__header">
                            <div className="modal__header-title mtop--10">
                                <h3>Campaign expiration date</h3>
                            </div>
                            <div className="close-modal modal--x" onClick={ () => { setShowModal(false) }}>
                                <svg viewBox="0 0 20 20">
                                    <path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
                                </svg>
                            </div>
                        </div>
                        <div className="modal-content">
                            <div className="modal__body">
                                <CampaignDateSelector date={timestampDate} timezone={undefined} setDate={setTimestampDate} setTimezone={undefined} />
                            </div>
                            <div className="modal__footer text--right ">
                                <button className="btn btn-secondary" onClick={ () => { setShowModal() }}>
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ChangeCampaignDateTime