import EditableInput from "../../../../utils/EditableInput"
import ScratchDateTimePicker from "./ScratchDateTimePicker";
import moment from "moment";
import { getDefaultElement } from "../../CampaignAddDefaults";

function ScratchPrizeAdvancedOptions({prize, update, extra}) {
	const updateStrategy = (key, value, extra) => {
        if(key === 'strategy'){
            if(value){
                prize.strategy = getDefaultElement(value);
            }else{
                prize.strategy = null;
            }
        }else{
            prize.strategy[key] = value;
        }    
        update('strategy', prize.strategy, extra);
    }
    function getTimestamp(dateString) {
        const date = moment(dateString, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").toDate();
        const timestamp = date.getTime();
        return timestamp;
    }
    const addDate = () => {
        const currentDate = new Date();
        prize.strategy.dates.push({
            'from': getTimestamp(currentDate),
            'quantity': 1,
        })
        update('strategy', prize.strategy, extra);
    }
    const updateDate = (key, value, dateExtra) => {
        prize.strategy.dates.forEach((date, idx) => {
            if (dateExtra.idx === idx) {
                prize.strategy.dates[idx][key] = value;
            }
        })
        update('strategy', prize.strategy, extra);
    }
    const deleteDate = (targetIdx) => {
        prize.strategy.dates = prize.strategy.dates.filter((d, idx) => idx !== targetIdx)
        update('strategy', prize.strategy, extra);
    }
    const getStrategyDescriptionBasedOnType = () => {
        switch (prize.strategy?.strategy) {
            case 'spread':
                return (<div className="text--muted font--12">Prizes will be spread evenly between the two specified dates</div>)
            case 'active':
                return (<div className="text--muted font--12">Prizes are active from - to</div>)
            case 'reserve':
                return (
                    <div className="text--muted font--12">
                        Prizes are reserved based on a date and a quantity.
                        <br />
                        Eg. reserve 10 tickets of this type until the given date.
                    </div>
                )
            default:
                break;
        }
    }
    const canUseStrategy = (type) => {
        switch (type) {
            case 'spread':
			case 'reserve':
                return prize.stock !== null
            case 'active':
                return true;
            default:
                return true
        }
    }
	return (
		<>
			<div className="card card__secondary">
				<div className="mtop--10">
					<label className="text--bold">Description</label>
					<EditableInput extra={extra} item="description" type="textarea" startValue={prize.description} updateAction={update} placeholder={'Type here...'} />
				</div>
			</div>
			<div className="card card__secondary">
				<>
					<div className="form__group">
						<div className="form__group-label">
							Probability
							<div className="text--muted font--12">The probability of drawing this prize if the user can draw a prize</div>
						</div>
						<div className="form__group-content form__group-contentXl">
							<div className="form__group-check">
								<div className={`${prize.probability === null && 'form__group-checkActive'}`} onClick={() => update('probability', null, extra)}>
									Auto
								</div>
								<div className={`${prize.probability !== null && 'form__group-checkActive'}`}>
									<EditableInput extra={extra} item="probability" type="number" startValue={prize.probability ? prize.probability / 10000 : 0} updateAction={update} placeholder={'50'} />
								</div>
							</div>
						</div>
					</div>
					<div className="form__group">
						<div className="form__group-label">
							Stock
						</div>
						<div className="form__group-content form__group-contentXl">
							<div className="form__group-check">
								<div className={`${prize.stock === null && 'form__group-checkActive'} font--14`} onClick={() => update('stock', null, extra)}>
									Unlimited
								</div>
								<div className={`${prize.stock !== null && 'form__group-checkActive'} font--14`} onClick={() => update('stock', 10, extra)}>
									Limited
								</div>
								<div className={`${prize.stock !== null && 'form__group-checkActive'}`}>
									<EditableInput extra={extra} item="stock" type="number" min={1} startValue={prize.stock} updateAction={update} placeholder={'50'} />
								</div>
							</div>
						</div>
					</div>
					<div className='form__group-heading mtop--20'>Advanced Distribution</div>
					<div className="form__group">
						<div className="form__group-label">
							Strategy
							{getStrategyDescriptionBasedOnType()}
						</div>
						<div className="form__group-content form__group-contentXl">
							<div className="scratch-prizeStrategies">
								<div className={`scratch-prizeStrategiesItem ${prize.strategy === null && 'scratch-prizeStrategiesItemActive'}`} onClick={() => updateStrategy('strategy', null, extra)}>
									None
								</div>
								<div disabled={!canUseStrategy('spread')} className={`scratch-prizeStrategiesItem ${prize.strategy?.strategy === 'spread' && 'scratch-prizeStrategiesItemActive'}`} onClick={() => updateStrategy('strategy', 'spread', extra)}>
									Spread
								</div>
								<div disabled={!canUseStrategy('reserve')}  className={`scratch-prizeStrategiesItem ${prize.strategy?.strategy === 'reserve' && 'scratch-prizeStrategiesItemActive'}`} onClick={() => updateStrategy('strategy', 'reserve', extra)}>
									Reserve
								</div>
								<div disabled={!canUseStrategy('active')}  className={`scratch-prizeStrategiesItem ${prize.strategy?.strategy === 'active' && 'scratch-prizeStrategiesItemActive'}`} onClick={() => updateStrategy('strategy', 'active', extra)}>
									Active
								</div>
							</div>
						</div>
					</div>
					{prize.strategy?.strategy &&
						<div>
							{prize.strategy.strategy === 'spread' &&
								<>
									<div className="form__group">
										<div className="form__group-label">
											From
										</div>
										<div className="form__group-content form__group-contentXl">
											<ScratchDateTimePicker itemKey="from" initialValue={prize.strategy?.from} update={updateStrategy} extra={extra}/>
										</div>
									</div>
									<div className="form__group">
										<div className="form__group-label">
											To
										</div>
										<div className="form__group-content form__group-contentXl">
											<ScratchDateTimePicker itemKey="to" initialValue={prize.strategy?.to} update={updateStrategy} extra={extra} />
										</div>
									</div>
								</>
							}
							{prize.strategy.strategy === 'active' &&
								<>
									<div className="form__group">
										<div className="form__group-label">
											Open
										</div>
										<div className="form__group-content form__group-contentXl">
											<ScratchDateTimePicker itemKey="open" initialValue={prize.strategy?.open} update={updateStrategy} extra={extra} />
										</div>
									</div>
									<div className="form__group">
										<div className="form__group-label">
											Close
										</div>
										<div className="form__group-content form__group-contentXl">
											<ScratchDateTimePicker itemKey="close" initialValue={prize.strategy?.close} update={updateStrategy} extra={extra} />
										</div>
									</div>
								</>
							}
							{prize.strategy.strategy === 'reserve' &&
								<div className="mtop--20">
									<div className="flex flex--row justify--spread">
										<div className="form__group-label">
											Dates
										</div>
										<div className="btn btn-primary btn-md" onClick={addDate}>
											<span className="material-icons">add</span>
											Add
										</div>
									</div>
									<div className="form__group-content">
										{ prize?.strategy?.dates?.map((date, idx) => {
											return (
												<div className="wheel__prize-date" key={`strategy__date-${idx}`}>
													<div className="wheel__prize-dateDelete" onClick={() => deleteDate(idx)}>
														<span className="material-icons font--20 text--danger">clear</span>
													</div>
													<>
														<div className="form__group">
															<div className="form__group-label">
																From
															</div>
															<div className="form__group-content form__group-contentXl">
																<ScratchDateTimePicker itemKey="from" initialValue={date.from} update={updateDate} extra={{ idx: idx }} />
															</div>
														</div>
														<div className="form__group">
															<div className="form__group-label">
																Quantity
															</div>
															<div className="form__group-content">
																<EditableInput max={prize.stock} extra={{ idx: idx }} item="quantity" type="number" startValue={date.quantity} updateAction={updateDate} placeholder={'50'} />
															</div>
														</div>
													</>
												</div>
											)
										})}
									</div>
								</div>
							}
						</div>
					}
				</>
			</div>
		</>
	)
}

export default ScratchPrizeAdvancedOptions