import { useState, useEffect, useCallback } from "react";
import { useSelector } from 'react-redux';
import useDebounce from "../../../utils/UseDebounce";
import 'react-tippy/dist/tippy.css';
import { getScratchResults } from './ResultsCreator';
import {Tooltip} from 'react-tippy';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { SwiperSlide } from 'swiper/react';
import HorizontalSlider from "../../../utils/HorizontalSlider";
import ScratchResultsTable from './ScratchResultsTable';

function ScratchResults() {
	const { resultsCurrentElement, unMappedUserResults} = useSelector(state => state.user);
	const [mappedResults, setMappedResults] = useState([])
	const [stats, setStats] = useState([])
	const [calculatedResults, setCalculatedResults] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const throttledSearchTerm = useDebounce(searchTerm, 600);
	const [onlyValidAnswers, setOnlyValidAnswers] = useState(false);
	const [loading, setLoading] = useState(false);

	const mapResults = useCallback(async () => {
		setLoading(true);
		const results = await getScratchResults(unMappedUserResults, resultsCurrentElement, onlyValidAnswers)
		setMappedResults(results[0]);
		setStats(results[1]);
		setLoading(false);
	}, [unMappedUserResults, resultsCurrentElement, onlyValidAnswers])
	
	const calculateMappedResults = useCallback(() => {
		if (!mappedResults) return;
		let o = [];
		if (throttledSearchTerm) {
			o = mappedResults.filter((row) => {
				return row.find(cell => {
					return cell.toString().toLowerCase().search(throttledSearchTerm.toLowerCase()) >= 0
				});
			})
		} else {
			o = mappedResults
		}
		setCalculatedResults(o)
	}, [throttledSearchTerm, mappedResults])

	const getLosers = () => {
		return calculatedResults.length > 0 ? calculatedResults.filter((element) => element.includes('No prize')).length : 0;
	}
	const getPrizePercentage = (prize) => {
		const prizeCount = prize ? prize.prizeCount : getLosers();
		return `${((prizeCount / calculatedResults.length) * 100).toFixed(1)}%`;
	}
	useEffect(() => {
		calculateMappedResults();
	}, [mappedResults, throttledSearchTerm, calculateMappedResults])
	
	useEffect(() => {
		if (resultsCurrentElement && unMappedUserResults) mapResults();
	}, [resultsCurrentElement, unMappedUserResults, onlyValidAnswers, mapResults])
	return (
		<div>
			<>
				<div className="flex flex--row flex--wrap">
					<Tooltip title="This options works only if you have a registration element. Verified answers are the ones that have at least a name or a surname" position="bottom">
						<div className="md__input mtop--10 mright--10" style={{width: '300px'}}>
							<input type="checkbox" className="switch" checked={onlyValidAnswers} onChange={(e) => setOnlyValidAnswers(e.target.checked)}/>
							<label>Only verified answers</label>
						</div>
					</Tooltip>
					<div className="float--right">
						<input type="text" placeholder="Search by name, email..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
					</div>
				</div>
				<div className="">
					<HorizontalSlider items={stats} css={'prize__card-wrapper'} itemWidth={250}>
						{stats?.map((prize, idx)  => {
							return (
								<SwiperSlide className="prize__card" key={`prize__stat${idx}`}>
									<label className="prize__card-label width ellipse two-lines">
										<span className="uppercase">{prize.text}</span>
									</label>
									<div className="prize__card-count">
										<label className="text--bold font--35">{prize.prizeCount}</label>
										<br />
										<span style={{marginTop: '-10px'}}>
											/ {prize.stock || '∞'}	
										</span>
									</div>
									<div className="bar__wrapper">
										<div className='bar' style={{height: getPrizePercentage(prize)}}>
											<label>{getPrizePercentage(prize)}</label>
										</div>
									</div>
								</SwiperSlide>
							)
						})}
						<SwiperSlide className="prize__card prize__card-primaryLight">
							<label className="">
								<span className="uppercase">No prize</span>
							</label>
							<div className="prize__card-count">
								<label className="text--bold font--35">{getLosers()}</label>
							</div>
							<div className="bar__wrapper">
								<div className='bar' style={{height: getPrizePercentage()}}>
									<label>{getPrizePercentage()}</label>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide className="prize__card prize__card-primary">
							<label className="">
								<span className="uppercase">TOTAL</span>
							</label>
							<div className="prize__card-count">
								<label className="text--bold font--35">{calculatedResults.length}</label>
							</div>
						</SwiperSlide>
					</HorizontalSlider>
				</div>
				<div className="mtop--20 table__wrapper card" style={{margin: '10px 5px'}}>
					<ScratchResultsTable header={resultsCurrentElement.tableHeader} rows={calculatedResults} searchTerm={searchTerm} />
					{ loading &&
						<div className="text--center">
							<span className="loader loader__md"></span>
						</div>
					}
				</div>
			</>
		</div>
	);
}

export default ScratchResults;
