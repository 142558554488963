export const setUser = (data) => {
	return {
		type: 'USER_SET',
		data: data
	}
}
export const setCampaign = (data) => {
	return {
		type: 'USER_ADD_CAMPAIGN',
		data: data
	}
}
export const clearCampaigns = () => {
	return {
		type: 'USER_CLEAR_CAMPAIGNS',
		data: null
	}
}
export const setCampaigns = (data) => {
	return {
		type: 'USER_SET_CAMPAIGNS',
		data: data
	}
}
export const setCurrentCampaign = (data) => {
	return {
		type: 'USER_SET_CURRENT_CAMPAIGN',
		data: data
	}
}
export const setProjectors = (data) => {
	return {
		type: 'USER_SET_PROJECTORS',
		data: data,
	}
}
export const setCurrentPage = (data) => {
	return {
		type: 'USER_SET_CURRENT_PAGE',
		data: data
	}
}
export const setCurrentElement = (data) => {
	return {
		type: 'USER_SET_CURRENT_ELEMENT',
		data: data
	}
}
export const setCurrentCampaignId = (data) => {
	return {
		type: 'USER_SET_CURRENT_CAMPAIGNID',
		data: data
	}
}
export const editElement = (data, id) => {
	return {
		type: 'USER_EDIT_ELEMENT',
		data: {
			data: data,
			id: id
		}
	}
}
export const addElementStore = (data) => {
	return {
		type: 'USER_ADD_ELEMENT',
		data: data
	}
}
export const removeElementStore = (element_id) => {
	return {
		type: 'USER_REMOVE_ELEMENT',
		data: element_id
	}
}
export const setCampaignPages = (data) => {
	return {
		type: 'USER_SET_CAMPAIGN_PAGES',
		data: data
	}
}
export const removePageStore = (page_id) => {
	return {
		type: 'USER_REMOVE_PAGE',
		data: page_id
	}
}
export const reorderPageElementsStore = (data) => {
	return {
		type: 'USER_REORDER_ELEMENTS',
		data: data
	}
}
export const reorderCampaignPagesStore = (data) => {
	return {
		type: 'USER_REORDER_PAGES',
		data: data
	}
}
export const editCampaign = (data) => {
	return {
		type: 'USER_EDIT_CAMPAIGN',
		data: data
	}
}
export const deleteCampaignStore = (data) => {
	return {
		type: 'USER_DELETE_CAMPAIGN',
		data: data
	}
}
export const setColorPalleteColors = (colors, campaign_id) => {
	return {
		type: 'USER_SET_COLOR_PALLETE',
		data: {colors: colors, campaign_id: campaign_id}
	}
}
export const addColorToPallete = (color, type, campaign_id) => {
	return {
		type: 'USER_ADD_TO_PALLETE',
		data: {color: color, type: type, campaign_id: campaign_id}
	}
}
export const setCurrentResultsElement = (data) => {
	return {
		type: 'USER_RESULTS_SET_CURRENT_ELEMENT',
		data: data
	}
}
export const addUnMappedResult = (data) => {
	return {
		type: 'USER_ADD_UNMAPPED_RESULT',
		data: data
	}
}
export const deleteUnMappedResult = (data) => {
	return {
		type: 'USER_DELETE_UNMAPPED_RESULT',
		data: data
	}
}
export const addUnMappedPost = (data) => {
	return {
		type: 'USER_ADD_UNMAPPED_POST',
		data: data
	}
}
export const deleteUnMappedPost = (data) => {
	return {
		type: 'USER_DELETE_UNMAPPED_POST',
		data: data
	}
}
export const setCampaignLocked = (data) => {
	return {
		type: 'USER_SET_CAMPAIGN_LOCKED',
		data: data
	}
}