import { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import ColorPallete from "../../../utils/ColorPallete";
import RichText from "../../../utils/RichText";
import EditableInput from "../../../utils/EditableInput";
import Dropdown from "../../../utils/Dropdown";
import {genUUID} from "../../../utils/Misc";
import {updateElement} from "../../../utils/UpdateFunctions";
import { editElement } from "../../../../actions/UserActions";
import {Notification} from '../../../utils/Misc';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { basicFieldOptions, businessFieldOptions } from './Registration/RegistrationFieldOptions';
import RegistrationElementFieldOptions from "./Registration/RegistrationElementFieldOptions";
import 'react-phone-number-input/style.css'

function RegistrationElementOptions() {
	const dispatch = useDispatch();
	const [dragLoader, setDragLoader] = useState(false);
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const {background, footer, header, marginTop, submitText, textColor, fields} = elementCopy;

	const update = (key, value) => {
		if(key == 'marginTop') value += 'px';
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const updateField = (key, value, extra) => {
		for (let i = 0; i < fields.length; i++) {
			if(fields[i]['id'] == extra.field_id){
				elementCopy.fields[i][key]  = value;
			}
		}
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const addField = (data) => {
		const tmpNewField = {...data};
		tmpNewField.id = genUUID();
		delete tmpNewField.displayLabel;
		delete tmpNewField.icon;
		elementCopy.fields.push(tmpNewField);
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const deleteField = field_id => {
		if(elementCopy.fields.length == 1){
			Notification("This element should have at least one field.");
			return false;
		}
		const o = elementCopy.fields.filter( current => current.id !== field_id )
		elementCopy.fields = o;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const updateFieldOption = (key, value, extra) => {
		const field = elementCopy.fields.find((f) => f.id == extra.field_id)
		field.options[key] = value
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const addFieldOption = (field_id) => {
		const field = elementCopy.fields.find((f) => f.id == field_id)
		if(!field) return;
		field.options.push(`Option ${field.options.length+1}`)
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const deleteFieldOption = (field_id, idx) => {
		const field = elementCopy.fields.find((f) => f.id == field_id)
		if (!field) return;
		if(field.options.length == 1) {
			Notification("This field should have at least one option.");
			return false;	
		}
		field.options.splice(idx, 1);
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const onSortEnd = (data) => {
		setDragLoader(true);
		const toBeMoved = fields[data.oldIndex];
		let filteredFields = fields.filter((field) => field.id != toBeMoved.id)
		filteredFields.splice(data.newIndex, 0, toBeMoved);
		elementCopy['fields'] = filteredFields;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
		setTimeout(() => {
			setDragLoader(false);
		}, 1000)
	}
	const canViewField = (originalField, source) => {
		if(source === 'basic'){
			if (['firstName', 'lastName', 'email'].includes(originalField.type)) {
				return !fields.find((el) => el.type === originalField.type)
			}
		}else{
			return !fields.find((el) => el.customId === originalField.customId)
		}
		return true;
	}
	const SortableItem = SortableElement(({ field, i }) => (
		<RegistrationElementFieldOptions
			field={field}
			updateField={updateField}
			deleteField={deleteField}
			updateFieldOption={updateFieldOption}
			addFieldOption={addFieldOption}
			deleteFieldOption={deleteFieldOption}
		/>
	));
	const SortableList = SortableContainer(({ items }) => {
		return (
			<ul className="sortable__container sortable__inline">
				{
					items.map((value, index) => (
						<SortableItem key={`sortable-${index}`} i={index} index={index} field={value} />
					))
				}
			</ul>
		);
	});
	return (
		<div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Header</label>
				<RichText item={'header'} value={header} updateAction={update} />
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Fields</label>
				<div className="mtop--15">
					{  dragLoader && <div className="page__selector-loader text--center"><span className="loader"></span></div> }
					{ !dragLoader && <SortableList axis="y" useDragHandle items={fields} onSortEnd={onSortEnd} /> }
					<div className="flex flex--row">
						<Dropdown dropup>
							<div className="dropdown-trigger btn-100">
								<button className="btn btn-primary btn-100 mtop--10" onClick={() => false}>
									<span className="material-icons font--20 vm--align">add</span>
									Add Field
								</button>
							</div>
							<div className="dropdown__menu">
								<div className="dropdown__content">
									{basicFieldOptions.map( (f, i) => {
										return(
											canViewField(f, 'basic') &&
											<div className="dropdown__item" key={`df_${i}`} onClick={() => addField(f)}>
												<span className="material-icons font--20 vm--align mright--10">{f.icon}</span>
												<span>{f.displayLabel}</span>
											</div>
										) 
									})}
								</div>
							</div>
						</Dropdown>
						<Dropdown dropup>
							<div className="dropdown-trigger btn-100">
								<button className="btn btn-light btn-100 mtop--10" onClick={() => false}>
									<span className="material-icons font--20 vm--align">add</span>
									Add Business Field
								</button>
							</div>
							<div className="dropdown__menu">
								<div className="dropdown__content">
									{businessFieldOptions.map((f, i) => {
										return (
											canViewField(f, 'business') &&
											<div className="dropdown__item" key={`df_${i}`} onClick={() => addField(f)}>
												<span className="material-icons font--20 vm--align mright--10">{f.icon}</span>
												<span>{f.displayLabel}</span>
											</div>
										)
									})}
								</div>
							</div>
						</Dropdown>
					</div>
				</div>
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Submit button text</label>
				<EditableInput item="submitText" type="text" startValue={submitText} updateAction={update} placeholder="Submit..." />
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Footer</label>
				<RichText item={'footer'} value={footer} updateAction={update} />
			</div>
			<div className="campaign__option">
				<label className="text--bold">Top Margin (pixels)</label>
				<div className="font--12 text--muted">The space the element has from the top</div>
				<EditableInput item="marginTop" type="number" startValue={marginTop ? marginTop.substring(0, marginTop.length - 2) : 0} updateAction={update} placeholder="0" />
			</div>
			<div className="flex flex--row flex--wrap mtop--20">
				<div className="campaign__option campaign__option-50">
					<label className="text--bold">Text color</label>
					<div className="campaign__option-act">
						<ColorPallete item="textColor" value={textColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="campaign__option campaign__option-50 mleft--5">
					<label className="text--bold">Background color</label>
					<div className="campaign__option-act">
						<ColorPallete item="background" value={background} type={'rgba'} updateAction={update} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default RegistrationElementOptions;
