import React from 'react'
import ShareModal from '../../utils/ShareModal';

function CampaignListActions({campaign, duplicate, duplicating, setDeleteCampaignModal}) {
	const getShareUrl = (campaign) => {
		const org = campaign?.organizationId ? `&org=${campaign.organizationId}` : '';
		return `https://app.sportovivo.com/share?campaign=${campaign.id}${org}`;
	}
	return (
		<div className="campaign__list-actions">
			<a className="btn btn-primary-soft btn-sm campaign__list-actionItem" href={"campaigns/" + campaign.id + "/results"}>
				<span className="material-icons font--20 vm--align text--primary">
					checklist
				</span>
				&nbsp;
				View Answers
			</a>
			<div className="btn btn-transparent btn-sm campaign__list-actionItem" onClick={() => duplicate(campaign.id)} disabled={duplicating}>
				{duplicating === campaign.id && <span className="loader loader__sm"></span>}
				{ (duplicating !== campaign.id) &&
					<div className="material-icons font--20 vm--align text--primary">
						content_copy
					</div>
				}
			</div>
			<ShareModal campaign_url={getShareUrl(campaign)} showShare={false} updateAction={() => {}} showOnlyIcon={true} />
			<div className="btn btn-danger-soft btn-sm campaign__list-actionItem" onClick={() => setDeleteCampaignModal(campaign.id)}>
				<span className="material-icons font--20 vm--align text--danger">
					clear
				</span>
			</div>
		</div>
	)
}

export default CampaignListActions