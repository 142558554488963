import { useState } from 'react';
import {
	cancelRequest,
} from '../../../firebaseActions.mjs';
import ConfirmDelete from '../../utils/ConfirmDeleteModal'
import { Notification } from '../../utils/Misc.jsx';

const OrganizationRequests = ({ requests, setRequests, myRole }) => {
	const [revokeModal, setRevokeModal]  = useState(null);
	const getStatus = (request) => {
		const o = {
			'pending': 'text--primary',
			'accepted': 'text--success',
			'rejected': 'text--danger',
			'canceled': 'text--warning',
		}
		return <div className={`font--11 ${o[request]}`}>{request}</div>
	}
	const revoke = (request_id) => {
		cancelRequest(request_id)
		.then((data) => {
			if(data){
				const tmp = [...requests];
				const index = tmp.findIndex(request => request.id === request_id);
				tmp[index].status = 'canceled'
				setRequests(tmp);
				setRevokeModal(null)
			}
		})
		.catch((e) => {
			Notification('The user access could not be revoked. Please try again later.', 'error')
			console.error(e)
		})
	}
	return (
		<div className="mtop--20">
			<table>
				<thead>
					<tr>
						<td>Email</td>
						<td>Status</td>
						<td>Action</td>
					</tr>
				</thead>
				<tbody>
					{requests.map((request, idx) => {
						return (
							<tr className="card" key={`admin__${request.id}`}>
								<td>
									{request.email}
								</td>
								<td className="capitalize">
									{getStatus(request.status)}
								</td>
								<td className="">
									{ ((request.status === 'pending') && (myRole !== 'manager')) &&
										<div className='btn btn-danger-soft btn-sm' onClick={() => setRevokeModal(request.id)}>Revoke</div>
									}
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
			{revokeModal && <ConfirmDelete closeCb={setRevokeModal} confirmCb={revoke} confirmData={revokeModal} text={'Are you sure you want to revoke access for this user?'} />}
		</div>
	)
}

export default OrganizationRequests