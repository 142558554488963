import { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {deletePageElement, movePageElement} from '../../../firebaseActions.mjs';
import {ErrorNotification} from '../../utils/Misc';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import ShareModal from "../../utils/ShareModal.jsx";
import { updateAppThemeIfNeeded, canEdit} from '../../utils/Misc'

/* PREVIEW */
import FreeText from "./elements/FreeText"
import Image from "./elements/Image"
import Link from "./elements/Link"
import NumericPrediction from "./elements/NumericPrediction"
import RegistrationForm from "./elements/RegistrationForm"
import Spacer from "./elements/Spacer"
import SubmitButton from "./elements/SubmitButton"
import Text from "./elements/Text"
import Poll from "./elements/Poll"
import ImageChoice from "./elements/ImageChoice"
import CategorizedPoll from "./elements/CategorizedPoll"
import WheelOfPrizes from "./elements/WheelOfPrizes"
import WheelOfPrizesPreview from "./elements/WheelOfPrizesPreview"
import SoccerLineUpSelector from "./elements/SoccerLineUpSelector"
import SlideShow from "./elements/SlideShow"
import UGC from "./elements/UGC"
import PoweredBy from "./elements/PoweredBy"
import Mvp from "./elements/Mvp"
import Scratch from "./elements/Scratch";
import IFrame from "./elements/IFrame";

import {setCurrentElement, removeElementStore, reorderPageElementsStore} from "../../../actions/UserActions";
import DeleteElementModal from "./DeleteElementModal.jsx";

function CampaignPagePreview(props) {
	const dispatch = useDispatch();
    const [deleteModal, setDeleteModal] = useState();
	const [dragLoader, setDragLoader] = useState();
	const {currentCampaign, currentCampaignId, currentPage, currentElement, preview} = useSelector(state => state.user);
	const { background, mobileBackground, tint, primaryTextColor, secondaryTextColor, primaryColor, secondaryColor, showShare, locked } = currentCampaign;
	const {elements} = currentPage;

	const setData = () => {};
	const onSubmit = (e) => {};
	const globalStyling = {
		"font": currentCampaign ?.font,
        "primaryTextColor":  currentCampaign ?.primaryTextColor,
        "secondaryTextColor":  currentCampaign ?.secondaryTextColor,
        "primaryColor":  currentCampaign ?.primaryColor,
        "secondaryColor":  currentCampaign ?.secondaryColor,
	}
	const getCampaignStyling = () => {
		let d = null;
		if(props.isMobilePreview){
			d = mobileBackground ? mobileBackground : background;
		}else{
			d = background ? background : mobileBackground;
		}
		if(!d) return ;
		const root = document.querySelector(':root');
		const textColor = getComputedStyle(root).getPropertyValue('--app-textColor');
		return {
			"backgroundPosition": d.position ? d.position.x+" "+d.position.y : "center",
			"backgroundRepeat": d.repeat ? d.repeat : 'no-repeat',
			"backgroundSize": d.size ? d.size : 'contain',
			"backgroundImage": d.url ? "url("+d.url+")" : '',
			"color": textColor
		};
	}
	const getCampaignCss = () => {
		return `campaign ${preview}`;
	}
	const getTintStyling = () => {
		if(!tint) return;
		return {
			"background": tint.background ? tint.background : "",
		}
	}
	const getElementClass = element_id => {
		let s = 'campaign__preview-element';
		if(!props.isMobilePreview) return s;
		if(currentElement && (element_id == currentElement.id) ) s+= ' campaign__preview-elementActive';
		return s;
	}
	const deleteElement = element => {
		setDeleteModal(null);
		const pageType = currentPage.type == 'expiredPage' ? 'expiredPage' : 'pages';
		deletePageElement(currentCampaignId, pageType, currentPage.id, element.id)
		.then( data => {
			dispatch( removeElementStore(element.id) )
		})
		.catch( (e) => {
			ErrorNotification('Could not delete this element!', e, {});
			console.error(e, {...e});
		})
	}
	const selectElement = (elementData) => {
		dispatch(setCurrentElement(null))
		setTimeout(() => {
			dispatch(setCurrentElement(elementData))
		}, 10)
	}
	const DragHandle = SortableHandle(() => <span className="material-icons font--25 item">drag_indicator</span>);
	const SortableItem = SortableElement(({elementData}) => (
		<li className={getElementClass(elementData.id)} onClick={() => selectElement(elementData)}>
			{ props.isMobilePreview && 
				<>
					<div className="campaign__preview-overlay"></div>
					<div className="campaign__preview-elementToolbar">
						<DragHandle />
						{ canEdit(elementData.type, locked) &&
							<span className="material-icons font--25 item text--danger" onClick={(e) => { e.stopPropagation(); setDeleteModal(elementData); }} >
								clear
							</span>
						}
					</div>
				</>
			}
			{ elementData.type == 'freeText' && <FreeText key={elementData.id} data={elementData} sending={false} elementErrors={[]} onChange={setData}/>}
			{ elementData.type == 'image' && 	<Image key={elementData.id} data={elementData}/> }
			{ elementData.type == 'link' && 	<Link key={elementData.id} data={elementData}/> }
			{ elementData.type == 'iframe' && 	<IFrame key={elementData.id} data={elementData}/> }
			{ elementData.type == 'numericPrediction' && <NumericPrediction key={elementData.id} data={elementData} sending={false} elementErrors={[]} onChange={setData}/> }
			{ elementData.type == 'registrationForm' && <RegistrationForm key={elementData.id} globalStyling={globalStyling} data={elementData} sending={false} elementErrors={[]} onChange={setData} onSubmit={onSubmit}/> }
			{ elementData.type == 'spacer' && <Spacer key={elementData.id} data={elementData}/> }
			{ elementData.type == 'submitButton' && <SubmitButton key={elementData.id} data={elementData} sending={false} onSubmit={onSubmit}/> }
			{ elementData.type == 'text' && <Text key={elementData.id} data={elementData}/> }
			{ elementData.type == 'poll' && <Poll key={elementData.id} data={elementData} sending={false} elementErrors={null} onChange={setData} onSubmit={onSubmit}/> }
			{ elementData.type == 'imageChoice' && <ImageChoice key={elementData.id} data={elementData} sending={false} elementErrors={null} onChange={setData} onSubmit={onSubmit} />}
			{ elementData.type == 'categorizedPoll' && <CategorizedPoll key={elementData.id} data={elementData} sending={false} elementErrors={[]} onChange={setData} onSubmit={onSubmit}/> }
			{ elementData.type == 'wheelOfPrizes' && <WheelOfPrizes key={elementData.id} data={elementData} sending={false} elementErrors={[]} onChange={setData} onSubmit={onSubmit} primaryTextColor={primaryTextColor} /> }
			{ elementData.type == 'wheelOfPrizesPreview' && <WheelOfPrizesPreview key={elementData.id} data={elementData} sending={false} elementErrors={[]} onChange={setData} onSubmit={onSubmit} campaign={currentCampaign} primaryTextColor={primaryTextColor} />}
			{ elementData.type == 'soccerLineupSelector' && <SoccerLineUpSelector key={elementData.id} data={elementData} sending={false} elementErrors={[]} onChange={setData} onSubmit={onSubmit}/> }
			{ elementData.type == 'slideShow' && <SlideShow key={elementData.id} data={elementData}/> }
			{ elementData.type == 'mvp' && <Mvp key={elementData.id} data={elementData} sending={false} elementErrors={null} onChange={setData} onSubmit={onSubmit} />}
			{ elementData.type == 'scratch' && <Scratch key={elementData.id} data={elementData} sending={false} elementErrors={[]} onChange={setData} onSubmit={onSubmit} primaryTextColor={primaryTextColor} isDemo={true} />  }
			{ elementData.type == 'ugc' && <UGC key={elementData.id} data={elementData} onChange={setData} isDemo={true}/> }
		</li>
	));

	const SortableList = SortableContainer(({items}) => {
		return (
			<ul className="sortable__container">
				{
					items.map((value, index) => (
						<SortableItem key={`sortable-${index}`} index={index} elementData={value} />
					))
				}
			</ul>
		);
	});
	const onSortEnd = (data) => {
		setDragLoader(true);
		const element_id = currentPage.elements[data.oldIndex].id;
		const pageType = currentPage.type == 'expiredPage' ? 'expiredPage' : 'pages';
		movePageElement(currentCampaignId, pageType, currentPage.id, element_id, data.newIndex)
		.then((data) => {
			let p = null;
			if(pageType == 'expiredPage'){
				p = data.expiredPage;
			}else{
				p = data.pages.filter( page => page.id == currentPage.id);
				p = p[0];
			}
			if(p.hasOwnProperty('elements')) dispatch( reorderPageElementsStore(p.elements));
		})
		.catch( (e) => {
			ErrorNotification('Could not move this element.', e, {});
			console.error(e, {...e});
		})
		.finally(() => setDragLoader(false))
	}
	const setPoweredAsCurrent = () => {
		dispatch( setCurrentElement({
			'id': null,
			'type': 'powered'
		}) )
	}
	useEffect(() => {
		/* setDragLoader(true)
		setTimeout(() => {
			setDragLoader(false)
		}, 500); */
		updateAppThemeIfNeeded(primaryColor, primaryTextColor, secondaryColor, secondaryTextColor)
	}, [currentPage])
	return (
		<>
			<div className={getCampaignCss()} style={getCampaignStyling()}>
				{  dragLoader && <div className="preview__loader"><span className="loader"></span></div> }
				<div className="campaign__tint" style={getTintStyling()}>
					<div className="campaign__wrap">
						<SortableList useDragHandle items={elements} onSortEnd={onSortEnd} />
						{showShare && <ShareModal isStatic={true} campaign_url={`https://app.sportovivo.com/share?campaign=${currentCampaignId}&org=${currentCampaign?.organizationId}`} /> }
					</div>
				</div>
				{ deleteModal && <DeleteElementModal deleteAction={deleteElement} closeCb={setDeleteModal} data={deleteModal} /> }
			</div>
			{/* { <div className="campaign__preview-element poweredBy__preview" onClick={() => setPoweredAsCurrent()}><PoweredBy background={primaryColor} textColor={primaryTextColor} /></div>} */}
		</>
	);
}

export default CampaignPagePreview;
