import { useState } from "react";
import ColorPallete from "../../../utils/ColorPallete";
import {useDispatch, useSelector} from 'react-redux';
import EditableInput from "../../../utils/EditableInput";
import {updateElement} from "../../../utils/UpdateFunctions";
import { editElement } from "../../../../actions/UserActions";
import ExtractParams from "../../../utils/ExtractParams";
function IFrameElementOptions() {
	const dispatch = useDispatch();
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const {marginTop, url, allow, allowfullscreen} = elementCopy;
	const update = (key, value) => {
		if(key === 'marginTop') value += 'px';
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	};
	const updateMulti = (pairs) => {
		for (const [key, value] of Object.entries(pairs)) {
			console.log(key, value);
			elementCopy[key] = value;
		}
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	return (
		<div>
			<div className="campaign__option">
				<label className="text--bold">Top Margin (pixels)</label>
				<div className="font--12 text--muted">The space the element has from the top</div>
				<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Url</label>
				<div className="text--muted font--12">This is the actual url that will load inside the iframe</div>
				<EditableInput item="url" type="text" startValue={url} updateAction={update} placeholder="IFrame source" />
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Allow</label>
				<div className="text--muted font--12">These are the allowed properties for the iframe</div>
				<EditableInput item="allow" type="text" startValue={allow} updateAction={update} placeholder="allow properties for the iframe" />
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Fullscreen</label>
				<div className="text--muted font--12">Allow fullscreen</div>
				<EditableInput type="checkbox" isSwitch={true} item="allowfullscreen" startValue={allowfullscreen ? allowfullscreen : false} updateAction={update} />
			</div>

			<div className="campaign__option mtop--15">
				<label className="text--bold">Paste Embed Code</label>
				<div className="font--12 text--muted">Copy and paste iframe code</div>
				<ExtractParams onSubmit={updateMulti} />
			</div>
			{/* <div className="campaign__option mtop--15">
				<label className="text--bold">Link behaviour</label>
				<div className="flex flex--row">
					<button className={ target === 'self' ? 'btn btn-primary' : 'btn btn-secondary'} onClick={() => update('target', 'self')}>Same window</button>
					<button className={ target === 'newTab' ? 'btn btn-primary' : 'btn btn-secondary'} onClick={() => update('target', 'newTab')}>New window</button>
				</div>
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Text align</label>
				<div className="flex flex--row">
					<div className={textAlign === 'left' ? 'btn btn-primary' : 'btn btn-secondary'}  onClick={() => update('textAlign', 'left')}>Left</div>
					<div className={textAlign === 'center' ? 'btn btn-primary' : 'btn btn-secondary'}  onClick={() => update('textAlign', 'center')}>Center</div>
					<div className={textAlign === 'right' ? 'btn btn-primary' : 'btn btn-secondary'}  onClick={() => update('textAlign', 'right')}>Right</div>
				</div>
			</div> */}
		</div>
	);
}

export default IFrameElementOptions;
