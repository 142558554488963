import {genUUID} from './uuid.mjs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getPageElements } from './PageElements';

export {genUUID};

export const getErrorReasonMessage = (error, {defaultMessage, emptyMessage}) => {
	if(!defaultMessage) defaultMessage = '';
	if(!emptyMessage) emptyMessage = '';
	if(!error) return emptyMessage;

	const errorName = error?.details?.errorName;
	switch (errorName){
		case 'CampaignLockedError': {
			return 'The campaign has answers and no further changes are allowed.'
		}
		case 'ValidationError': {
			const errType = error?.details?.data[0]?.type;
			if(errType==="string.max"){
				const limit = error.details.data[0]?.context?.limit;
				if(limit) return 'Length must be less than or equal to ' + limit + ' characters long.';
			}
			else if(errType==="string.min"){
				const limit = error.details.data[0]?.context?.limit;
				if(limit) return 'Length must be at least ' + limit + ' characters long.';
			}
			else if(errType==="string.empty"){
				return 'The text cannot be empty.';
			}
			else if(errType==="array.min"){
				const limit = error.details.data[0]?.context?.limit;
				if(limit) return 'The list must contain at least ' + limit + ' item' + ((limit!=1)?'s':'') + '.';
			}
			break;
		}
	}
	return defaultMessage;
}
export const getErrorActionMessage = (error, {defaultAction, emptyAction}) => {
	if(!defaultAction) defaultAction = '';
	if(!emptyAction) emptyAction = '';
	if(!error) return emptyAction;

	const errorCode = error?.code;
	switch (errorCode){
		case 'functions/internal': {
			return 'Try again later.'
		}
		case 'functions/permission-denied': {
			return 'Try loggin in.'
		}
		default: {
			return defaultAction;
		}
	}
}
export const Notification = (message, type='info') => {
	/* *
		* Types
		* warn
		* info
		* success
		* error
	*/
	toast[type](message , {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "dark",
	});
}
export const ErrorNotification = (message, error, {defaultMessage, emptyMessage, defaultAction, emptyAction}) => {
	const _eMsg = getErrorReasonMessage(error, {defaultMessage, emptyMessage});
	let action = getErrorActionMessage(error, {defaultAction, emptyAction});
	let notification = (<>
		{message} {action} {_eMsg?<><br/><small>{_eMsg}</small></>:''}
	</>)
	Notification(notification, 'error');
}
export const hexToRGB = (hex) => {
	hex = hex.toLowerCase()
	// Remove the "#" character
	hex = hex.replace("#", "");

	// check for the length of hex input
	if (hex.length === 3) {
		// Expand hex code to 6 characters if the input is in the format of #RGB
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	} else if (hex.length === 4) {
		// Expand hex code to 8 characters if the input is in the format of #RGBA
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
	}

	// Convert the hex string to an array of integers
	let r = parseInt(hex.substring(0, 2), 16);
	let g = parseInt(hex.substring(2, 4), 16);
	let b = parseInt(hex.substring(4, 6), 16);

	// check for the presence of alpha channel if the input is in the format of RRGGBBAA
	let a = parseInt(hex.substring(6, 8), 16);
	a = isNaN(a) ? 1 : a / 255; //if the input is RRGGBB return a value of 1

	// Return an RGB object
	return `${r} ${g} ${b}`;
}
export const removeOpacityFromRGBA = (rgba) => {
	// Check if input is valid RGBA format
	if (!/^rgba?\(\d{1,3},\s*\d{1,3},\s*\d{1,3},\s*(0|1|0?\.\d+)\)$/.test(rgba)) {
		return null;
	}
	// Remove "rgba(" and ")" from the string
	const strippedString = rgba.slice(5, -1);
	// Split the string into individual color values and alpha
	const [r, g, b, a] = strippedString.split(',');
	// Combine the color values without commas
	const color = `${r.trim()} ${g.trim()} ${b.trim()}`;
	// Return the color string without opacity
	return color;
}
export const updateAppThemeIfNeeded = (primaryColor, primaryTextColor, secondaryColor, secondaryTextColor) => {
	const root = document.querySelector(':root');
	const colors = {
		'--app-primary': primaryColor ? primaryColor : '#645CAA',
		'--app-primary-rgb': primaryColor ? hexToRGB(primaryColor) : hexToRGB('#645CAA'),
		'--app-textColor': primaryTextColor ? primaryTextColor : '#000D25',
		'--app-textColor-rgb': primaryTextColor ? hexToRGB(primaryTextColor) : hexToRGB('#000D25'),
		'--app-secondary': secondaryColor ? secondaryColor : '#EFF5F5',
		'--app-secondary-rgb': secondaryColor ? removeOpacityFromRGBA(secondaryColor) : '239 245 245',
		'--app-secondaryTextColor': secondaryTextColor ? secondaryTextColor : '#F6F8FF',
		'--app-secondaryTextColor-rgb': secondaryTextColor ? removeOpacityFromRGBA(secondaryTextColor) : '246 248 255',
	}
	if (root) {
		for (const [key, value] of Object.entries(colors)) {
			root.style.setProperty(key, value);
		}
	}
}
export const canEdit = (type, campaignLocked) => {
	return campaignLocked ? getPageElements(type)?.allowedOnLocked : true
}