import { useEffect } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import CampaignEdit from "./CampaignElements/CampaignEdit";
import {Routes, Route } from "react-router-dom";
import CampaignResults from "./CampaignElements/CampaignResults";
import CampaignModeration from "./CampaignElements/CampaignModeration";
import TopBar from "./CampaignElements/TopBarCampaign";
import "../../scss/pages/Campaign.scss";
import {setCurrentCampaignId} from "../../actions/UserActions";

function Campaign() {
	const dispatch = useDispatch();
	const {currentCampaignId} = useSelector(state => state.user);
	const {id} = useParams();
	useEffect( () => {
		if(id) dispatch( setCurrentCampaignId(id) )
	}, [id])
	return (
		<div>
			<TopBar/>
			<Routes>
				<Route>
					{ currentCampaignId &&
						<>
							<Route path="/edit" element={<CampaignEdit />}/>
							<Route path="/results" element={<CampaignResults />}/>
							<Route path="/moderation" element={<CampaignModeration />}/>
						</>
					}
				</Route>
			</Routes>
		</div>
	);
}

export default Campaign;
