import EditableInput from "../../../../utils/EditableInput"

function RegistrationChoiceElementOptions({ field, updateFieldOption, addFieldOption, deleteFieldOption }) {
	return (
		 <>
			<div className="mtop--10 registration__form-fieldOptions">
				<label className="text--bold font--12">Options</label>
				{field.options.map((option, idx) => {
					return (
						<div className="registration__form-fieldOption" key={`registration__option${idx}`}>
							<EditableInput item={idx} type="text" extra={{ field_id: field.id }} startValue={option} updateAction={updateFieldOption} />
							<div className="material-icons delete float--right" onClick={() => deleteFieldOption(field.id, idx)}>clear</div>
						</div>
					)
				})}
				<div className="text--center">
					<button className="btn btn-primary-soft btn-md" onClick={() => addFieldOption(field.id)}>Add Option</button>
				</div>
			</div>
			{/*<div className="mtop--10 flex flex--row">
				<div className="flex--50 input__sm">
					<label className="text--bold font--12">Max selections</label>
					 <EditableInput item={'max'} type="number" extra={{ field_id: field.id }} startValue={field.max} updateAction={updateField} /> 
				</div>
			</div>*/}
	  	</>
	)
}

export default RegistrationChoiceElementOptions