import { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { CURRENT_VERSION } from "./CampaignAddDefaults";
import CampaignAppearance from "./CampaignAppearance";
import CampaignSettings from "./CampaignSettings";
import PageSelector from "./PageSelector";
import CampaignPagePreview from "./CampaignPagePreview";
import CampaignOptions from "./CampaignOptions";
import CampaignAddElements from "./CampaignAddElements";
import CampaignDesktopPreview from "./CampaignDesktopPreview";
import ProjectorEditor from "./ProjectorEditor";
import {getCampaign, modifyCampaign, getProjectors, replaceProjector} from '../../../firebaseActions.mjs';
import {setCurrentCampaign, setCurrentPage, setColorPalleteColors, setProjectors} from "../../../actions/UserActions";
import {ErrorNotification} from "../../utils/Misc";
import noCampaignImage from '../../../images/undraw_raining_re_4b55.svg';

function CampaignEdit(props) {
	const dispatch = useDispatch();
	const [appearanceModal, setAppearanceModal] = useState(null);
	const [settingsModal, setSettingsModal] = useState(null);
	const [desktopPreviewModal, setDesktopPreviewModal] = useState(null);
	const [projectorEditModal, setProjectorEditModal] = useState(null);
	const {currentCampaign, currentPage, currentCampaignId, projectors} = useSelector(state => state.user);
	const [inited, setInited] = useState(false);
	const [projectorElementCount, setProjectorElementCount] = useState(0);
	const colorKeys = ['color', 'backgroundColor', 'textColor', 'primaryColor', 'buttonColor', 'shirtColor', 'background'];
	let iteratedColors = {
		rgba: [],
		hex: []
	}
	let hashMap = {
		rgba: {},
		hex: {}
	};
	const iterateColors = currentObject => {
		for (const key in currentObject) {
			if (currentObject.hasOwnProperty(key)) {
				if(colorKeys.includes(key)){
					if(typeof currentObject[key] == 'string'){
						const v = currentObject[key].replace(/ /g,'');
						if(v.startsWith('rgba')){
							if(hashMap['rgba'][v]){
								hashMap['rgba'][v]++;
							}else{
								hashMap['rgba'][v] = 1;
								iteratedColors['rgba'].push(currentObject[key]);
							}
						}else{
							if(hashMap['hex'][v]){
								hashMap['hex'][v]++;
							}else{
								hashMap['hex'][v] = 1;
								iteratedColors['hex'].push(currentObject[key]);
							}
						}
					}
				} else if (typeof currentObject[key] === 'object') {
					if (Array.isArray(currentObject[key])) {
						currentObject[key].map(element => iterateColors(element));
					} else {
						iterateColors(currentObject[key]);
					}
				}
			}
		}
	}
	const get = () => {
		getCampaign(currentCampaignId)
		.then( async data => {
			if (data.campaign.version !== CURRENT_VERSION) {
				console.warn('Campaign mismatch, Trying to fix...')
				const d = await modifyCampaign(currentCampaignId, {'primaryColor' : data.campaign.primaryColor}).catch((e) => console.log(e))
				if(d) data.campaign = d;
			}
			data.campaign.pages.map((page, idx) => {
				if (idx + 1 === data.campaign.pages.length) {
					data.campaign.pages[idx]['type'] = 'endPage'
				} else {
					data.campaign.pages[idx]['type'] = 'normal'
				}
			})
			dispatch(setCurrentCampaign({
				...data.campaign,
				name: data.campaignMeta.name,
				shareSecondary: data.campaignMeta.share ? data.campaignMeta.share : {
					title: null,
					image: null,
					description: null,
				},
				endDate: data.campaignMeta?.endDate,
				expiredPage: data.campaign?.expiredPage,
				organizationId: data.campaignMeta?.organizationId
			}))
			dispatch(setCurrentPage(data.campaign.pages[0]))
			iterateColors(data.campaign);
			dispatch(setColorPalleteColors(iteratedColors, currentCampaignId))
		})
		.catch((e) => {
			ErrorNotification('Could not load campaign.', e, { defaultAction: 'Make sure the campaign exists and you are logged in with the correct account' });
			console.error(e, { ...e });
			window.e = e
		})
		.finally(() => setInited(true))
	}
	const loadProjectors = () => {
		getProjectors(currentCampaignId)
		.then( async data => {
			dispatch(setProjectors(data?data.map((dt)=>dt.data):[]));
		})
		.catch((e) => {
			console.error(e, { ...e });
			dispatch(setProjectors([]));
		});
	}
	useEffect(()=>{
		let c = 0;
		if(currentCampaign){
			for(let i=0;i<currentCampaign.pages.length;i++){
				for(let j=0;j<currentCampaign.pages[i].elements.length;j++){
					if(currentCampaign.pages[i].elements[j].type==="ugc") c++;
				}
			}
		}
		setProjectorElementCount(c);
	}, [currentCampaign])
	useEffect(()=>{
		loadProjectors();
	}, [projectorElementCount])
	useEffect( () => {
		get()
	}, [currentCampaignId])
	return (
		<>
			{ currentCampaign ?
				<div className="campaign__edit">
					<div className="campaign__edit-pages">
						<div className="float--right">
							<button className="btn btn-primary-soft" onClick={ () => setAppearanceModal(true) }>
								<span className="pulse__blob  mright--10"></span>
								Look & Feel
							</button>
							<button className="btn btn-transparent" onClick={ () => setSettingsModal(true) }>
								<span className="material-icons font--20 vm--align">
									settings
								</span>
								&nbsp;
								Settings
							</button>
						</div>
					</div>
					{ currentPage &&
						<div className="campaign__edit-content">
							<div className="campaign__edit-contentSidebar">
								<div className="campaign__edit-pagesWrapper">
									<PageSelector />
									{projectors?.length>0 && <div style={{"width": "100%","position": "absolute","bottom": "0","left": "0",}}>
										{projectors.map((p)=>{
											return (<div key={p.id}>
												<div className="text--center">
													<a href={`${process.env.REACT_APP_PROJECTOR_URL}/c/${currentCampaignId}/p/${p.id}`} target="_blank" rel="noreferrer">
														<span className="material-icons font--20 vm--align">open_in_new</span>
														Projector
													</a>
												</div>
												<div className="text--center" style={{"marginTop":"10px"}}>
													<a href={`/campaigns/${currentCampaignId}/moderation`} target="_blank" rel="noreferrer">
														<span className="material-icons font--20 vm--align">open_in_new</span>
														Moderation
													</a>
												</div>
												<button className="btn btn-primary btn-100" onClick={() => setProjectorEditModal(p)} style={{"marginTop":"10px"}}>
													<span className="material-icons font--20 vm--align">edit</span>
													Edit Projector (Beta)
												</button>
											</div>);
										})}
									</div>}
								</div>
							</div>
							<div className="campaign__edit-contentLeft">
								<div>
									<div>
										<h3>Elements</h3>
										<div className="text--muted font--12">Click to add an element</div>
									</div>
									<div className="campaign__edit-elements flex--wrap">
										<CampaignAddElements/>
									</div>
								</div>
							</div>
							<div className="campaign__edit-contentCenter">
								<div>
									<div>
										<div className="flex flex--row mbottom--10">
											<h3>Preview</h3>
											<div className="float--right">
												<button className="btn btn-secondary btn-sm" onClick={() => setDesktopPreviewModal(true)}>
													<span className="material-icons">
														computer
													</span>
												</button>
											</div>
										</div>
										<div className="campaign__edit-preview">
											<CampaignPagePreview isMobilePreview={true} />
										</div>
									</div>
								</div>
							</div>
							<div className="campaign__edit-contentRight">
								<CampaignOptions />
							</div>
						</div>
					}
					{ appearanceModal && <CampaignAppearance campaign={currentCampaign} closeCb={setAppearanceModal}/>}
					{ settingsModal && <CampaignSettings campaign={currentCampaign} closeCb={setSettingsModal}/>}
					{ desktopPreviewModal && <CampaignDesktopPreview campaign={currentCampaign} closeCb={setDesktopPreviewModal}/>}
					{ projectorEditModal && <ProjectorEditor campaignId={currentCampaignId} projector={projectorEditModal} onChange={(campaignId, changes)=>{
						replaceProjector(campaignId, changes.id, changes)
						.then((data)=>{
							dispatch(setProjectors([data]));
						})
					}} closeCb={setProjectorEditModal}/>}
				</div>
				:
				<>
					{ inited &&
						<div className="text--center">
							<img src={noCampaignImage} className="img__responsive mtop--30" />
							<h3>No campaign selected</h3>
						</div>
					}
				</>

			}
		</>
	);
}

export default CampaignEdit;
