import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import 'animate.css'
import {
	getOrganizationRequests,
	getOrganization,
	removeUserFromOrganization
} from '../../../firebaseActions.mjs';
import ConfirmDelete from '../../utils/ConfirmDeleteModal'
import { Notification } from '../../utils/Misc.jsx';
import AddRequest from './AddRequest'
import OrganizationRequests from './OrganizationRequests'
import OrganizationUsers from './OrganizationUsers'
import OrganizationAPIKeys from './OrganizationAPIKeys'
import { storeDeleteOrganization } from '../../../actions/OrganizationsActions'

const Organization = ({ organization, setCurrentOrganization }) => {
	const dispatch = useDispatch()
	const { user } = useSelector(state => state.user);
	const [view, setView] = useState('users');
	const [administrators, setAdministrators] = useState([]);
	const [managers, setManagers] = useState([]);
	const [administratorsRequests, setAdministratorsRequests] = useState([]);
	const [managersRequests, setManagersRequests] = useState([]);
	const [myRole, setMyRole] = useState('owner');
	const [leaveRole, setLeaveRole] = useState(null);

	const leave = () => {
		removeUserFromOrganization(user.uid, organization.id)
		.then((data) => {
			if (data) {
				dispatch(storeDeleteOrganization(organization.id));
				setCurrentOrganization(null)
			}
		})
		.catch((e) => {
			Notification('The user access could not be revoked. Please try again later.', 'error')
			console.error(e)
		})
	}
	useEffect(() => {
		if(view === 'users'){
			setAdministrators([])
			setManagers([])
			getOrganization(organization.id)
			.then((data) => {
				if(data?.roles){
					let admins = [];
					let managers = [];
					/* const roles = Object.values(data.roles);
					const keys = Object.keys(data.roles); */
					for(const [key, role] of Object.entries(data.roles)){
						if ( key === user.uid) {
							setMyRole(role.role)
							continue ;
						}
						const o = {
							id: key,
							...role
						}
						switch (role.role) {
							case 'admin':
								admins.push(o)
								break;
							case 'manager':
								managers.push(o)
								break;
							default:
								break;
						}
					}
					setAdministrators(admins)
					setManagers(managers)
				}
			})
		}else{
			setAdministratorsRequests([])
			setManagersRequests([])
			getOrganizationRequests(organization.id)
			.then((data) => {
				if (data) {
					let admins = [];
					let managers = [];
					data.forEach((request) => {
						const o = {
							id: request.id,
							...request.data
						}
						console.log(o.role);
						if (o.role === 'admin') {
							admins.push(o)
						} else {
							managers.push(o)
						}
					})
					setAdministratorsRequests(admins)
					setManagersRequests(managers)
				}
			})
		}
	}, [view, organization])
	return (
		<div>
			<div className='animate__animated animate__fadeIn'>
				<div className="card card__secondary">
					<div>You are acting as: <b className="uppercase">{myRole}</b></div>
					{myRole !== 'owner' && <div className="btn btn-danger-soft btn-md mtop--10" onClick={() => setLeaveRole(true)}>Leave organization</div> }
				</div>
				<div className="flex flex--row mtop--20">
					<div className='tabs'>
						<div className={`tabs__item ${view === 'users' ? 'tabs__item-active' : ''}`} onClick={() => setView('users')}>
							<span className="material-icons font--25 vm--align">
								people
							</span>
							Active Users
						</div>
						<div className={`tabs__item ${view === 'requests' ? 'tabs__item-active' : ''}`} onClick={() => setView('requests')}>
							<span className="material-icons font--25 vm--align">
								mark_email_read
							</span>
							Sent Invitations
						</div>
						<div className={`tabs__item ${view === 'api' ? 'tabs__item-active' : ''}`} onClick={() => setView('api')}>
							<span className="material-icons font--25 vm--align">
								vpn_key
							</span>
							API Keys
						</div>
					</div>
				</div>
				{view === 'users' &&
					<div className="mtop--20">
						<div className="card card__secondary">
							<div className="flex flex--row">
								<h4>Administrators</h4>
								<div className="float--right">
									{ myRole === 'owner' && <AddRequest role="admin" organization={organization} /> }
								</div>
							</div>
							<OrganizationUsers users={administrators} setUsers={setAdministrators} organization_id={organization.id} myRole={myRole} />
						</div>
						<div className="card card__secondary mtop--20">
							<div className="flex flex--row">
								<h4>Managers</h4>
								<div className="float--right">
									{myRole !== 'manager' && <AddRequest role="manager" organization={organization} />}
								</div>
							</div>
							<OrganizationUsers users={managers} setUsers={setManagers} organization_id={organization.id} myRole={myRole} />
						</div>
					</div>
				}
				{ view === 'requests' && 
					<div className="mtop--20">
						<div className="card card__secondary">
							<div className="flex flex--row">
								<h4>Administrators</h4>
							</div>
							<OrganizationRequests requests={administratorsRequests} setRequests={setAdministratorsRequests} organization_id={organization.id} myRole={myRole} />
						</div>
						<div className="card card__secondary mtop--20">
							<div className="flex flex--row">
								<h4>Managers</h4>
							</div>
							<OrganizationRequests requests={managersRequests} setRequests={setManagersRequests} organization_id={organization.id} myRole={myRole} />
						</div>
					</div>
				}
				{view  === 'api' && <OrganizationAPIKeys organizationId={organization.id} myRole={myRole} />}
			</div>
			{leaveRole && <ConfirmDelete closeCb={setLeaveRole} confirmCb={leave} confirmData={leaveRole} text={'Are you sure you want to leave this organization?'} />}
		</div>
	)
}

export default Organization