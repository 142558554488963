import {useDispatch} from 'react-redux';
import ColorPallete from "../../../../utils/ColorPallete";
import EditableInput from "../../../../utils/EditableInput";
import ImageUpload from '../../../../utils/ImageUpload';
import { updateElement } from "../../../../utils/UpdateFunctions";
import { editElement } from "../../../../../actions/UserActions";
import RichText from "../../../../utils/RichText";

function ScratchLook({ currentCampaignId, currentPage, currentElement, elementCopy, setElementCopy }) {
	const dispatch = useDispatch();
	const { 
		textColor,
		marginTop,
		backgroundColor,
		scratchText,
		loaderMessage,
		winMessage,
		lostMessage,
		coverImage,
		buttonBackgroundColor, 
		buttonTextColor,
		finishPercent,
		brushSize
	} = elementCopy;
	
	const update = (key, value, extra) => {
		if (key === 'marginTop') value += 'px';
		elementCopy[key] = value
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	return (
		<div>
			<div>
				<label className="text--bold">Element</label>
				<div className="campaign__option mtop--20">
					<label className="text--bold">Top Margin (pixels)</label>
					<div className="font--12 text--muted">The space the element has from the top</div>
					<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
				</div>
			</div>
			<div className="mtop--20">
				<div className="form__group">
					<div className="form__group-label">
						Background Color
					</div>
					<div className="form__group-content">
						<ColorPallete dropdown={true} isLeft={true} item="backgroundColor" value={backgroundColor} type={'rgba'} updateAction={update} />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Text Color
					</div>
					<div className="form__group-content">
						<ColorPallete dropdown={true} isLeft={true} item="textColor" value={textColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Button Background Color
					</div>
					<div className="form__group-content">
						<ColorPallete dropdown={true} isLeft={true} item="buttonBackgroundColor" value={buttonBackgroundColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Button Text Color
					</div>
					<div className="form__group-content">
						<ColorPallete dropdown={true} isLeft={true} item="buttonTextColor" value={buttonTextColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Show result after % is complete
					</div>
					<div className="form__group-content">
						<EditableInput item="finishPercent" type="number" startValue={finishPercent} updateAction={update} placeholder={'50'} />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Scratch Brush Size
					</div>
					<div className="form__group-content">
						<EditableInput item="brushSize" type="number" startValue={brushSize} updateAction={update} placeholder={'40'} />
					</div>
				</div>
				<div className="flex flex--row flex--wrap mtop--20">
					<div className="campaign__option campaign__option-50">
						<label className="text--bold">Cover Image</label>
						<div className="text--muted font--12">An image placed on top of the scratch card. Before the user begins scratching.</div>
						<div className="campaign__option-act">
							<ImageUpload dimensions={'900 x 1400 px'} campaign_id={currentCampaignId} file={coverImage} item="coverImage" updateAction={update} 
								hasCrop={true}
								aspectRatio={9/14}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="mtop--20">
				<label className="text--bold">Scratch Prompt</label>
				<RichText item={'scratchText'} returnEmptyString={true} value={scratchText} updateAction={update} isSmall={true} />
			</div>
			<div className="mtop--20">
				<label className="text--bold">Loader message</label>
				<RichText item={'loaderMessage'} returnEmptyString={true} value={loaderMessage} updateAction={update} isSmall={true} />
			</div>
			<div className="mtop--20">
				<label className="text--bold">Win message</label>
				<RichText item={'winMessage'} returnEmptyString={true} value={winMessage} updateAction={update} isSmall={true} />
			</div>
			<div className="mtop--20">
				<label className="text--bold">Lost message</label>
				<RichText item={'lostMessage'} returnEmptyString={true} value={lostMessage} updateAction={update} isSmall={true} />
			</div>
		</div>
	);
}

export default ScratchLook;
