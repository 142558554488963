import { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {updateElement} from "../../../utils/UpdateFunctions";
import EditableInput from "../../../utils/EditableInput";
import { editElement } from "../../../../actions/UserActions";

function UGCElementOptions({isPreview}) {
	const dispatch = useDispatch();
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const {marginTop, allowImage, useFrame, frames, stickers, allowVideos, allowFirstName, allowLastName, allowCaption, fields, autoAcceptPosts, postButtonText, captionTitle, firstNameTitle, lastNameTitle} = elementCopy;
	const update = (key, value) => {
		// eslint-disable-next-line default-case
		switch(key){
			case 'marginTop':
				value += 'px';
				break;
			case 'captionTitle':
			case 'displayNameTitle':
				if(!value) value=null;
				break;
		}
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
		.then(() => {
			//alert('1')
		})
		.catch(() => {
			//alert('2')
		})
	}

	return (
		<div className="UGCElementOptions">
			{/*<div className="campaign__option">
				<label className="text--bold">Content</label>
				<RichText item={'html'} value={html} updateAction={update} />
			</div>*/}
			<div className="campaign__option">
				<div className="campaign__option flex flex--row">
					<EditableInput item="allowImage" type="checkbox" isSwitch startValue={allowImage} updateAction={update} />
					<label className="font--13 text--bold mleft--10">Allow image</label>
				</div>
				<div className="campaign__option flex flex--row">
					<EditableInput item="autoAcceptPosts" type="checkbox" isSwitch startValue={autoAcceptPosts} updateAction={update} />
					<label className="font--13 text--bold mleft--10">Auto accept posts</label>
				</div>
				{allowImage && <div className="campaign__option">
					{false && <div className="campaign__option flex flex--row">
						<EditableInput item="useFrame" type="checkbox" isSwitch startValue={useFrame} updateAction={update} />
						<label className="font--13 text--bold mleft--10">Use frame</label>
					</div>}
					{false && useFrame && <div className="campaign__option flex flex--row">
						<div>Frames</div>
						{frames.map((frame)=>{
							return frame.id;
						})}
					</div>}
					{false && useFrame && <div className="campaign__option flex flex--row">
						<div>Stickers</div>
						{stickers.map((sicker)=>{
							return sicker.id;
						})}
					</div>}
				</div>}
				{false && <div className="campaign__option flex flex--row">
					<EditableInput item="allowVideos" type="checkbox" isSwitch startValue={allowVideos} updateAction={update} />
					<label className="font--13 text--bold mleft--10">Allow video</label>
				</div>}
				<div className="campaign__option flex flex--row">
					<EditableInput item="allowFirstName" type="checkbox" isSwitch startValue={allowFirstName} updateAction={update} />
					<label className="font--13 text--bold mleft--10">Allow first name</label>
				</div>
				<div className="campaign__option flex flex--row">
					<EditableInput item="allowLastName" type="checkbox" isSwitch startValue={allowLastName} updateAction={update} />
					<label className="font--13 text--bold mleft--10">Allow last name</label>
				</div>
				<div className="campaign__option flex flex--row">
					<EditableInput item="allowCaption" type="checkbox" isSwitch startValue={allowCaption} updateAction={update} />
					<label className="font--13 text--bold mleft--10">Allow text</label>
				</div>
				{false && <div className="campaign__option flex flex--row">
					<div>Extra fields</div>
					{fields.map((field)=>{
						return field.id;
					})}
				</div>}
				<div className="mtop--20">
					<label className="text--bold">Post button text</label>
					<EditableInput item="postButtonText" type="text" startValue={postButtonText} updateAction={update} placeholder={'Post'} />
				</div>
				{allowCaption && <div className="mtop--20">
					<label className="text--bold">Text input title</label>
					<EditableInput item="captionTitle" type="text" startValue={captionTitle} updateAction={update} placeholder={'Caption'} />
				</div>}
				{allowFirstName && <div className="mtop--20">
					<label className="text--bold">First name title</label>
					<EditableInput item="firstNameTitle" type="text" startValue={firstNameTitle} updateAction={update} placeholder={'First name'} />
				</div>}
				{allowLastName && <div className="mtop--20">
					<label className="text--bold">Last name title</label>
					<EditableInput item="lastNameTitle" type="text" startValue={lastNameTitle} updateAction={update} placeholder={'Last name'} />
				</div>}
			</div>
			<div className="flex flex--row flex--wrap mtop--20">
				<div className="campaign__option campaign__option-50 campaign__option-act">
					<label className="text--bold">Top Margin (pixels)</label>
					<div className="font--12 text--muted">The space the element has from the top</div>
					<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
				</div>
				{/*<div className="campaign__option campaign__option-50 campaign__option-act mleft--5">
					<label className="text--bold">Background color</label>
					<div className="text--muted font--12">Preferable if you have a background image and the text is not visible</div>
					<ColorPallete item="background" value={background} type={'rgba'} updateAction={update} />
				</div>*/}
			</div>
		</div>
	);
}

export default UGCElementOptions;
