import moment from 'moment';

export const getNumericPredictionResults = (unMappedUserResults, resultsCurrentElement, onlyValidAnswers) => {
	return new Promise((resolve, reject) => {
		let o = []
		let generalHashMap = {};
		const keys = Object.keys(unMappedUserResults);
		keys.map((key) => {
			const user = unMappedUserResults[key];
			let detailedPlaceholder = JSON.parse(JSON.stringify(resultsCurrentElement.rowPlaceholder));
			let generalPlaceholder = JSON.parse(JSON.stringify(resultsCurrentElement.generalReportPlaceholder));
			for (const [rKey, rValue] of Object.entries(user)) {
				for (const [k, v] of Object.entries(rValue)) {
					for (const [eKey, eValue] of Object.entries(v)) {
						if (eValue.hasOwnProperty('timestamp')) {
							detailedPlaceholder.timeAdded = moment.unix(eValue['timestamp']['seconds']).format("YYYY-MM-DD HH:mm:ss")
						}
						if (eValue.hasOwnProperty('value')) {
							for (const [vKey, vValue] of Object.entries(eValue['value'])) {
								let valueBasedOnFieldType = vValue
								if (typeof valueBasedOnFieldType == 'boolean') {
									valueBasedOnFieldType = valueBasedOnFieldType ? 'Yes' : 'No'
								}
								if (detailedPlaceholder.hasOwnProperty(vKey)) detailedPlaceholder[vKey] = valueBasedOnFieldType;
								if (generalPlaceholder.hasOwnProperty(vKey)) generalPlaceholder[vKey] = valueBasedOnFieldType;
							}
						}
					}
				}
			}

			let isValidAnswer = true
			/** Filter only the valid answers */
			if (onlyValidAnswers) {
				/**
					** In order to check if the answer is valid,
					** We find the keys that represent the valid answers (Mostly Name-Surname of registration).
					** Then check the row values against those keys
				*/
				const keysThatRepresentValidAnswers = resultsCurrentElement.tableHeader.filter((item) => ['registration_firstName', 'registration_lastName', 'registration_email'].includes(item.type) && item.type)
				if (keysThatRepresentValidAnswers.length > 0) {
					/**
						** Keep a sum of the valid fields the user has completed.
						**For example if he has completed at least lastname then we say that the answer is valid
					*/
					const validCount = keysThatRepresentValidAnswers.reduce((sum, item) => detailedPlaceholder[item.key] ? sum + 1 : sum, 0)
					isValidAnswer = validCount > 0
				}
			}

			let k = null;
			Object.values(generalPlaceholder).map((item, index) => {
				k += index != 0 ? ('-' + item) : item
			});
			k = k.toString();
			if (k) {
				if (generalHashMap.hasOwnProperty(k)) {
					generalHashMap[k]['value'] += 1;
				} else {
					generalHashMap[k] = {
						value: 1,
						percentage: 0,
					}
				}
				generalHashMap[k]['percentage'] = Math.round((100 * generalHashMap[k]['value']) / keys.length).toFixed(1);
			}
			if (isValidAnswer) o.push(Object.values(detailedPlaceholder));
		})
		o.sort((a, b) => {
			let a1 = a[a.length - 1]
			let b1 = b[b.length - 1]
			return new Date(b1) - new Date(a1);
		})
		resolve([o, generalHashMap])
	})
}
export const getRegistrationResults = (unMappedUserResults, rowPlaceholder) => {
	return new Promise((resolve, reject) => {
		let o = []
		const keys = Object.keys(unMappedUserResults);
		keys.map((key) => {
			const user = unMappedUserResults[key];
			let detailedPlaceholder = JSON.parse(JSON.stringify(rowPlaceholder));
			for (const [rKey, rValue] of Object.entries(user)) {
				for (const [k, v] of Object.entries(rValue)) {
					for (const [eKey, eValue] of Object.entries(v)) {
						if (eValue.hasOwnProperty('timestamp')) {
							detailedPlaceholder.timeAdded = moment.unix(eValue['timestamp']['seconds']).format("YYYY-MM-DD HH:mm:ss")
						}
						if (eValue?.value) {
							for (const [vKey, vValue] of Object.entries(eValue['value'])) {
								let valueBasedOnFieldType = vValue
								if (typeof valueBasedOnFieldType == 'boolean') {
									valueBasedOnFieldType = valueBasedOnFieldType ? 'Yes' : 'No'
								}
								if (detailedPlaceholder.hasOwnProperty(vKey)) detailedPlaceholder[vKey] = valueBasedOnFieldType;
							}
						}
					}
				}
			}
			o.push(Object.values(detailedPlaceholder));
		})
		o.sort((a, b) => {
			let a1 = a[a.length - 1]
			let b1 = b[b.length - 1]
			return new Date(b1) - new Date(a1);
		})
		resolve(o)
	})
}
export const getPollResults = (unMappedUserResults, resultsCurrentElement, onlyValidAnswers) => {
	return new Promise((resolve, reject) => {
		const keys = Object.keys(unMappedUserResults);
		const options = JSON.parse(JSON.stringify(resultsCurrentElement.generalReportPlaceholder))
		const optionsKeys = Object.keys(options);
		const detailedAnswersByUser = [];
		let totalVotes = 0;
		keys.map((key) => {
			const user = unMappedUserResults[key];
			let userMapped = JSON.parse(JSON.stringify(resultsCurrentElement.rowPlaceholder));
			for (const [rKey, rValue] of Object.entries(user)) {
				for (const [k, v] of Object.entries(rValue)) {
					for (const [eKey, eValue] of Object.entries(v)) {
						if (eValue.hasOwnProperty('timestamp')) {
							userMapped.timeAdded = moment.unix(eValue['timestamp']['seconds']).format("YYYY-MM-DD HH:mm:ss")
						}
						if (eValue?.value) {
							for (const [vKey, vValue] of Object.entries(eValue['value'])) {
								if(optionsKeys.includes(vValue)){
									userMapped[vValue] = options[vValue]['text'];
									totalVotes++;
								}else{
									if (userMapped.hasOwnProperty(vKey)) userMapped[vKey] = vValue;
								}
							}
						}
						if (eValue?.comment) {
							userMapped["comment"] = eValue?.comment;
						}
					}
				}
			}
			let isValidAnswer = true
			/** Filter only the valid answers */
			if (onlyValidAnswers) {
				/**
					** In order to check if the answer is valid,
					** We find the keys that represent the valid answers (Mostly Name-Surname of registration).
					** Then check the row values against those keys
				*/
				const keysThatRepresentValidAnswers = resultsCurrentElement.tableHeader.filter((item) => ['registration_firstName', 'registration_lastName'].includes(item.type) && item.type)
				if (keysThatRepresentValidAnswers.length > 0) {
					/**
						** Keep a sum of the valid fields the user has completed.
						**For example if he has completed at least lastname then we say that the answer is valid
					*/
					const validCount = keysThatRepresentValidAnswers.reduce((sum, item) => userMapped[item.key] ? sum + 1 : sum, 0)
					isValidAnswer = validCount > 0
				}
			}

			for (const [answerKey, answerValue] of Object.entries(userMapped)) {
				if(options.hasOwnProperty(answerKey)){
					if(answerValue){
						options[answerKey]['answers'].push(userMapped)
					}else{
						delete userMapped[answerKey]
					}
				}
			}
			if (isValidAnswer) detailedAnswersByUser.push(userMapped);
		})
		for (const [optionKey, optionValue] of Object.entries(options)) {
			optionValue.percentage = (!optionValue.answers) || (optionValue.answers.length == 0) ? 0 : ((optionValue.answers.length / totalVotes) * 100).toFixed(2)
		}
		resolve([Object.values(options), detailedAnswersByUser])
	})
}
export const getSoccerLineUpResults = (unMappedUserResults, resultsCurrentElement, onlyValidAnswers) => {
	return new Promise((resolve, reject) => {
		const keys = Object.keys(unMappedUserResults);
		const detailedPlaceholder = resultsCurrentElement.rowPlaceholder;
		const generalPlaceholder = resultsCurrentElement.generalReportPlaceholder
		let playersPerUser = [];
		let formation = {};
		let players = {};
		for (const [key, value] of Object.entries(generalPlaceholder.formation)) {
			if (typeof generalPlaceholder.formation[key] == 'string'){
				formation[key] = value
			}else{
				value.map((position) => {
					formation[key] = { ...formation[key], ...{ [position]: {
						mostVotedPlayer: null, 
						votes: {}
					} }}
				})
			}
		}
		generalPlaceholder.players.forEach((player) => {
			players[player.id] = player;
		})
		/* const detailedAnswersByUser = []; */
		let totalVotes = 0;
		/* Map the answers to the appropriate formation position */
		keys.map((key, idx) => {
			const user = unMappedUserResults[key];
			playersPerUser[idx] = JSON.parse(JSON.stringify(detailedPlaceholder));
			let eleven = "";
			//let userMapped = JSON.parse(JSON.stringify(resultsCurrentElement.rowPlaceholder));
			for (const [rKey, rValue] of Object.entries(user)) {
				for (const [k, v] of Object.entries(rValue)) {
					for (const [eKey, eValue] of Object.entries(v)) {
						if (eValue.hasOwnProperty('timestamp')) {
							playersPerUser[idx]['timeAdded'] = moment.unix(eValue['timestamp']['seconds']).format("YYYY-MM-DD HH:mm:ss")
						}
						if (eValue.hasOwnProperty('value')) {
							for (const [positionKey, positionValue] of Object.entries(eValue['value'])) {
								if (formation.hasOwnProperty(positionKey)){
									positionValue.forEach( vote => {
										if(!formation[positionKey][vote.position]['votes'].hasOwnProperty(vote.playerId)){
											formation[positionKey][vote.position]['votes'][vote.playerId] = {
												percentage: null, 
												data: players[vote.playerId],
												users: []
											};
										}
										/* Passing the user id so that we can have details later */
										formation[positionKey][vote.position]['votes'][vote.playerId]['users'].push(key)
										const playerName = players[vote.playerId]?.name.replace(/["]/g, "'");
										eleven = `${eleven}${playerName};`;
									})
								}else{
									let valueBasedOnFieldType = positionValue
									if (typeof valueBasedOnFieldType === 'boolean') {
										valueBasedOnFieldType = valueBasedOnFieldType ? 'Yes' : 'No'
									}
									if(!playersPerUser[idx][positionKey]) playersPerUser[idx][positionKey] = valueBasedOnFieldType;
								}
							}
						}
					}
				}
			}
			playersPerUser[idx].players = `"${eleven}"`;
			totalVotes++;
		})
		
		/* Calculate percentage for each player */
		for (const [key, value] of Object.entries(formation)) {
			if (typeof generalPlaceholder.formation[key] == 'string') continue;
			for (const [positionKey, positionValue] of Object.entries(value)) {
				let max = -Infinity;
				let totalPositionVotes = 0;

				for (const [voteKey, voteValue] of Object.entries(positionValue.votes)) {
					totalPositionVotes += voteValue.users.length;
				}
				for (const [voteKey, voteValue] of Object.entries(positionValue.votes)) {
					voteValue.percentage = 100 * voteValue.users.length / totalPositionVotes;
					voteValue.absolute = voteValue.users.length;
					if(voteValue.absolute > max){
						positionValue.mostVotedPlayer = voteValue;
						max = voteValue.absolute;
					}
				}
			}
		}
		console.log(playersPerUser);
		console.log('Calculated results for soccer line up :', formation)
		resolve([formation, null, totalVotes, playersPerUser]) //formation corresponds to generalResults
	})
}
export const getWheelOfPrizesResults = (unMappedUserResults, resultsCurrentElement, onlyValidAnswers) => {
	return new Promise((resolve, reject) => {
		let o = []
		const keys = Object.keys(unMappedUserResults);
		let generalPlaceholder = JSON.parse(JSON.stringify(resultsCurrentElement.generalReportPlaceholder));
		keys.map((key) => {
			const user = unMappedUserResults[key];
			let detailedPlaceholder = JSON.parse(JSON.stringify(resultsCurrentElement.rowPlaceholder));
			for (const [rKey, rValue] of Object.entries(user)) {
				for (const [k, v] of Object.entries(rValue)) {
					for (const [eKey, eValue] of Object.entries(v)) {
						if (eValue.hasOwnProperty('timestamp')) {
							detailedPlaceholder.timeAdded = moment.unix(eValue['timestamp']['seconds']).format("YYYY-MM-DD HH:mm:ss")
						}
						if (eValue?.value){
							for (const [vKey, vValue] of Object.entries(eValue['value'])) {
								if(vKey === 'id'){
									if (detailedPlaceholder.hasOwnProperty(vValue)) detailedPlaceholder[vValue] = generalPlaceholder[vValue];	
									if (generalPlaceholder.hasOwnProperty(vValue)) generalPlaceholder[vValue].prizeCount++;	
									continue;
								}
								let valueBasedOnFieldType = vValue
								if (typeof valueBasedOnFieldType == 'boolean') {
									valueBasedOnFieldType = valueBasedOnFieldType ? 'Yes' : 'No'
								}
								if (detailedPlaceholder.hasOwnProperty(vKey)) detailedPlaceholder[vKey] = valueBasedOnFieldType;
							}
						}
					}
				}
			}
			let isValidAnswer = true
			/** Filter only the valid answers */
			if (onlyValidAnswers) {
				/**
					** In order to check if the answer is valid,
					** We find the keys that represent the valid answers (Mostly Name-Surname of registration).
					** Then check the row values against those keys
				*/
				const keysThatRepresentValidAnswers = resultsCurrentElement.tableHeader.filter((item) => ['registration_firstName', 'registration_lastName', 'registration_email'].includes(item.type) && item.type)
				if (keysThatRepresentValidAnswers.length > 0) {
					/**
						** Keep a sum of the valid fields the user has completed.
						**For example if he has completed at least lastname then we say that the answer is valid
					*/
					const validCount = keysThatRepresentValidAnswers.reduce((sum, item) => detailedPlaceholder[item.key] ? sum + 1 : sum, 0)
					isValidAnswer = validCount > 0
				}
			}
			/* Only keep the prize if the user won */
			const totalPrizes = Object.entries(generalPlaceholder).length
			const lostPrizes = Object.entries(detailedPlaceholder).filter((val) => val[1] === 'No prize')
			/* It means that the user has not won any prize */
			if(totalPrizes === lostPrizes.length){
				for (let index = 0; index < lostPrizes.length-1; index++) {
					const element = lostPrizes[index];
					delete detailedPlaceholder[element[0]]
				}	
			}else{
				for (const [key, value] of Object.entries(detailedPlaceholder)) {
					if(value === 'No prize'){
						delete detailedPlaceholder[key];
					}
				}
			}
			if (isValidAnswer) o.push(Object.values(detailedPlaceholder));
		})
		o.sort((a, b) => {
			let a1 = a[a.length - 1]
			let b1 = b[b.length - 1]
			return new Date(b1) - new Date(a1);
		})
		resolve([o, Object.values(generalPlaceholder)])
	})
}
export const getScratchResults = (unMappedUserResults, resultsCurrentElement, onlyValidAnswers) => {
	return new Promise((resolve, reject) => {
		let o = []
		const keys = Object.keys(unMappedUserResults);
		let generalPlaceholder = JSON.parse(JSON.stringify(resultsCurrentElement.generalReportPlaceholder));
		keys.forEach((key) => {
			const user = unMappedUserResults[key];
			let detailedPlaceholder = JSON.parse(JSON.stringify(resultsCurrentElement.rowPlaceholder));
			for (const [rKey, rValue] of Object.entries(user)) {
				for (const [k, v] of Object.entries(rValue)) {
					for (const [eKey, eValue] of Object.entries(v)) {
						if (eValue.hasOwnProperty('timestamp')) {
							detailedPlaceholder.timeAdded = moment.unix(eValue['timestamp']['seconds']).format("YYYY-MM-DD HH:mm:ss")
						}
						if (eValue?.value){
							for (const [vKey, vValue] of Object.entries(eValue['value'])) {
								if(vKey === 'id'){
									if (detailedPlaceholder.hasOwnProperty(vValue)) detailedPlaceholder[vValue] = generalPlaceholder[vValue];	
									if (generalPlaceholder.hasOwnProperty(vValue)) generalPlaceholder[vValue].prizeCount++;	
									continue;
								}
								let valueBasedOnFieldType = vValue
								if (typeof valueBasedOnFieldType == 'boolean') {
									valueBasedOnFieldType = valueBasedOnFieldType ? 'Yes' : 'No'
								}
								if (detailedPlaceholder.hasOwnProperty(vKey)) detailedPlaceholder[vKey] = valueBasedOnFieldType;
							}
						}
					}
				}
			}
			let isValidAnswer = true
			/** Filter only the valid answers */
			if (onlyValidAnswers) {
				/**
					** In order to check if the answer is valid,
					** We find the keys that represent the valid answers (Mostly Name-Surname of registration).
					** Then check the row values against those keys
				*/
				const keysThatRepresentValidAnswers = resultsCurrentElement.tableHeader.filter((item) => ['registration_firstName', 'registration_lastName', 'registration_email'].includes(item.type) && item.type)
				if (keysThatRepresentValidAnswers.length > 0) {
					/**
						** Keep a sum of the valid fields the user has completed.
						**For example if he has completed at least lastname then we say that the answer is valid
					*/
					const validCount = keysThatRepresentValidAnswers.reduce((sum, item) => detailedPlaceholder[item.key] ? sum + 1 : sum, 0)
					isValidAnswer = validCount > 0
				}
			}
			/* Only keep the prize if the user won */
			const totalPrizes = Object.entries(generalPlaceholder).length
			const lostPrizes = Object.entries(detailedPlaceholder).filter((val) => val[1] === 'No prize')
			/* It means that the user has not won any prize */
			if(totalPrizes === lostPrizes.length){
				for (let index = 0; index < lostPrizes.length-1; index++) {
					const element = lostPrizes[index];
					delete detailedPlaceholder[element[0]]
				}	
			}else{
				for (const [key, value] of Object.entries(detailedPlaceholder)) {
					if(value === 'No prize'){
						delete detailedPlaceholder[key];
					}
				}
			}
			if (isValidAnswer) o.push(Object.values(detailedPlaceholder));
		})
		/* Sort the results by date */
		o.sort((a, b) => {
			let a1 = a[a.length - 1]
			let b1 = b[b.length - 1]
			return new Date(b1) - new Date(a1);
		})
		resolve([o, Object.values(generalPlaceholder)])
	})
}