import React, {useMemo} from 'react'
import {SortableHandle} from 'react-sortable-hoc';
import Dropdown from '../../../utils/Dropdown';
import { getTypesAndElements } from '../../../utils/PageElements';

function Page({page, i, goTo, duplicatePage, currentCampaign, getPageCss, setDeleteModal}) {
	const [nonDecorativeElements] = getTypesAndElements();

	const getPageEnding = page => {
		const endings = ['th', 'st', 'nd', 'd'];
		const index = page % 10;
		return endings[index < 4 ? index : 0];
	}
	const pageType = useMemo(() => {
		const targetPage = currentCampaign.pages[i];
		if(!targetPage) return {type: 'decoration', text: 'Decoration'};
		/* Return the first non-decorative element as the type of the campaign */
		const f = nonDecorativeElements.find((nonDecoElement) => {
			return targetPage.elements.find((element) => nonDecoElement.type === element.type);
		})
		if(!f) return {type: 'decoration', text: 'Decoration'};
		return f;
	}, [i, currentCampaign, nonDecorativeElements])

	const isDraggable = useMemo(() => {
		const campaignType = currentCampaign?.type;
		if(!campaignType) return true;
		const currentType = nonDecorativeElements.find(el => el.type === campaignType);
		if(!currentType) return true;

		return !currentType.nonDraggablePages.includes(pageType?.type) && (i !== currentCampaign.pages.length-1);
	}, [i, currentCampaign, nonDecorativeElements, pageType])
	
	const isDeletable = useMemo(() => {
		const campaignType = currentCampaign?.type;
		if(!campaignType) return true;
		const currentType = nonDecorativeElements.find(el => el.type === campaignType);
		if(!currentType) return true;

		return !currentType.nonDeletablePages.includes(pageType?.type);
	}, [currentCampaign, nonDecorativeElements, pageType])

	const DragHandle = SortableHandle(() => <span className="material-icons font--22 item pointer">drag_indicator</span>);

	return (
		<li onClick={ () => goTo(page, 'normal') }>
			<div className="flex flex--row">
				<div className={getPageCss(page.id, i)}>
					<span className="campaign__edit-pageNumber">{i+1}{getPageEnding(i+1)}</span>
					{ i  < currentCampaign.pages.length-1 && 
						<div className="campaign__edit-pageText">
							Page
							<div className="text--muted font--12">{pageType.text}</div>
						</div>
					}
					{ i  === currentCampaign.pages.length-1 &&
						<>
							<div className="campaign__edit-pageText text--warning">
								End
							</div>
							<div className="text--muted font--10">
								This is the last page of the campaign. Say thanks to your participants.
							</div>
						</>
					}
					<div className="campaign__edit-pageActions">
						{ i  !== currentCampaign.pages.length-1 &&
							<div>
								<Dropdown>
									<div className="btn btn-sm dropdown-trigger">
										<div className="material-icons pointer font--20">
											tune
										</div>
									</div>
									<div className="dropdown__menu">
										<div className="dropdown__content">
											<div className="dropdown__item page__dropdown-action" onClick={() => duplicatePage(page)}>
												<span className="material-icons vm--align mright--5">
													content_copy
												</span>
												<span className="font--12">Duplicate Page</span>
											</div>
											{ isDeletable &&
												<div className="dropdown__item page__dropdown-action" onClick={() => setDeleteModal(page)}>
													<span className="material-icons vm--align mright--5 text--danger">
														clear
													</span>
													<span className="font--12">Delete Page</span>
												</div>
											}
										</div>
									</div>
								</Dropdown>
							</div>
						}
						{ isDraggable &&
							<div className="btn btn-sm float--right">
								<DragHandle/>
							</div>
						}
					</div>
				</div>

			</div>
		</li>
	)
}

export default Page