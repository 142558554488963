import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ColorPallete from "../../../utils/ColorPallete";
import RichText from "../../../utils/RichText";
import {updateElement} from "../../../utils/UpdateFunctions";
import EditableInput from "../../../utils/EditableInput";
import { editElement } from "../../../../actions/UserActions";

function TextElementOptions() {
	const dispatch = useDispatch();
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const {background, html, marginTop, textColor} = elementCopy;
	const update = (key, value) => {
		if(key == 'marginTop') value += 'px';
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
		.then(() => {
			//alert('1')
		})
		.catch(() => {
			//alert('2')
		})
	}
	return (
		<div>
			<div className="campaign__option">
				<label className="text--bold">Content</label>
				<RichText item={'html'} value={html} updateAction={update} />
			</div>
			<div className="flex flex--row flex--wrap mtop--20">
				<div className="campaign__option campaign__option-50 campaign__option-act">
					<label className="text--bold">Top Margin (pixels)</label>
					<div className="font--12 text--muted">The space the element has from the top</div>
					<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
				</div>
				<div className="campaign__option campaign__option-50 campaign__option-act mleft--5">
					<label className="text--bold">Background color</label>
					<div className="text--muted font--12">Preferable if you have a background image and the text is not visible</div>
					<ColorPallete item="background" value={background} type={'rgba'} updateAction={update} />
				</div>
			</div>
		</div>
	);
}

export default TextElementOptions;
