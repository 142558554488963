export const storeAddOrganization = (data) => {
	return {
		type: 'ORGANIZATIONS_ADD',
		data: data
	}
}
export const storeSetOrganizations = (data) => {
	return {
		type: 'ORGANIZATIONS_SET',
		data: data
	}
}
export const storeClearOrganizations = () => {
	return {
		type: 'ORGANIZATIONS_CLEAR',
		data: []
	}
}
export const storeDeleteOrganization = (organization_id) => {
	return {
		type: 'ORGANIZATIONS_DELETE_ORGANIZATION',
		data: organization_id
	}
}
export const storeAddIncomingRoleRequest = (data) => {
	return {
		type: 'ORGANIZATIONS_ADD_INCOMING_REQUEST',
		data: data
	}
}
export const storeClearIncomingRoleRequests = () => {
	return {
		type: 'ORGANIZATIONS_CLEAR_INCOMING_REQUESTS',
		data: []
	}
}
export const storeEditIncomingRoleRequest = (data) => {
	return {
		type: 'ORGANIZATIONS_EDIT_INCOMING_REQUEST',
		data: data
	}
}