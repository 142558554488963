import { useState, useMemo, useRef } from 'react';
import RegistrationPdfResult from './RegistrationPdfResult';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

function RegistrationTableResults({header, rows, searchTerm}) {
	const DEFAULTSIZE = 150;
	const [newPageSize, setNewPageSize] = useState(DEFAULTSIZE);
	const prevPageSize = useRef(0);
	
	const visibleRows = useMemo(() => {
		const l = [...rows];
		const rowsToLoad = newPageSize > l.length ? l.length : newPageSize;
		const out = l.splice(0, rowsToLoad);
		prevPageSize.current = out.length;
		return out;
	}, [rows, newPageSize, prevPageSize])
	
	const loadMore = () => {
		setNewPageSize(visibleRows.length + DEFAULTSIZE);
	}

	const getValueByType = (index, value) => {
		const type = header[index]?.type;
		return type === "registration_signature" ? <img src={value} width={70} alt="signature" /> : value
	}
	const cantLoadMore = () => {
		return searchTerm || (visibleRows.length >= rows.length);
	}
	return (
		<>
			<Table key={1}>
				<Thead>
					<Tr>
						<Th style={{width: '70px'}}>#</Th>
						{header.map((hItem, index) => {
							return <Th key={`registration__header${index}`}>{hItem.value}</Th>
						})}
						<Th>
							<span className="material-icons font--25">
								picture_as_pdf
							</span>
						</Th>
					</Tr>
				</Thead>
				<Tbody>
					{ visibleRows.map( (row, index) => {
						return (
							<Tr key={`row__${index}`}>
								<Td>{index+1}</Td>
								{row.map((value, i) => {
									return (
										<Td style={{overflow: 'hidden', textOverflow: 'ellipsis'}} title={getValueByType(i, value)} key={`col__${index}`}>
											{getValueByType(i, value)}
										</Td>
									)
								})}
								<Td>
									<RegistrationPdfResult index={index} calculatedResults={rows[index]} tableHeader={header} />
								</Td>
							</Tr>
						)
					})}
				</Tbody>
			</Table>
			<div className="text--center mtop--20">
				<button className="btn btn-primary" onClick={loadMore} disabled={cantLoadMore()}>
					Load more
					<span className="material-icons">expand_circle_down</span>
				</button>
			</div>
		</>
	)
}

export default RegistrationTableResults