import { useState } from "react";
import "../../../scss/pages/Login.scss";
import logo from '../../../images/logo__white.png';
import ThemeSwitch from "../../utils/ThemeSwitch";
import { sendResetPasswordEmail } from '../../../firebaseActions.mjs';

function PasswordReset() {
	const [email, setEmail] = useState('');
	const [status, setStatus] = useState(null);
	const submit = () => {
		setStatus(null)
		sendResetPasswordEmail(email)
		.then((data) => {
			setStatus('success')
		})
		.catch((e, b) => {
			setStatus('error')
		})
	}
	return (
		<div className="login">
			<div className="login__content">
				<div className="login__body">
					<a className="btn btn__transparent" href="/">
						<span className="material-icons">
							arrow_back_ios_new
						</span>
					</a>
					<div className="flex flex--row justify--center">
						<img src={logo} width="250" />
						<ThemeSwitch />
					</div>
					{ status === null &&
						<>
							<div className="text--center mtop--20">
								<h2 className="">Password Reset</h2>
							</div>
							<div className="text--center mtop--10">Enter your email and click on the link</div>
							<div className="mtop--20">
								<div className="mtop--10">
									<label className="text--bold">Email</label>
									<input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={'Type here...'} />
								</div>
								<div className="mtop--10 btn btn-primary btn-100" disabled={!email} onClick={submit}>
									Submit
								</div>
							</div>
						</>
					}
					{status === 'error' &&
						<div className="text--center text--danger">
							An error error occured. Please try again later!
						</div>
					}
					{status === 'success' &&
						<div className="text--center text--success">
							An email was sent to your inbox. Click the link and reset your password!
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default PasswordReset;
