import { useState, Fragment } from 'react';
import {
	removeUserFromOrganization,
} from '../../../firebaseActions.mjs';
import ConfirmDelete from '../../utils/ConfirmDeleteModal'
import { Notification } from '../../utils/Misc.jsx';

const OrganizationUsers = ({ users, setUsers, organization_id, myRole }) => {
	const [revokeModal, setRevokeModal] = useState(null);
	const revoke = (user_id) => {
		removeUserFromOrganization(user_id, organization_id)
		.then((data) => {
			if (data) {
				setUsers(users.filter( user => user.id !== user_id))
				Notification('User removed', 'success');
				setRevokeModal(null);
			}
		})
		.catch((e) => {
			Notification('The user access could not be revoked. Please try again later.', 'error')
			console.error(e)
		})
	}
	return (
		<div className="mtop--20">
			<table>
				<thead>
					<tr>
						<td>Email</td>
						<td>Action</td>
					</tr>
				</thead>
				<tbody>
					{users.map((user) => {
						return (
							<Fragment key={`admin__${user.id}`}>
								{!user?.hidden ?
									<tr className="card">
										<td>
											{user.email}
										</td>
										<td className="">
											{ myRole !== 'manager' && <div className='btn btn-danger-soft btn-sm' onClick={() => setRevokeModal(user.id)}>Revoke</div> }
										</td>
									</tr>
									: ''
								}
							</Fragment>
						)
					})}
				</tbody>
			</table>
			{revokeModal && <ConfirmDelete closeCb={setRevokeModal} confirmCb={revoke} confirmData={revokeModal} text={'Are you sure you want to remove this user from the organization?'} />}
		</div>
	)
}

export default OrganizationUsers