import { useState, useEffect } from "react";
import RichText from "../../../utils/RichText";
import {genUUID} from "../../../utils/Misc";
import ColorPallete from "../../../utils/ColorPallete";
import {useDispatch, useSelector} from 'react-redux';
import EditableInput from "../../../utils/EditableInput";
import {updateElement} from "../../../utils/UpdateFunctions";
import { editElement } from "../../../../actions/UserActions";
import ImageUpload from "../../../utils/ImageUpload";
import imgChoice1 from '../../../../images/imgChoice1.png';
import imgChoice2 from '../../../../images/imgChoice2.png';
import {Notification} from '../../../utils/Misc';

function NumericPredictionElementOptions() {
	const dispatch = useDispatch();
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const {header, divider, options, vs, marginTop, max, min, optionLayout, textPosition, background, showOptionsText} = elementCopy;

	const updateHeader = (key, value) => {
		elementCopy.header[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const updateGeneral = (key, value) => {
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const updateVS = (key, value) => {
		elementCopy.vs[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const updateDivider = (key, value) => {
		if(key == 'width') value += 'px';
		elementCopy.divider[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const addOption = (text) => {
		let o = {
			id: genUUID(),
			image: 'https://via.placeholder.com/150/433F66/fff?Text='+text,
			text: text
		}
		elementCopy.options.push(o);
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const deleteOption = (option_id) => {
		if(elementCopy.options.length == 1){
			Notification("This element should have at least one option.");
			return false;
		}
		const o = elementCopy.options.filter( current => current.id !== option_id )
		elementCopy.options = o;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const updateOption = (key, value, extra) => {
		for (let i = 0; i < options.length; i++) {
			if(options[i]['id'] == extra.option_id){
				elementCopy.options[i][key]  = value;
			}
		}
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	return (
		<div>
			<div>
				<div className="text__hr">
					<span className="text__hr-title">
						HEADER
					</span>
				</div>
				<div className="campaign__option mtop--15">
					<label className="text--bold">Text</label>
					<RichText item={'html'} value={elementCopy.header.html} isSmall={true} updateAction={updateHeader} />
				</div>
				<div className="campaign__option mtop--15">
					<label className="text--bold">Background color</label>
					<ColorPallete item="background" value={header.background} type={'hex'} updateAction={updateHeader} />
				</div>
			</div>
			<div>
				<div className="text__hr">
					<span className="text__hr-title">
						<label className="">CONTENT</label>
					</span>
				</div>
				<div className="campaign__option mtop--15">
					<label className="text--bold">Options</label>
					<div>
						{ elementCopy.options.map( (option, index) => {
							return (
								<div key={index} className="numeric__option">
									<div className="numeric__option-left">
										<ImageUpload dimensions={'150x150px'} extra={{option_id: option.id}} campaign_id={currentCampaignId} file={option.image} item="image" updateAction={updateOption} />
									</div>
									<div className="numeric__option-right mleft--10">
										<div className="numeric__option-text">
											<EditableInput nonEmpty extra={{option_id: option.id}} item="text" type="text" startValue={option.text} updateAction={updateOption} placeholder="Type here..." />
										</div>
										<div className="pointer" onClick={() => deleteOption(option.id)}>
											<span className="material-icons font--30 numeric__option-delete text--danger">
												clear
											</span>
										</div>
									</div>
								</div>
							)
						}) }
						<div className="btn btn-primary-soft btn-100 mtop--10" onClick={() => addOption("Team")}>
							<span className="material-icons font--20 vm--align">
								add
							</span>
							Add option
						</div>
					</div>
				</div>
				<div className="campaign__option flex flex--row">
					<EditableInput item="showOptionsText" type="checkbox" isSwitch startValue={showOptionsText} updateAction={updateGeneral} />
					<label className="font--13 text--bold mleft--10">Show text on each option</label>
				</div>
				<div className="campaign__option mtop--15 flex flex--row flex--wrap">
					<div className="flex--33">
						<label className="font--13 text--bold">Options text position</label>
						<div className="flex flex--row">
							<button className={ textPosition == 'above' ? 'btn btn-primary' : 'btn btn-secondary'} onClick={ () => updateGeneral('textPosition', 'above')}>Above</button>
							<button className={ textPosition == 'below' ? 'btn btn-primary' : 'btn btn-secondary'} onClick={ () => updateGeneral('textPosition', 'below')}>Below</button>
						</div>
					</div>
					<div className="flex--33 mleft--5">
						<label className="font--13 text--bold">Options Min</label>
						<EditableInput item="min" type="number" startValue={min} updateAction={updateGeneral} placeholder="0" />
					</div>
					<div className="flex--33 mleft--5">
						<label className="font--13 text--bold">Options Max</label>
						<EditableInput item="max" type="number" startValue={max} updateAction={updateGeneral} placeholder="99" />
					</div>
				</div>
				<div>
					<label className="font--13 text--bold">Options layout</label>
					<div className="flex flex--row flex--wrap">
						<div className={optionLayout == 'row' ? 'card__check-option card__check-optionActive' : 'card__check-option'} onClick={ () => updateGeneral('optionLayout', 'row')}>
							<div className="card__check-radio">
								<span className="material-icons">
									done
								</span>
							</div>
							<img src={imgChoice2} className="img__responsive"/>
						</div>
						<div className={optionLayout == 'column' ? 'card__check-option card__check-optionActive' : 'card__check-option'} onClick={ () => updateGeneral('optionLayout', 'column')}>
							<div className="card__check-radio">
								<span className="material-icons">
									done
								</span>
							</div>
							<img src={imgChoice1} className="img__responsive"/>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div className="text__hr">
					<span className="text__hr-title">
						<label className="">VS</label>
					</span>
				</div>
				<div className="campaign__option mtop--15 md__input">
					<EditableInput type="checkbox" item="visible" startValue={vs.visible} updateAction={updateVS}/>
					<label className="font--13 text--bold">Show VS</label>
				</div>
				{ vs?.visible ?
					<div>
						<div className="campaign__option mtop--15">
							<label className="text--bold">Text</label>
							<EditableInput item="text" type="text" characterCount={4} startValue={vs.text} updateAction={updateVS}/>
						</div>
						<div className="campaign__option mtop--15 flex flex--row flex--wrap">
							<div className="flex--50">
								<label className="text--bold">Background color</label>
								<ColorPallete item="background" value={vs.background} type={'hex'} updateAction={updateVS} />
							</div>
							<div className="flex--50">
								<label className="text--bold">Text color</label>
								<ColorPallete item="color" value={vs.color} type={'hex'} updateAction={updateVS} />
							</div>
						</div>
					</div> : ''
				}
			</div>
			<div>
				<div className="text__hr">
					<span className="text__hr-title">
						<label className="">DIVIDER</label>
					</span>
				</div>
				<div>
					<div className="campaign__option mtop--15">
						<label className="text--bold">Width</label>
						<EditableInput item="width" type="number" startValue={divider.width.substring(0, divider.width.length - 2)} updateAction={updateDivider} placeholder="0" />
					</div>
					<div className="campaign__option mtop--15">
						<label className="text--bold">Color</label>
						<ColorPallete item="background" value={divider.background} type={'hex'} updateAction={updateDivider} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default NumericPredictionElementOptions;
