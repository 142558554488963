import ColorPallete from "../../../utils/ColorPallete";
import {useDispatch, useSelector} from 'react-redux';
import {updateCampaignDB} from "../../../utils/UpdateFunctions";
import { editCampaign } from "../../../../actions/UserActions";

function PoweredByElementOptions() {
	const dispatch = useDispatch();
	const {currentCampaignId, currentCampaign} = useSelector(state => state.user);
	const {primaryTextColor, primaryColor} = currentCampaign;
    const update = (key, value) => {
		currentCampaign[key] = value;
		updateCampaignDB(currentCampaignId,  {[key]: currentCampaign[key]}, dispatch, editCampaign)
	}
	return (
		<div>
			<div className="campaign__option">
				<label className="text--bold">Background Color</label>
                <ColorPallete dropdown={true} item="primaryColor" value={primaryColor} type={'hex'} updateAction={update} />
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Text color</label>
                <ColorPallete dropdown={true} item="primaryTextColor" value={primaryTextColor} type={'hex'} updateAction={update} />
			</div>
		</div>
	);
}

export default PoweredByElementOptions;
