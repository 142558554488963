import {useDispatch} from 'react-redux';
import ColorPallete from "../../../../utils/ColorPallete";
import EditableInput from "../../../../utils/EditableInput";
import ImageUpload from '../../../../utils/ImageUpload';
import { updateElement } from "../../../../utils/UpdateFunctions";
import { editElement } from "../../../../../actions/UserActions";
import RichText from "../../../../utils/RichText";

function WheelOfPrizesLook({ currentCampaignId, currentPage, currentElement, elementCopy, setElementCopy }) {
	const dispatch = useDispatch();
	const { 
		buttonColor,
		textColor,
		marginTop,
		buttonText,
		winMessage,
		lostMessage,
		borderImage,
		centerImage,
		perpendicularText,
		borderImageSize
	} = elementCopy;
	
	const update = (key, value, extra) => {
		if (key === 'marginTop') value += 'px';
		elementCopy[key] = value
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	return (
		<div>
			<div>
				<label className="text--bold">Element</label>
				<div className="campaign__option mtop--20">
					<label className="text--bold">Top Margin (pixels)</label>
					<div className="font--12 text--muted">The space the element has from the top</div>
					<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
				</div>
			</div>
			<div className="mtop--20">
				<label className="text--bold">Wheel</label>
				<div className="form__group">
					<div className="form__group-label">
						Button Text
					</div>
					<div className="form__group-content">
						<EditableInput item="buttonText" type="text" startValue={buttonText} updateAction={update} placeholder={'Spin'} />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Button Color
					</div>
					<div className="form__group-content">
						<ColorPallete item="buttonColor" value={buttonColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Text Color
					</div>
					<div className="form__group-content">
						<ColorPallete item="textColor" value={textColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="form__group">
					<div className="form__group-label">
						Text Orientation
					</div>
					<div className="form__group-content">
						<div className="form__group-check">
							<div className={`${!perpendicularText && 'form__group-checkActive'} font--12`} onClick={() => update('perpendicularText', false)}>
								Parallel
							</div>
							<div className={`${perpendicularText && 'form__group-checkActive'} font--12`} onClick={() => update('perpendicularText', true)}>
								Perpendicular
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex--row flex--wrap mtop--20">
					<div className="campaign__option campaign__option-50">
						<label className="text--bold">Border Image</label>
						<div className="text--muted font--12">An image placed on the radius of the wheel. Add your custom border image to enhance the wheel design.</div>
						<div className="campaign__option-act">
							<ImageUpload dimensions={'1500x1500 px'} campaign_id={currentCampaignId} file={borderImage} item="borderImage" updateAction={update} />
						</div>
						<div className="form__group mtop--20">
							<div className="form__group-label">
								Image Size (%)
								<div className="font--12 text--muted">Adjust accordingly. (Can go above 100%)</div>
							</div>
							<div className="form__group-content">
								<EditableInput item="borderImageSize" type="number" startValue={borderImageSize} updateAction={update} placeholder={'Type here'} />
							</div>
						</div>
					</div>
					<div className="campaign__option campaign__option-50 mleft--5">
						<label className="text--bold">Center Image</label>
						<div className="text--muted font--12">Add your custom image inside the wheel's center.</div>
						<div className="campaign__option-act">
							<ImageUpload dimensions={'150x150 px'} campaign_id={currentCampaignId} file={centerImage} item="centerImage" updateAction={update} />
						</div>
					</div>
				</div>
			</div>
			<div className="mtop--20">
				<label className="text--bold">Win message</label>
				<RichText item={'winMessage'} returnEmptyString={true} value={winMessage} updateAction={update} isSmall={true} />
			</div>
			<div className="mtop--20">
				<label className="text--bold">Lost message</label>
				<RichText item={'lostMessage'} returnEmptyString={true} value={lostMessage} updateAction={update} isSmall={true} />
			</div>
		</div>
	);
}

export default WheelOfPrizesLook;
