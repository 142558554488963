import React, {useEffect, useState} from 'react'
import {
	getAWSCredentials,
	addAWSCredentials,
	removeAWSCredentials
} from '../../../firebaseActions.mjs';
import credentialsImage from '../../../images/undraw_security_re_a2rk.svg';
import noCredentialsImage from '../../../images/undraw_warning_re_eoyh.svg';

function OrganizationAPIKeys({organizationId, myRole}) {
	const [accessKey, setAccessKey] = useState(null);
	const [newSecretKey, setNewSecretKey] = useState('');
	const [newAccessKey, setNewAccessKey] = useState('');
	const [addCredentials, setAddCredentials] = useState(false);
	const [isProduction, setIsProduction] = useState(true);
	const [status, setStatus] = useState(null);

	const saveKeys = () => {
		setStatus('loading');
		addAWSCredentials(organizationId, newAccessKey, newSecretKey, isProduction)
		.then((data) => {
			if(data?.accessKeyId){
				setAccessKey(newAccessKey)
				setStatus(null);
				setAddCredentials(false);
				setNewSecretKey(null)
			}else{
				setStatus('error');
			}
		})
		.catch((e) => {
			setStatus('error');
		})
	}
	const deleteKeys = () => {
		removeAWSCredentials(organizationId)
		.then((data) => {
			if(data) setAccessKey(null);
		})
	}
	useEffect(() => {
		getAWSCredentials(organizationId)
		.then((data) => {
			if(data){
				setAccessKey(data.accessKeyId);
				setNewAccessKey(data.accessKeyId);
			}
		})
	}, [organizationId])
	return (
		<div className="card card__secondary">
			{(!accessKey && !addCredentials) && 
				<div className="text--center">
					<img src={noCredentialsImage} width={250} alt="Credentials"/>
					<h3>Add your API Keys.</h3>
					<div className="flex flex--row justify--center mtop--20">
						<div className="btn btn-primary btn-rounded" onClick={() => setAddCredentials(true)}>
							<span className="material-icons">
								add
							</span>
							Add Keys
						</div>
					</div>
				</div>
			}
			{(accessKey && !addCredentials) && 
				<div className="text--center">
					<img src={credentialsImage} width={250} alt="Credentials"/>
					<h3>Keys are set</h3>
					<h4>{accessKey}</h4>
					<div className="flex flex--row justify--center mtop--20">
						<div className="btn btn-primary btn-rounded" onClick={() => setAddCredentials(true)}>
							Replace Keys
						</div>
						<div className="btn btn-danger btn-rounded" onClick={deleteKeys}>
							Delete Keys
						</div>
					</div>
				</div>
			}
			{addCredentials && 
				<div className='mtop--20'>
					<h2>Set Up your API Keys</h2>
					<div className="form__group">
						<div className="form__group-label">
							<div className="flex flex--row">
								Public Access Key
							</div>
						</div>
						<div className="form__group-contentXl">
							<input type="text" placeholder="XXXXXXXXXXXXXXXXXXX" value={newAccessKey} onChange={(e) => setNewAccessKey(e.target.value)} />
						</div>
					</div>
					<div className="form__group">
						<div className="form__group-label">
							<div className="flex flex--row">
								Secret Access Key
							</div>
							<div className="text--muted font--12"></div>
						</div>
						<div className="form__group-contentXl">
							<div className="flex flex--row">
								<input type="text" placeholder="XXXXXXXXXXXXXXXXXXX" value={newSecretKey} onChange={(e) => setNewSecretKey(e.target.value)} />
							</div>
						</div>
					</div>
					<div className="form__group">
						<div className="form__group-label">
							<div className="">
								Production Endpoint?
								<div className="text--muted font--12">
									If this is switched off then the endpoint will default to staging.
								</div>
							</div>
							<div className="text--muted font--12"></div>
						</div>
						<div className="form__group-contentXl">
							<div className="flex flex--row">
								<input type="checkbox" className="switch" checked={isProduction} onChange={(e) => setIsProduction(e.target.checked)} />
							</div>
						</div>
					</div>
					{ status === 'error' && 
						<div className="text--danger">
							There seems to be an error. Please check the validity of your API Keys and try again.
						</div>
					}
					<div className="flex flex--row">
						{ accessKey &&
							<div className="btn btn-secondary btn-rounded btn-100 mtop--20" onClick={() => setAddCredentials(false)}>
								Cancel
							</div>
						}
						<div className="btn btn-primary btn-rounded btn-100 mtop--20" disabled={!newAccessKey || !newSecretKey || status === 'loading'} onClick={saveKeys}>
							{status !== 'loading' && 
								<>
									<span className="material-icons">
										save
									</span>
									Save
								</>
							}
							{ status === 'loading' && <span className="loader loader__sm"></span> }
						</div>
					</div>
					
				</div>
			}
		</div>
	)
}

export default OrganizationAPIKeys