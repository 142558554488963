import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import "../../../scss/partials/topBar.scss";
import { Notification } from '../../utils/Misc';
import EditableInput from "../../utils/EditableInput";
import EmbedModal from './EmbedModal';
import ThemeSwitch from "../../utils/ThemeSwitch";
import ShareModal from "../../utils/ShareModal";
import ChangeCampaignDateTime from "./ChangeCampaignDateTime";
import { modifyCampaignData } from '../../../firebaseActions.mjs'
import { updateCampaignDB } from "../../utils/UpdateFunctions";
import { editCampaign } from "../../../actions/UserActions";
import { Link } from "react-router-dom";

function TopBarCampaign(props) {
	const { currentCampaignId, currentCampaign } = useSelector(state => state.user);
	const dispatch = useDispatch();
	const [embedModal, setEmbedModal] = useState(false);
	const getCampaignName = () => {
		return currentCampaign ? currentCampaign.name : 'Go Back';
	}
	const getCampaignUrl = () => {
		const org = currentCampaign?.organizationId ? `${currentCampaign.organizationId}` : '';
		return `https://app.sportovivo.com/${currentCampaignId}/${org}`;
	}
	const getShareUrl = () => {
		const org = currentCampaign?.organizationId ? `&org=${currentCampaign.organizationId}` : '';
		return `https://app.sportovivo.com/share?campaign=${currentCampaignId}${org}`;
	}
	const getCustomUserUrl = () => {
		if(currentCampaign.organizationId!=='Kp67lwz6AaOTm8j2XlCR') return false;
		return `https://app.sportovivo.com/${currentCampaignId}?user_id=`;
	}
	const update = (key, value) => {
		currentCampaign[key] = value;
		updateCampaignDB(currentCampaignId, { [key]: currentCampaign[key] }, dispatch, editCampaign)
		.catch((e) => {
			if(e?.errorName == 'CampaignLockedError'){
				Notification("The campaign is locked", 'error');
			}else{
				Notification("An unexpected error occured, Please try again later!", 'error');
			}
			
		})
	}
	const updateCampaign = (key, value, extra) => {
		if (key === 'name') {
			if (value.length == 0) {
				Notification("The name field can't be empty, This value will not be saved.", 'error');
				return false;
			}
		}
		modifyCampaignData(currentCampaignId, { [key]: value })
	}
	return (
		<>
			{currentCampaign &&
				<div className="top__bar">
					<div className="flex flex--row top__bar-top">
						<Link to="/" className="btn btn-transparent btn-sm">
							<span className="material-icons font--30 vm--align">
								arrow_back_ios
							</span>
						</Link>
						<div className="text--bold font--14 topbar__name">
							<EditableInput characterCount={150} type="text" withPencil item="name" startValue={getCampaignName()} updateAction={updateCampaign} />
						</div>
						<>
							<span className="vm--align text--primary font--30 mleft--10"> | </span>
							<ChangeCampaignDateTime endDate={currentCampaign.endDate} />
						</>
					</div>
					<div className="float--right flex flex--row top__bar-bottom">
						<ThemeSwitch />
						<div className="divider"></div>
						<div className="flex flex--row">
							<a className="btn btn-primary text--normal" href={getShareUrl()} target="_blank">
								<span className="material-icons font--20 vm--align">mobile_friendly</span>
								<span className="topbar__actionItem">Live App</span>
							</a>
							<button className="btn btn-secondary" onClick={() => setEmbedModal(true)}>
								<span className="material-icons font--20 vm--align">code</span>
								<span className="topbar__actionItem">Embed</span>
							</button>
							<ShareModal campaign_url={getShareUrl()} showShare={currentCampaign.showShare} updateAction={update} showOnlyIcon={false} />
						</div>
					</div>
				</div>
			}
			{embedModal && <EmbedModal closeCb={setEmbedModal} campaign_url={getCampaignUrl()} custom_user_url={getCustomUserUrl()}/>}
		</>
	);
}

export default TopBarCampaign;
