import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {deleteResponses} from '../../firebaseActions.mjs';
import {Notification, ErrorNotification} from './Misc';
import { editCampaign } from "../../actions/UserActions";

function ClearAnswers({currentCampaign, currentCampaignId}) {
    const [confirmClearAnswers, setConfirmClearAnswers] = useState(false);
    const [confirmText, setConfirmText] = useState('');
    const [loading, setLoading] = useState(null)
    const dispatch = useDispatch();

    const initDeleteAnswers = () => {
        setLoading('clearing')
		deleteResponses(currentCampaignId)
		.then((data) => {
            currentCampaign.locked = false;
            dispatch(editCampaign(currentCampaign));
            setLoading('success')
            setTimeout(() => {
                setLoading(null)
            }, 4000)
		})
		.catch((e) => {
            setLoading('error')
            setTimeout(() => {
                setLoading(null)
            }, 4000)
		})
		.finally(() => {
			setConfirmClearAnswers(false);
		})
	}
    const canDelete = () => {
        return confirmText == 'CONFIRM' ? true : false;
    }
	return (
		<>
            { loading === null &&
                <>
                    <div className="text--danger font--12">By clearing the answers all the data of this campaign will be lost. Prcceed with caution!</div>
                    {confirmClearAnswers &&
                        <div className="text--center mtop--20">
                            Type <span className="danger__message">CONFIRM</span> in order to delete the answers.
                            <br />
                            <input type="text" value={confirmText} onChange={(e) => setConfirmText(e.target.value)} className="input__50" placeholder="Type here..." />
                            <div className="mtop--20">
                                <button className="btn btn-secondary" onClick={() => setConfirmClearAnswers(false)}>
                                    Cancel
                                </button>
                                <button className="btn btn-danger-soft" disabled={!canDelete()} onClick={() => initDeleteAnswers()}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    }
                    {!confirmClearAnswers &&
                        <button className="btn btn-danger-soft mtop--10 btn-50" onClick={() => setConfirmClearAnswers(true)}>
                            <span className="material-icons font--20">
                                clear
                            </span>
                            Clear
                        </button>
                    }
                </>
            }
            {loading === 'clearing' &&
                <div className="text--center">Clearing...</div>
            }
            {loading === 'success' &&
                <div className="text--center text--success">Excellent. Your answers have been cleared. </div>
            }
            {loading === 'error' &&
                <div className="text--center text--danger">Excellent. Your answers have been cleared. </div>
            }
		</>
	)
}

export default ClearAnswers
