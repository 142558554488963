import { useState, useEffect, useRef, useCallback } from 'react';

const isValidUrl = urlString => {
	let url;
	try {
		url = new URL(urlString);
	}
	catch (e) {
		return false;
	}
	return url.protocol === "https:";
}

function ExtractParams(props) {
	const { onSubmit } = props;

	const [isError, setIsError] = useState(false)
	const textRef = useRef()

	const alertError = useCallback( () => {
		setIsError(true)
		setTimeout(() => setIsError(false), 3000)
	}, [])

	const paramsFromText = useCallback( () => {
		const pastedText = textRef.current.value
		
		const parsedDoc = new DOMParser().parseFromString(pastedText, "text/html");
		const iframes = parsedDoc.getElementsByTagName("iframe");
		if(iframes.length){
			const iframeElem = iframes[0]
			const extracted = {
				url: iframeElem.src,
				allow: iframeElem.allow,
				allowfullscreen: !!iframeElem.allowFullscreen
			}
			console.log("extracted", extracted)
			onSubmit(extracted)
			textRef.current.value = ''
		}
		else{
			alertError()
		}
		
	}, [onSubmit])

	useEffect(() => {
		return () => {

		}
	}, [])


	return (
		<>
			<textarea ref={textRef}></textarea>
			{isError &&
				<div className="font--14 text--danger text--right">
					Pasted code is invalid!
				</div>
			}
			
			<button className="btn btn-primary" onClick={paramsFromText} > Import Code </button>
		</>
	)
}

export default ExtractParams
