import { useState, useEffect } from "react";


function UGCFieldInput({ name, value, label, placeholder, isRequired, onChange, inputType, elementErrors }) {
	const [err, setErr] = useState(false);

	const getInputStyling = () => {
		let className = "FormFieldInput mtop--15";
		if (err) className += ' input--error';
		return className;
	}	
	
	useEffect(() => {
		elementErrors?.errors?.forEach(function (e) {
			setErr(true);
		});
	}, [elementErrors])

	return (
		<div className={getInputStyling()}>
			<label>{label && label}</label>
			{isRequired ? <b className="text--danger">*</b> : ''}
			<input onChange={(e) => onChange(name, e.target.value)} value={value} placeholder={placeholder} type={inputType} />
			{elementErrors?.errors.map((error) => <div className="text--danger">{error.message}</div>)}
		</div>
	);
};
export default UGCFieldInput;
