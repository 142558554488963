import 'animate.css';
import tinycolor from "tinycolor2";

function MiniLoader() {
	const color = tinycolor('#fff');
	return (
		<div className="text--center">
			<div className="jelly-triangle" style={{display: 'inline-block'}}>
				<div className="jelly-triangle__dot"></div>
				<div className="jelly-triangle__traveler"></div>
			</div>
			
		</div>
	);
}
export default MiniLoader;