import moment from "moment";
import { genUUID } from "../../utils/uuid.mjs";
const placeholderImage = '/images/common/placeholder.png';
export const CURRENT_VERSION = 7;
const background = (url, size) => {
    return {
        "url": url ? url : null,
        "position": {
            "x": "center",
            "y": "center"
        },
        "size": size ? size : "cover",
        "attachment": "scroll",
        "color": null,
        "repeat": "no-repeat",
    }
};
const tint = (tint) => {
    return {
        "background": tint ? tint : null,
    }
};
const font = () => {
    return {
        "family": 'Arial',
        "size": '14px',
    };
}
const numericPredictionOption = (text, image) => {
    return {
        "id": genUUID(),
        "text": text ? text : "Text",
        "image": image ? image : placeholderImage
    }
};
const pollOption = (text, image) => {
    return {
        "id": genUUID(),
        "text": text ? text : "Text",
    }
}
const imageChoiceOption = (text, image) => {
    return {
        "id": genUUID(),
        "text": text ? text : 'New Option',
        "image": image ? image : placeholderImage,
        "showText": true
    }
}
const mvpOption = (text, image) => {
    return {
        "id": genUUID(),
        "text": text ? text : 'New Option',
        "image": image ? image : placeholderImage,
        "showText": true,
        "attributes": []
    }
}
const registrationFormField = (type, label) => {
    let o = {
        "id": genUUID(),
        "type": type ? type : 'text',
        "required": false,
        "label": label ? label : "New Field",
        "placeholder": 'Type here...',
        "customId": type
    }
    if (type === 'email') {
        o.unique = true;
        o.required = true;
    }
    if (type === 'tel') {
        o.defaultCountry = 'el'
    }
    return o;
};
const pollCategory = (title) => {
    return {
        "id": genUUID(),
        "title": title ? title : 'New Category',
        "options": [pollOption('Player 1'), pollOption('Player 2')]
    }
}
const soccerLineUpPlayer = (name) => {
    return {
        "id": genUUID(),
        "name": name ? name : 'New Player',
        "image": null,
        "categories": [], // Category ids
    }
}
const soccerLineUpCategory = (title) => {
    return {
        "id": genUUID(),
        "title": title ? title : 'New Category'
    }
}
const slide = () => {
    return {
        "id": genUUID(),
        "image": placeholderImage,
        "label": null,
        "url": null,
        "target": "newTab",
    }
};
const slideShowCategory = (title) => {
    return {
        "id": genUUID(),
        "title": title ? title : 'New Category',
        "slides": [slide(), slide()]
    }
}

/* Elements */
const spacer = () => {
    return {
        "id": genUUID(),
        "type": 'spacer',
        "size": "10px",
    }
};
const freeText = () => {
    return {
        "id": genUUID(),
        "type": "freeText",
        "marginTop": "10px",
        "background": 'rgba(255,255,255,1)',
    }
};
const submitButton = () => {
    return {
        "id": genUUID(),
        "type": "submitButton",
        "marginTop": "10px",
        "background": null,
        "text": 'Submit',
        "textAlign": "center",
        "textColor": null,
        "fontSize": '14px',
        "width": 'auto', // full => Full width / auto => Auto width
    }
}
const text = data => {
    return {
        "id": genUUID(),
        "type": "text",
        "marginTop": "10px",
        "background": null,
        "html": data?.text ? data.text : "<p style='text-align:center;'>This is a new text element</p>",
        "textColor": null,
    }
};
const link = () => {
    return {
        "id": genUUID(),
        "type": "link",
        "marginTop": "10px",
        "background": null,
        "target": "newTab",
        "url": "https://sportovivo.com",
        "text": "Click to go",
        "textAlign": "center",
    }
};
const iframe = () => {
    return {
        "id": genUUID(),
        "type": "iframe",
        "marginTop": "10px",
        "url": "https://sportovivo.com", //full url not path
        "allow": null,
        "allowfullscreen": true
    }
};
const image = () => {
    return {
        "id": genUUID(),
        "type": "image",
        "marginTop": "10px",
        "size": "100px",
        "background": background(placeholderImage, 'contain'),
        "url": null,
        "target": "newTab",
    }
};
const numericPrediction = () => {
    return {
        "id": genUUID(),
        "type": "numericPrediction",
        "marginTop": "10px",
        "background": null,
        "header": {
            "background": null,
            "html": '<p style="text-align: center">Saturday</p>',
        },
        "options": [numericPredictionOption('Team A'), numericPredictionOption('Team B')],
        "textPosition": "above",
        "min": 0,
        "max": 1000,
        "divider": {
            "background": null,
            "width": "1px",
        },
        "vs": {
            "visible": true,
            "background": null,
            "text": "VS",
            "color": null,
            "size": "14px",
        },
        "optionLayout": "row",
        "showOptionsText": true,
    }
};
const registrationForm = () => {
    return {
        "id": genUUID(),
        "type": "registrationForm",
        "marginTop": "10px",
        "textColor": null,
        "background": null,
        "fields": [registrationFormField('firstName', 'Name'), registrationFormField('lastName', 'Surname'), registrationFormField('email', 'Email')],
        "submitText": 'Submit',
        "header": "<p style='text- align: center'>Registration</p>",
        "footer": null
    }
};
const poll = () => {
    return {
        "id": genUUID(),
        "type": "poll",
        "marginTop": "10px",
        "textColor": null,
        "background": null,
        "options": [pollOption("Option 1"), pollOption("Option 2")],
        "question": "<p style='text-align: center'>Choose your option</p>",
        "layout": "button", //dropdown, radio
        "required": true,
        "min": 1,
        "max": 1,
    }
};
const imageChoice = () => {
    return {
        "id": genUUID(),
        "type": "imageChoice",
        "marginTop": "10px",
        "textColor": null,
        "background": null,
        "options": [imageChoiceOption("Option 1"), imageChoiceOption("Option 2")],
        "question": "<p style='text-align: center'>Choose your option</p>",
        "required": true,
        "min": 1,
        "max": 1,
        "layout": 'column', // row, column
        "textPosition": 'below', // above, below
    }
};
const mvp = () => {
    return {
        "id": genUUID(),
        "type": "mvp",
        "marginTop": "10px",
        "textColor": null,
        "background": null,
        "options": [mvpOption("Player 1"), mvpOption("Player 2")],
        "question": "<p style='text-align: center'>Pick the player of the match!</p>",
        "required": true,
        "min": 1,
        "max": 1,
        "layout": 'column', // row, column
        "optionsTextColor": null, // hex, allow empty
        "optionsBackgroundColor": null, //rgba, allow empty
    }
};
const categorizedPoll = () => {
    return {
        "id": genUUID(),
        "type": "categorizedPoll",
        "marginTop": "10px",
        "textColor": "#000",
        "background": 'rgba(255,255,255,1)',
        "categories": [pollCategory()],
        "layout": 0,
        "multipleAnswers": true,
        "required": true
    }
};
function getTimestamp(dateString) {
    const date = moment(dateString, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").toDate();
    const timestamp = date.getTime();
    return timestamp;
}
const wheelSpreadStrategy = () => {
    const currentDate = new Date();
    const tomorrow = moment(currentDate).add(1, 'day').toDate();
    return {
        "strategy": "spread",
        "from": getTimestamp(currentDate),
        "to": getTimestamp(tomorrow),
    }
}
const wheelActiveStrategy = () => {
    const currentDate = new Date();
    const tomorrow = moment(currentDate).add(1, 'day').toDate();
    return {
        "strategy": "active",
        "open": getTimestamp(currentDate),
        "close": getTimestamp(tomorrow),
    }
}
const wheelReserveStrategy = () => {
    return {
        "strategy": "reserve",
        "dates": []
    }
}
const wheelPrize = ({ text, quantity }) => {
    return {
        "id": genUUID(),
        "text": text ? text : 'New Prize',
        "description": '',
        "image": null,
        "probability": null,
        "stock": null,
        "strategy": null,
    }
};
const wheelSlice = (text, image) => {
    return {
        "id": genUUID(),
        "image": image ? image : placeholderImage,
        "text": text ? text : 'New Category',
        "color": null,
        "textColor": null,
        "prizes": []
    }
}
const wheelOfPrizes = () => {
    return {
        "id": genUUID(),
        "type": "wheelOfPrizes",
        "participationProbability": 500000,
        "marginTop": "10px",
        "textColor": "#fff",
        "buttonColor": '#645CAA',
        "buttonText": "Spin",
        "winMessage": "<p style='text-align: center;'>Congratulations  you won</p>",
        "lostMessage": `<h2>Hey, you were unlucky this time.</h2><h3>Thank you for your participation.</h3>`,
        "showPrizeImage": true,
        "prizeImagePosition": "below", //above (text message)
        "slices": [wheelSlice('1'), wheelSlice('2'), wheelSlice('3'), wheelSlice('4')],
        "hasPreview": false,
        "borderImage": null, // Url or null
        "borderImageSize": 130, // Number from 0 and can go above 100
        "centerImage": null, // Url or null
        "perpendicularText": false // boolean
    }
};
const wheelOfPrizesPreview = () => {
    return {
        "id": genUUID(),
        "type": "wheelOfPrizesPreview",
        "wheelOfPrizeId": null,
    }
};
const generate11Players = () => {
    let o = []
    for (let index = 0; index < 11; index++) {
        o.push(soccerLineUpPlayer(`Player ${index + 1}`));
    }
    return o
}
const scratchPrize = (text, image) => {
    return {
        "id": genUUID(),
        "text": text ? text : 'New Prize',
        "image": image ? image : placeholderImage,
        "description": '',
        "probability": null,
        "stock": null,
        "strategy": null,
    }
}
const scratch = () => {
    return {
        "id": genUUID(),
        "type": "scratch",
        "participationProbability": 500000,
        "marginTop": "10px",
        "textColor": null,
        "backgroundColor": null,
        "buttonTextColor": null,
        "buttonBackgroundColor": null,
        "finishPercent": 50,
        "brushSize": 30,
        "scratchText": "<h1>Scratch to win</h1>",
        "loaderMessage": "<h1>Get ready to scratch...</h1>",
        "winMessage": "<h1>Congratulations you won</h1>",
        "lostMessage": "<h2>Hey, you were unlucky this time.</h2><h3>Thank you for your participation.</h3>",
        "prizes": [scratchPrize('1'), scratchPrize('2')],
        "coverImage": null, // Url or null
    }
};
const soccerLineUpSelector = () => {
    return {
        "id": genUUID(),
        "type": "soccerLineupSelector",
        "formations": [{
            "type": "4-4-2",
            "ext": [1, 3],
            "fwd": [0, 4],
            "mid": [1, 3],
            "def": [0, 1, 3, 4],
            "goal": [2],
        }],
        "marginTop": "10px",
        "shirtColor": '#000',
        "shirtImage": null,
        "gkShirtColor": '#000',
        "gkShirtImage": null,
        "players": generate11Players(),
        "categories": [soccerLineUpCategory('GK'), soccerLineUpCategory('DEF'), soccerLineUpCategory('MID'), soccerLineUpCategory('FWD')]
    }
}
const slideShow = () => {
    return {
        "id": genUUID(),
        "type": "slideShow",
        "marginTop": "10px",
        "textColor": null,
        "background": null,
        "categories": [slideShowCategory()],
        "slidesPerView": 2,
        "height": '150px'
    }
};
const ugc = () => {
    return {
        "id": genUUID(),
        "type": "ugc",
        "marginTop": "10px",
        "textColor":null,
        "allowImage":true,
        "useFrame":false,
        "frames":[],
        "stickers":[],
        "allowVideos":false,
        "allowFirstName":true,
        "allowLastName":false,
        "allowCaption":true,
        "autoAcceptPosts":false,
        "fields":[],
        "appLayout":"wall",
        "postButtonText":"Post",
        "captionTitle":null,
        "firstNameTitle":null,
        "lastNameTitle":null,
    }
};
export const getDefaultElement = (type, data) => {
    switch (type) {
        case "spacer":
            return spacer()
        case "freeText":
            return freeText()
        case "submitButton":
            return submitButton()
        case "text":
            return text(data)
        case "link":
            return link()
        case "iframe":
            return iframe()
        case "image":
            return image()
        case "numericPrediction":
            return numericPrediction()
        case "registrationForm":
            return registrationForm()
        case "background":
            return background()
        case "tint":
            return tint()
        case "font":
            return font()
        case "poll":
            return poll()
        case "imageChoice":
            return imageChoice()
        case "categorizedPoll":
            return categorizedPoll()
        case "wheelOfPrizes":
            return wheelOfPrizes()
        case "wheelOfPrizesPreview":
            return wheelOfPrizesPreview()
        case "wheelSlice":
            return wheelSlice()
        case "wheelPrize":
            return wheelPrize(data)
        case "soccerLineupSelector":
            return soccerLineUpSelector()
        case "soccerLineUpCategory":
            return soccerLineUpCategory()
        case "soccerLineUpPlayer":
            return soccerLineUpPlayer()
        case "slideShow":
            return slideShow()
        case "slideShowCategory":
            return slideShowCategory()
        case "slide":
            return slide()
        case "mvp":
            return mvp()
        case "mvpOption":
            return mvpOption()
        case "spread":
            return wheelSpreadStrategy()
        case "active":
            return wheelActiveStrategy()
        case "reserve":
            return wheelReserveStrategy()
        case "scratch":
            return scratch()
        case "scratchPrize":
            return scratchPrize()
        case "ugc":
            return ugc()
        default:
            break;
    }
};
export const pageTypes = {
    "welcome": ()=>{
        return [
            getDefaultElement('text', {
                text: "<p style='text-align:center;'>Welcome</p>",
            }),
            getDefaultElement('submitButton')
        ];
    },
    "numericPrediction": ()=>{
        return [
            getDefaultElement('text', {
                text: "<p style='text-align:center;'>Predict the score!</p>",
            }),
            getDefaultElement('numericPrediction'),
            getDefaultElement('submitButton')
        ];
    },
    "registrationForm": ()=>{
        return [
            getDefaultElement('registrationForm'),
        ];
    },
    "endScreen": ()=>{
        return [
            getDefaultElement('text', {
                text: "<p style='text-align:center;'>Thank you for participating!</p>",
            }),
        ];
    },
    "expired": ()=>{
        return [
            getDefaultElement('text', {
                text: "<p style='text-align:center;'>The campaign has ended.</p>",
            }),
        ];
    },
    "wheelOfPrizes": ()=>{
        return [
            getDefaultElement('wheelOfPrizes'),
        ];
    },
    "wheelOfPrizesPreview": ()=>{
        return [
            getDefaultElement('wheelOfPrizesPreview'),
        ];
    },
    "imageChoice": ()=>{
        return [
            getDefaultElement('imageChoice'),
            getDefaultElement('submitButton')
        ];
    },
    "poll": ()=>{
        return [
            getDefaultElement('poll'),
            getDefaultElement('submitButton')
        ];
    },
    "mvp": ()=>{
        return [
            getDefaultElement('mvp'),
            getDefaultElement('submitButton')
        ];
    },
    "soccerLineupSelector": ()=>{
        return [
            getDefaultElement('soccerLineupSelector'),
            getDefaultElement('submitButton')
        ];
    },
    "scratch": ()=>{
        return [
            getDefaultElement('scratch'),
        ];
    },
    "ugc": ()=>{
        return [
            getDefaultElement('image'),
            getDefaultElement('ugc'),
        ];
    },
}
export const PAGE = (type) => {
    let tmp = {
        "id": genUUID(),
        "elements": [],
    }
    if(pageTypes[type]) tmp['elements'] = pageTypes[type]();
    else {
        tmp['elements'] = [
            getDefaultElement('text', {
                text: "<p style='text-align:center;'>This is a page.</p>",
            }),
            getDefaultElement('submitButton')
        ];
    }
    return tmp;
};
export const campaignTypes = {
    'numericPrediction': () => {
        return [PAGE('numericPrediction'), PAGE('registrationForm'), PAGE('endScreen')];
    },
    'registrationForm': () => {
        return [PAGE('registrationForm'), PAGE('endScreen')];
    },
    'imageChoice': () => {
        return [PAGE('imageChoice'), PAGE('endScreen')];
    },
    'poll': () => {
        return [PAGE('poll'), PAGE('endScreen')];
    },
    'mvp': () => {
        return [PAGE('mvp'), PAGE('endScreen')];
    },
    'soccerLineupSelector': () => {
        return [PAGE('soccerLineupSelector'), PAGE('endScreen')];
    },
    'wheelOfPrizes': () => {
        let w = PAGE('wheelOfPrizes');
        delete w['elements'][0]['hasPreview'];
        return [w, PAGE('endScreen')];
    },
    'wheelOfPrizesPreview': () => {
        const wf = PAGE('wheelOfPrizes');
        delete wf['elements'][0]['hasPreview'];
        const wfPreview = PAGE('wheelOfPrizesPreview');
        wfPreview['elements'][0]['wheelOfPrizeId'] = wf['elements'][0]['id'];
        return [wfPreview, PAGE('registrationForm'), wf, PAGE('endScreen')]
    },
    'scratch': () => {
        return [PAGE('scratch'), PAGE('endScreen')];
    },
    'ugc': () => {
        return [PAGE('ugc')];
    },
    'custom': () => {
        return [PAGE('welcome'), PAGE('numericPrediction'), PAGE('registrationForm'), PAGE('endScreen')];
    },
};
export const CAMPAIGNDATA = (type) => {
    const pages = campaignTypes[type]?campaignTypes[type]():[];
    return {
        "version": CURRENT_VERSION,
        "pages": pages,
        "background": background(),
        "mobileBackground": background(),
        "tint": tint(),
        "font": font(),
        "primaryTextColor": null,
        "secondaryTextColor": null,
        "primaryColor": null,
        "secondaryColor": null,
        "expiredPage": PAGE('expired'),
        "showShare": false,
        "multiVoting": false,
        "type": type
    }
}
export const CAMPAIGNMETA = () => {
    return {
        "name": null,
        "share": {
            "title": null,
            "description": null,
            "image": null
        },
        "endDate": null,
    }
}
