import { useState } from "react";
import EditableInput from "../../../utils/EditableInput";
import { genUUID, Notification } from "../../../utils/Misc";
import { updateElement } from "../../../utils/UpdateFunctions";
import { editElement } from "../../../../actions/UserActions";
import ImageUpload from "../../../utils/ImageUpload";
import { SortableHandle } from 'react-sortable-hoc';
import '../../../../scss/pages/Poll.scss'
import { useDispatch } from "react-redux";

function MvpElementOptionsOption({ currentCampaignId, currentPage, currentElement, elementCopy, option, setElementCopy }) {
	const dispatch = useDispatch();
	const [newAttributeValue, setNewAttributeValue] = useState('')
	const [newAttributeLabel, setNewAttributeLabel] = useState('')
	/* const [advanced, showAdvanced]= useState(false); */
	const {options} = elementCopy;
	const updateOption = (key, value, extra) => {
		for (let i = 0; i < options.length; i++) {
			if (options[i]['id'] == extra.option_id) {
				elementCopy.options[i][key] = value;
			}
		}
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const deleteOption = option_id => {
		if (elementCopy.options.length == 1) {
			Notification('There should be at least one option in the image choice')
			return
		}
		const o = elementCopy.options.filter(current => current.id !== option_id)
		elementCopy.options = o;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const duplicateOption = option => {
		const newOption = { ...option };
		newOption.id = genUUID()
		newOption.image = null;
		elementCopy.options.push(newOption);
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const addAttribute = (option_id) => {
		const option = elementCopy.options.find((option) => option.id === option_id)
		if (option) {
			option.attributes.push({
				"k": newAttributeLabel,
				"v": newAttributeValue
			})
			updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
			.then(() => {
				setNewAttributeLabel('')
				setNewAttributeValue('')
			})
		}
	};
	const updateOptionAttribute = (key, value, extra) => {
		const {idx} = extra;
		const option_id = option.id;
		elementCopy.options.forEach((option) => {
			if (option.id === option_id) {
				option.attributes[idx][key] = value
			}
			return option
		})
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
		.then(() => {
			console.log('attribute edited')
		})
	}
	const removeAttribute = (attributeIdx) => {
		const option_id = option.id;
		elementCopy.options.forEach((option) =>{
			if(option.id === option_id){
				option.attributes = option.attributes.filter((attribute, idx) => idx !== attributeIdx);
			}
			return option
		})
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
		.then(() => {
			console.log('attribute removed')
		})

	}
	const DragHandle = SortableHandle(() => <span className="material-icons font--25 item pointer mtop--10">drag_indicator</span>);
	return (
		<div className="poll__option">
			<div className="poll__option-actions">
				<div className="poll__option-action">
					<DragHandle />
				</div>
				<div className="poll__option-action" onClick={() => duplicateOption(option)}>
					<span className="material-icons">
						content_copy
					</span>
				</div>
				<div className="poll__option-action">
					{option?.showText ?
						<span className="material-icons" onClick={() => updateOption('showText', false, { option_id: option.id })}>
							visibility
						</span>
						:
						<span className="material-icons text--warning" onClick={() => updateOption('showText', true, { option_id: option.id })}>
							visibility_off
						</span>
					}
				</div>
				<div className="poll__option-action">
					<span className="material-icons text--danger poll__option-delete" onClick={() => deleteOption(option.id)}>
						delete
					</span>
				</div>
			</div>
			<div className="poll__option-img">
				<ImageUpload dimensions={'W:170px x H:250px'} extra={{ option_id: option.id }} campaign_id={currentCampaignId} file={option.image} item="image" updateAction={updateOption} />
			</div>
			<div className="poll__option-text poll__option-content">
				<div disabled={!option.showText}>
					<EditableInput extra={{ option_id: option.id }} item="text" type="text" startValue={option.text} updateAction={updateOption} placeholder={'Type here...'} />
				</div>
				<div className="mleft--10 mtop--10">
					<div>
						{/* <button className="btn btn-primary-soft btn-rounded btn-sm">
								Height: 1.70
							</button> */}
					</div>
					<div className="mtop--20">
						<label>Stats</label>
						<div className="mtop--20">
							{option.attributes.map((attribute, idx) => {
								return(
									<div className="mtop--10 poll__option-attr" key={`attribute__${idx}`}>
										<div className="poll__option-attrInner">
											<EditableInput extra={{ option_id: option.id, idx: idx }} item="k" type="text" startValue={attribute['k']} updateAction={updateOptionAttribute} placeholder={'Type here...'} />
										</div>
										<span className="text--muted">|</span>
										<div className="poll__option-attrInner">
											<EditableInput extra={{ option_id: option.id, idx: idx }} item="v" type="text" startValue={attribute['v']} updateAction={updateOptionAttribute} placeholder={'Type here...'} />
										</div>
										<span className="material-icons text--danger pointer" onClick={() => removeAttribute(idx)}>clear</span>
									</div>
								)
							})}
							<div className="mtop--10 poll__option-attr poll__option-attrAdd">
								<div className="poll__option-attrInner">
									<input value={newAttributeLabel} onChange={(e) => setNewAttributeLabel(e.target.value)} type="text" placeholder="E.g 12" className="mright--10" />
								</div>
								<span className="text--muted">|</span>
								<div className="poll__option-attrInner">
									<input value={newAttributeValue} onChange={(e) => setNewAttributeValue(e.target.value)} type="text" placeholder="E.g Assists" className="mleft--10" />
								</div>
								<span className="material-icons text--primary pointer" disabled={!newAttributeLabel || !newAttributeValue} onClick={() => addAttribute(option.id)}>send</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MvpElementOptionsOption;
