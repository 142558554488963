import { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';

function ScratchDateTimePicker({ itemKey, initialValue, update, extra }) {
	const [value, setValue] = useState(initialValue);
	const setDate = (newValue) => {
		setValue(newValue);
		update(itemKey, getTimestamp(newValue), extra)
	}
	function getTimestamp(dateString) {
		const date = moment(dateString, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").toDate();
		const timestamp = date.getTime();
		return timestamp;
	}
	return (
		<div className="datepicker__sm">
			<DateTimePicker onChange={setDate} value={value && new Date(value)} />
		</div>
	);
}

export default ScratchDateTimePicker;
