import React, { useState, useEffect, useRef } from 'react';
import {uploadImage} from '../../firebaseActions.mjs';
import {genUUID} from './Misc';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
    convertToPixelCrop,
} from 'react-image-crop';
import CropperModal from './CropperModal';
import 'react-circular-progressbar/dist/styles.css';
import 'react-image-crop/src/ReactCrop.scss'

const getImageDimensions = ( url ) => {
    return new Promise( (resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
            // Natural size is the actual image size regardless of rendering.
            // The 'normal' `width`/`height` are for the **rendered** size.
            const width  = img.naturalWidth;
            const height = img.naturalHeight; 
    
            resolve({width, height});
        };
    
        img.onerror = reject;
    })
}

function ImageUpload(props) {
    const {file, campaign_id, item, updateAction, extra, dimensions, minWidth, minHeight, maxWidth, maxHeight, aspectRatio, hasCrop} = props;
	const [preview, setPreview] = useState(file);
    const inputRef = useRef(null);
    const [uploading, setUploading] = useState(null);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [isCropping, setIsCropping] = useState(false)
    const [suffix, setSuffix] = useState()
    const [fileData, setFileData] = useState()
    const dataURL = useRef()
	const onInputChange = async e => {
		const loadedFile = e.target.files[0]
        const currentSuffix = loadedFile.type.split('/')[1]
        setSuffix(currentSuffix)
		dataURL.current = URL.createObjectURL(loadedFile)
        
        if(hasCrop){
            const {width, height} = await getImageDimensions(dataURL.current)
            console.log("dataURL.current", dataURL.current)
            setFileData({
                suffix: currentSuffix,
                dataURL: dataURL.current,
                width: width,
                height: height,
                aspectRatio: aspectRatio
            })
            setIsCropping(true)
        }
        else{
            setPreview(dataURL.current)
            startUploadImage(loadedFile)
        }
    }
    const startUploadImage = (imageFile) => {
        try {
            if(dataURL.current) URL.revokeObjectURL(dataURL.current)
        } catch (error) {
            console.log(error)
        }
        
        setUploading('uploading');

        uploadImage(
            campaign_id,
            genUUID()+'.'+suffix,
            imageFile,
            (state) => {
                setUploadPercentage(Math.round(100*state.bytesTransferred/state.totalBytes).toFixed(0))
            },
            (fullPath) => {
                setUploading('uploaded');
                setTimeout(() => { setUploading(null) }, 1500)
                updateAction(item, fullPath, extra)
                if(inputRef.current){
                    inputRef.current.value = null;
                }
            },
            (error) => {
                console.log(error);
                setPreview(null);
                if(inputRef.current){
                    inputRef.current.value = null;
                }
                setUploading('error');
                setTimeout(() => { setUploading(null) }, 1500)
            },
        )
    }
    const clear = (e) => {
        e.stopPropagation();
        setPreview(null);
        updateAction(item, null, extra)
    }
    const submitCrop = (croppedImageFile) => {
        try {
            if(dataURL.current) URL.revokeObjectURL(dataURL.current)
        } catch (error) {
            console.log(error)
        }
        setIsCropping(false)
        startUploadImage(croppedImageFile)
    }
    const stopCropping = () => {
		try {
            if(dataURL.current) URL.revokeObjectURL(dataURL.current)
        } catch (error) {
            console.log(error)
        }
        if(inputRef.current){
            inputRef.current.value = null;
        }
        setIsCropping(false)
    }
    useEffect(() => {
        setPreview(props.file);
	}, [props])
	return (
		<>
			<div className="image__upload-placeholder">
                { !isCropping &&
                    <input type="file" ref={inputRef} onChange={ e => onInputChange(e)} accept="image/jpg, image/png, image/jpeg" />
                }
                { preview &&
                    <>
                        <div className="animate__animated animate__fadeIn image__upload-img">
                            { !uploading &&
                                <>
                                    { preview &&
                                        <img className="img__responsive" src={preview} alt="Preview" />
                                    }
                                    { !preview &&
                                        <div>
                                            <span className="material-icons">
                                                image
                                            </span>
                                            <div className="mtop--10 text--muted font--13">Click to change</div>
                                        </div>
                                    }
                                </>
                            }
                            { uploading === 'uploading' &&
                                <div style={{ width: 50, height: 50 }}>
                                    <CircularProgressbar styles={buildStyles({
                                        pathColor: `rgba(100,92,170, ${uploadPercentage / 100})`,
                                        textColor: '#645CAA',
                                        trailColor: '#645CAA',
                                    })} value={uploadPercentage} text={`${uploadPercentage}%`} />
                                </div>
                            }
                            { uploading === 'uploaded' &&
                                <div className="text--center">
                                    <span className="material-icons font--1em text--success">
                                        task_alt
                                    </span>
                                    <h4>Uploaded</h4>
                                </div>
                            }
                            { uploading === 'error' &&
                                <div className="text--center">
                                    <span className="material-icons font--1em text--danger">
                                        report_problem
                                    </span>
                                    <h4>Upload Error</h4>
                                    <div className="font--12">Please try again later.</div>
                                </div>
                            }
                            { uploading === 'maxFileSizeError' &&
                                <div className="text--center">
                                    <span className="material-icons font--1em text--danger">
                                        report_problem
                                    </span>
                                    <h4>Max file size exceeded</h4>
                                    <div className="font--12">Please try again with a smaller file.</div>
                                </div>
                            }
                            { uploading === 'imageDimensionError' &&
                                <div className="text--center">
                                    <span className="material-icons font--1em text--danger">
                                        report_problem
                                    </span>
                                    <h4>Invalid image dimensions</h4>
                                    <div className="font--12">Please try again with proper dimensions.</div>
                                </div>
                            }
                        </div>
                        {(!uploading && (preview !== '/images/common/placeholder.png')) && 
                            <span className="image__delete material-icons" onClick={(e) => {
                                e.stopPropagation();
                                clear(e)
                            }}>
                                clear
                            </span>
                        }
                        
                        
                    </>
                }
                { !preview && !isCropping &&
                    <div>
                        <i className="fi fi-rr-upload font--25"></i><br />
                        <span className="font--12">Upload</span>
                        <div className="mtop--10 text--muted font--13">.jpg, .png files allowed</div>
                    </div>
                }
                { !isCropping &&
                    <div className="image__upload-info">
                        {dimensions && dimensions+' • '}
                        Max-size: 5Mb
                    </div>
                }
            </div>
            {isCropping && <CropperModal closeCb={stopCropping} confirmCb={submitCrop} fileData={fileData} />}
		</>
	)
}

export default ImageUpload