
const initialState = {
    incomingRequests: [],
    outgoingRequests: [],
    activeRoles: [],
    currentAccountGrants: {
        'managers': [],
        'administrators': []
    },
}
export default function RolesReducer(state = initialState, action) {
    switch (action.type) {
        case 'ROLES_ADD_INCOMING_REQUEST':
            return {
                ...state,
                incomingRequests: [...state.incomingRequests, ...[action.data]]
            }
        case 'ROLES_EDIT_INCOMING_REQUEST':
            return {
                ...state,
                incomingRequests: state.incomingRequests.map((request) => {
                    if (request.id == action.data.id) {
                        return action.data
                    }else{
                        return request
                    }
                })
            }
        case 'ROLES_CLEAR_INCOMING_REQUESTS':
            return {
                ...state,
                incomingRequests: []
            }
        case 'ROLES_ADD_OUTGOING_REQUEST':
            return {
                ...state,
                outgoingRequests: [...state.outgoingRequests, ...[action.data]]
            }
        case 'ROLES_EDIT_OUTGOING_REQUEST':
            return {
                ...state,
                outgoingRequests: state.outgoingRequests.map((request) => {
                    if (request.id == action.data.id) {
                        return action.data
                    } else {
                        return request
                    }
                })
            }
        case 'ROLES_CLEAR_OUTGOING_REQUESTS':
            return {
                ...state,
                outgoingRequests: []
            }
        case 'ROLES_SET_CURRENT_ACCOUNT_GRANTS':
            return {
                ...state,
                currentAccountGrants: action.data
            }
        case 'ROLES_ADD_ACTIVE':
            return {
                ...state,
                activeRoles: [...state.activeRoles, ...[action.data]]
            }
        case 'ROLES_REMOVE_ACTIVE':
            return {
                ...state,
                activeRoles: state.activeRoles.filter((role) => role.id != action.data)
            }
        default:
            return state;
    }
}