import { useEffect, useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import { saveAs } from "file-saver";
import { jsPDF } from 'jspdf';

function RegistrationPdfResult({ index, calculatedResults, tableHeader }) {
	const [data, setData] = useState(null);
	const [modal, setModal] = useState(null);
	const printRef = useRef();
	const filename = data?.find((row) => row.type === 'registration_email');

	const download = async () => {
		const divElement = printRef.current;
		const canvas = await html2canvas(divElement, {
			backgroundColor: null,
			useCORS: true // Enable CORS to allow screenshot of external images
		});
		const imgData = canvas.toDataURL('image/png');
		console.log(imgData);

		const pdf = new jsPDF();
		const pdfWidth = pdf.internal.pageSize.getWidth();
		const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

		pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
		pdf.save(`${filename ? `${filename.value}_details` : 'RegistrationDetails'}.pdf`);
	}
	useEffect(() => {
		if (!printRef.current) return;
		const loadImage = async () => {
			const imgElement = printRef.current.querySelector('img');
			const imageUrl = imgElement?.src;
			const response = await fetch(imageUrl);
			const blob = await response.blob();
			const objectUrl = URL.createObjectURL(blob);
			if(!imgElement) return;
			imgElement.src = objectUrl;
		};
		loadImage();
	}, [modal]);
	useEffect(() => {
		const d = calculatedResults.map((row, idx) => {
			return {
				key: tableHeader[idx]?.value,
				type: tableHeader[idx]?.type,
				value: row,
			}
		})
		setData(d);
	}, [])
	return (
		<>
			{data &&
				<>
					<button onClick={() => setModal(true)} className="btn btn-transparent material-icons font--20 pointer">download</button>
					{modal &&
						<div className="modal-overlay active">
							<div className="modal active animate__animated animate__fadeIn">
								<div className="modal__header">
								</div>
								<div className="modal-content">
									<div className="modal__body" ref={printRef} id="my-container">
										<div className="modal__header-title text--center">
											<h2>~ Registration Details ~</h2>
										</div>
										<div className="mtop--20">
											{data?.map((row, idx) => {
												return (
													<div key={`registration__info_${idx}`}>
														<label className="text--bold">{row.key}</label>
														<div>
															{
																row.type === "registration_signature" ?
																	<img src={row.value} className="img__responsive" alt="Signature"/>
																	:
																	<div>{row.value}</div>
															}
														</div>
													</div>
												)
											})}
										</div>
									</div>
									<div className="modal__footer text--right">
										<button className="btn btn-light" onClick={() => setModal(null)}>Close</button>
										<button className="btn btn-primary" onClick={download}>Download</button>
									</div>
								</div>
							</div>
						</div>
					}
				</>
			}
		</>
	);
}

export default RegistrationPdfResult;
