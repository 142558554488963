import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDefaultElement, PAGE } from "./CampaignAddDefaults";
import { createPageElement, replaceCampaignPage, addCampaignPage } from '../../../firebaseActions.mjs';
import { addElementStore, reorderPageElementsStore, setCampaignPages } from "../../../actions/UserActions";
import { ErrorNotification } from "../../utils/Misc";
import { getPageElements, getTypesAndElements } from "../../utils/PageElements";
import AddNotificationModal from './CampaignAddElementNotificationModal';
import CampaignAddWizardModal from './CampaignAddWizardModal';


function CampaignAddElements() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [addNotificationModal, setAddNotificationModal] = useState(null);
	const [wizardModal, setWizardModal] = useState(false);
	const { currentCampaign, currentPage, currentCampaignId } = useSelector(state => state.user);
	const currentPageCopy = JSON.parse(JSON.stringify(currentPage));
	const [typeElements, decorativeElements] = getTypesAndElements(null, currentCampaign?.type, false);
	const pageElements = [...typeElements, ...decorativeElements];

	const getConflictingElements = (conflictingElements, returnSame) => {
		let o = []
		currentPage.elements.forEach(element => {
			if (conflictingElements.includes(element.type)) {
				if (returnSame) {
					o.push(element.type)
				} else {
					o.push(getPageElements(element.type))
				}
			}
		})
		return o;
	}
	const removeAnyConflictingElements = conflictingElements => {
		return currentPage.elements.filter(el => {
			return !conflictingElements.includes(el.type)
		});
	}
	const addElement = element => {
		setLoading(element.type);
		if (element.conflictingElementTypes.length > 0) {
			const conflictingElements = getConflictingElements(element.conflictingElementTypes);
			if (conflictingElements.length > 0) {
				setAddNotificationModal({
					conflictingElements: conflictingElements,
					element: element
				});
				return;
			}
		}
		checkForWizard(element);
	}
	const checkForWizard = (element) => {
		if (element.hasWizard) {
			setWizardModal(element);
			return;
		}
		addElementDB(element);
	}
	/* 
		@param: type -> The type of the new element
		@param: premadeElement -> You can create the default element from a wizard and pass it down immediately
	*/
	const addElementDB = async (selectedElement, premadeElement) => {
		setWizardModal(null);
		setAddNotificationModal(null);
		const newElement = premadeElement ? premadeElement : getDefaultElement(selectedElement.type);
		const hasPreview = newElement?.hasPreview;
		console.log(newElement);
		delete newElement['hasPreview'];

		const submitButton = getPageElements('submitButton');
		let updateAllPage = false;
		let conflictingElements = [];
		let orderLength = null;

		/* Have to check for conflicting elements or not */
		if (selectedElement.conflictingElementTypes.length > 0) {
			/* Has conflicting elements */
			conflictingElements = getConflictingElements(selectedElement.conflictingElementTypes, true);
		}
		if (conflictingElements.length > 0) {
			updateAllPage = true;
			currentPageCopy.elements = removeAnyConflictingElements(conflictingElements)

			currentPageCopy.elements.push(newElement);
			/* Remove the submit button and add a new one after this element */
			currentPageCopy.elements = currentPageCopy.elements.filter((element) => element.type !== 'submitButton');
			/* Add a new submit button on elements not conflicting with the submit button */
			if (!submitButton?.conflictingElementTypes.includes(selectedElement.type)){
				currentPageCopy.elements.push(getDefaultElement('submitButton'));
			}
			orderLength = currentPageCopy.elements.length;
		} else {
			currentPageCopy.elements.push(newElement);
			orderLength = currentPageCopy.elements.length-1;
		}

		const pageType = currentPageCopy.type === 'expiredPage' ? 'expiredPage' : 'pages';
		if (updateAllPage) {
			let pageCopy = JSON.parse(JSON.stringify(currentPageCopy))
			delete pageCopy.type;
			replaceCampaignPage(currentCampaignId, pageType, pageCopy.id, pageCopy)
				.then(async (data) => {
					dispatch(reorderPageElementsStore(currentPageCopy.elements));
					if (hasPreview) {
						await addPagesBeforeSecifiedElement(selectedElement, newElement.id);
					}
				})	
				.catch((e) => {
					ErrorNotification('The element could not be added.', e, {});
					console.error(e, { ...e });
				})
				.finally(() => setTimeout(() => { setLoading(null) }, 500));
		} else {
			createPageElement(currentCampaignId, pageType, currentPageCopy.id, newElement.id, orderLength, newElement)
				.then(async (data) => {
					dispatch(addElementStore(newElement));
					if (hasPreview) {
						await addPagesBeforeSecifiedElement(selectedElement, newElement.id);
					}
				})
				.catch((e) => {
					ErrorNotification('The element could not be added.', e, {});
					console.error(e, { ...e });
				})
				.finally(() => setTimeout(() => { setLoading(null) }, 500));
		}
	}
	function addPagesBeforeSecifiedElement(selectedElement, elementId){
		return new Promise( async (resolve, reject) => {
			let idxToAdd = currentCampaign.pages.indexOf(currentPage);
			let addedPages = 0;
			const allPagesElementTypes = currentCampaign.pages.reduce((acc, page) => {
				return acc.concat(
					page.elements.map((el) => {
						return el.type;
					})
				)
			}, [])
			selectedElement.extraPagesBefore = selectedElement.extraPagesBefore.filter((el) => !allPagesElementTypes.includes(el));
			console.log(selectedElement.extraPagesBefore);
			for (let idx = 0; idx < selectedElement.extraPagesBefore.length; idx++) {
				const element = selectedElement.extraPagesBefore[idx];
				const page = PAGE(element);
				if (element === 'wheelOfPrizesPreview'){
					page.elements[0]['wheelOfPrizeId'] = elementId;
				}
				const campaign = await addCampaignPage(currentCampaignId, 'pages', page.id, idxToAdd - idx, page);
				campaign.pages = campaign.pages.map((page, idx) => {
					const t = idx === campaign.pages.length - 1 ? 'endPage' : 'normal';
					return { ...page, ...{ type: t } }
				})
				dispatch(setCampaignPages(campaign.pages));
				idxToAdd++;
				addedPages++;
			}
			if(addedPages === selectedElement.extraPagesBefore.length){
				resolve()
			}else{
				reject();
			}
		})
	}
	useEffect(() => {
		if (!addNotificationModal) setLoading(null);
	}, [addNotificationModal])
	return (
		<>
			{pageElements.map((element, index) => {
				return (
					<div key={index}>
						{element.allowedPageTypes.includes(currentPage.type) &&
							<div className="campaign__edit-element" onClick={() => addElement(element)}>
								{loading === element.type && <span className="loader"></span>}
								{loading !== element.type &&
									<div>
										<span className="material-icons font--25">
											{element.icon}
										</span>
										<div>
											{element.text}
										</div>
										<div className="campaign__edit-elementOverlay">
											<span className="material-icons font--40 vm--align text--normal">
												add
											</span>
										</div>
									</div>
								}
							</div>
						}
					</div>
				)
			})}
			{addNotificationModal && <AddNotificationModal closeCb={setAddNotificationModal} clickAction={checkForWizard} data={addNotificationModal} />}
			{wizardModal && <CampaignAddWizardModal closeCb={setWizardModal} clickAction={addElementDB} data={wizardModal} />}
		</>
	);
}

export default CampaignAddElements;
