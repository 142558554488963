import React, {useMemo, useState, useEffect} from 'react'
import { Swiper } from 'swiper/react';
import 'swiper/css';
import { Tooltip } from "react-tippy";

function HorizontalSlider({children, items, css, itemWidth}) {
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
	
	const itemsOnScreen = useMemo(() => {
		return Math.round(pageWidth / itemWidth)
	}, [items, pageWidth])

	const showArrow = useMemo(() => {
		return itemsOnScreen < items.length
	}, [itemsOnScreen, items])
    
	const swiperOptions = useMemo(() => {
		return {
			direction: 'horizontal',
			grabCursor: true,
			slidesPerView: itemsOnScreen,
            className: css
		}
	}, [itemsOnScreen]);
    useEffect(() => {
		const handleResize = () => {
			setPageWidth(window.innerWidth);
		};
		
		window.addEventListener('resize', handleResize);
		
		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
    return (
        <div className="horizontalslider">
            <Swiper {...swiperOptions}>
                {children}
            </Swiper>
            { showArrow &&
                <Tooltip title="Slide left and right" position="bottom">
                    <span className="material-icons arrow__move">
                        keyboard_arrow_right
                    </span>
                </Tooltip>
            }
        </div>
    )
}

export default HorizontalSlider