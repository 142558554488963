function RegistrationSsnElementOptions({ field, updateField }) {
	const allowedIsChecked = (allowed, allowedLengths) => {
		return allowedLengths.includes(allowed);
	}
	const setAllowed = (allowed, field) => {
		if (field.allowedLengths.includes(allowed)) {
			if (field.allowedLengths.length < 2) return;
			field.allowedLengths = field.allowedLengths.filter((allowedLength) => allowedLength !== allowed);
		} else {
			field.allowedLengths.push(allowed);
		}
		updateField('allowedLengths', field.allowedLengths, { field_id: field.id })
	}
	return (
		<div className="mtop--10">
			<div className="form__group-label">
				<label className="text--bold font--12">Allowed Lengths</label>
				<div className="text--muted font--12">Some countries have different lengths for ssn. For example 10 or 12 characters.</div>
			</div>
			<div className="flex flex--row">
				<div className="btn btn-secondary btn-md">
					{[8, 9, 10, 11, 12, 13, 14].map((allowed, idx) => {
						return (
							<div key={`allowed_${idx}`} onClick={() => setAllowed(allowed, field)} className={`btn ${allowedIsChecked(allowed, field.allowedLengths) ? 'btn-primary' : 'btn-secondary'}`}>
								{allowed}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default RegistrationSsnElementOptions