import { useState, useCallback, useEffect } from 'react';

import "./scss/UGC.scss";

import aspectImg from "./aspect-16-9.png";

import Modal from 'react-modal';
import FormFieldInput from './FormFieldInput.jsx';

import { ColumnsPhotoAlbum, MasonryPhotoAlbum } from "react-photo-album";
// import "react-photo-album/masonry.css";
import "react-photo-album/columns.css";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import UGCFieldInput from './UGCFieldInput';
import MiniLoader from '../common/MiniLoader.jsx';

const images = [
	{
		src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
		width: 320,
		height: 174,
		tags: [
			{ value: "Nature", title: "Nature" },
			{ value: "Flora", title: "Flora" },
		],
		firstName: "Elijah",
		lastName: "Glass",
		caption: "After Rain (Jeshu John - designerspics.com)",
	},
	{
		src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
		width: 320,
		height: 212,
		firstName: "Silas",
		lastName: "Dennis",
		caption: "Boats (Jeshu John - designerspics.com)",
	},
	{
		src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
		width: 320,
		height: 212,
		firstName: "Alfred",
		lastName: "Forbes",
		caption: "Color Pencils (Jeshu John - designerspics.com)",
	},
	{
		src: "https://c7.staticflickr.com/9/8546/28354329294_bb45ba31fa_b.jpg",
		width: 320,
		height: 213,
		firstName: "Monserrat",
		lastName: "Aguilar",
		caption: "Red Apples with other Red Fruit (foodiesfeed.com)",
	},
	{
		src: "https://c6.staticflickr.com/9/8890/28897154101_a8f55be225_b.jpg",
		width: 320,
		height: 183,
		firstName: "Keshawn",
		lastName: "Sloan",
		caption: "37H (gratispgraphy.com)",
	},
	{
		src: "https://c5.staticflickr.com/9/8768/28941110956_b05ab588c1_b.jpg",
		width: 240,
		height: 320,
		tags: [{ value: "Nature", title: "Nature" }],
		firstName: "Mireya",
		lastName: "Morrison",
		caption: "8H (gratisography.com)",
	},
	{
		src: "https://c3.staticflickr.com/9/8583/28354353794_9f2d08d8c0_b.jpg",
		width: 320,
		height: 190,
		firstName: "Taliyah",
		lastName: "Palmer",
		caption: "286H (gratisography.com)",
	},
	{
		src: "https://c7.staticflickr.com/9/8569/28941134686_d57273d933_b.jpg",
		width: 320,
		height: 148,
		tags: [{ value: "People", title: "People" }],
		firstName: "Hana",
		lastName: "Carlson",
		caption: "315H (gratisography.com)",
	},
	{
		src: "https://c6.staticflickr.com/9/8342/28897193381_800db6419e_b.jpg",
		width: 320,
		height: 213,
		firstName: "Kody",
		lastName: "Walsh",
		caption: "201H (gratisography.com)",
	},
	{
		src: "https://c2.staticflickr.com/9/8239/28897202241_1497bec71a_b.jpg",
		alt: "Big Ben - London",
		width: 248,
		height: 320,
		firstName: "Joselyn",
		lastName: "Arnold",
		caption: "Big Ben (Tom Eversley - isorepublic.com)",
	},
	{
		src: "https://c7.staticflickr.com/9/8785/28687743710_3580fcb5f0_b.jpg",
		alt: "Red Zone - Paris",
		width: 320,
		height: 113,
		tags: [{ value: "People", title: "People" }],
		firstName: "Kaleb",
		lastName: "Hoffman",
		caption: "Red Zone - Paris (Tom Eversley - isorepublic.com)",
	},
	{
		src: "https://c6.staticflickr.com/9/8520/28357073053_cafcb3da6f_b.jpg",
		alt: "Wood Glass",
		width: 313,
		height: 320,
		firstName: "Aiyana",
		lastName: "Johnson",
		caption: "Wood Glass (Tom Eversley - isorepublic.com)",
	},
	{
		src: "https://c8.staticflickr.com/9/8104/28973555735_ae7c208970_b.jpg",
		width: 320,
		height: 213,
		firstName: "Kobe",
		lastName: "Villa",
		caption: "Flower Interior Macro (Tom Eversley - isorepublic.com)",
	},
	{
		src: "https://c4.staticflickr.com/9/8562/28897228731_ff4447ef5f_b.jpg",
		width: 320,
		height: 194,
		firstName: "Braedon",
		lastName: "Mcgee",
		caption: "Old Barn (Tom Eversley - isorepublic.com)",
	},
	{
		src: "https://c2.staticflickr.com/8/7577/28973580825_d8f541ba3f_b.jpg",
		alt: "Cosmos Flower",
		width: 320,
		height: 213,
		firstName: "Bennett",
		lastName: "Michael",
		caption: "Cosmos Flower Macro (Tom Eversley - isorepublic.com)",
	},
]

const getDemoImages = ({ allowImage, allowCaption, allowFirstName, allowLastName}) => {
	return images.map(image => {

		return {
			data: {
				id: Math.random(),
				image: allowImage? [{
					...image,
					width: image.width * 10,
					height: image.height * 10,
				}]: null,
				caption: allowCaption? image.caption: null,
				firstName: allowFirstName? image.firstName: null,
				lastName: allowLastName? image.lastName: null,
				
			}
		}
	});
}

const modalStyles = {
	overlay: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	content: {
		position: 'relative',
		width: '100%',
		maxWidth: 600,
		inset: 0,
		padding: 40,
		background: 'none',
		border: 'none',
	},
};

const readAsDataURL = (file) =>
	new Promise(resolve => {
		const reader = new FileReader();
		// Read in the image file as a data URL.
		reader.readAsDataURL(file);
		reader.onload = function (evt) {
			if (evt.target.readyState === FileReader.DONE) {
				resolve(evt.target.result)
			}
		}
	})

const getImageDimensions = (src) =>
	new Promise(resolve => {
		const image = new Image()
		image.onload = () => resolve({ image, width: image.naturalWidth, height: image.naturalHeight })
		image.src = src
	})

const getResizedImageBlob = async (image, width, height, maxDim) => {

	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext("2d");
	
	if(width > height && width > maxDim){
		canvas.width = maxDim;
		canvas.height = Math.round(maxDim * height/width);
	}
	else if(height > width && height > maxDim){
		canvas.height = maxDim;
		canvas.width = Math.round(maxDim * width/height);
	}
	else{
		canvas.height = height;
		canvas.width = width;
	}

	ctx.fillStyle = "white";
	ctx.fillRect(0, 0, canvas.width, canvas.height);
	ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
	
	const outBlob = await new Promise( resolve => {
		canvas.toBlob(function(blob) {
			resolve(blob)
		}, "image/jpeg", 0.9);
	})

	return {
		blob: outBlob,
		width: canvas.width,
		height: canvas.height
	}
}


function UGC({ campaignId, data, value, onChange, onSubmit, fetchPosts, uploadTemporaryFile, genUUID, isDemo }) {
	const { marginTop, allowCaption, allowFirstName, allowLastName,allowImage, allowVideos, frames, stickers } = data;
	const [userPosts, setUserPosts] = useState([])
	const [isPostModal, setIsPostModal] = useState(false)
	const [postError, setPostError] = useState("")
	const [fileInputKey, setFileInputKey] = useState(0)
	const [isUploading, setIsUploading] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [uploadedImageSrc, setUploadedImageSrc] = useState()
	const [uploadProgress, setUploadProgress] = useState(0)
	const [index, setIndex] = useState(-1);

	// console.log("ugc data, value", data, value)

	const loadPosts = (e) => {
		fetchPosts(campaignId, data.id)
		.then(res => {
			console.log("posts", res)
			setUserPosts(res)
		})
	}
	useEffect(() => {
		resetData()
		if(isDemo) setUserPosts(getDemoImages({allowImage, allowCaption, allowFirstName, allowLastName}))
		else loadPosts()
	}, [])

	useEffect(() => {
		if(isDemo) setUserPosts(getDemoImages({allowImage, allowCaption, allowFirstName, allowLastName}))
	}, [allowImage, allowCaption, allowFirstName, allowLastName, isDemo, setUserPosts])

	const uploadTemporaryFileAsync = useCallback((filename, blob, onStateChange) => new Promise( (resolve, reject) => {
		if(uploadTemporaryFile){
			uploadTemporaryFile(filename, blob, 
				(snapshot)=>{
					if(onStateChange) onStateChange(snapshot)
				}, 
				() => { //SUCCESS
					resolve()
				},
				(err) => { //FAIL
					reject(err)
				}, 
			)
		}
		else{
			reject("Element not running in the app")
		}
	
	}), [uploadTemporaryFile])

	const resetPost = useCallback( (e) => {
		resetData()
		setFileInputKey(key => key + 1)
		setIsUploading(false)
		setUploadedImageSrc(null)
		setIsPostModal(false)
	}, [setIsUploading, setUploadedImageSrc, setIsPostModal, setFileInputKey])

	const resetData = () => {
		const initData = {}
		if(allowImage) initData.image = null
		if(allowCaption) initData.caption = null
		if(allowFirstName) initData.firstName = null
		if(allowLastName) initData.lastName = null
		onChange(data.id, initData)
	}
	const setData = (key, fieldValue) => {
		return onChange(data.id, {
			...value,
			[key]: fieldValue
		});
	}

	const handleSubmitPost = useCallback( async (e) => {
		if( ! (value.image || value.caption ) ){
			setPostError("Please upload an image or type in some text.")
			setTimeout( () => setPostError(""), 3000)
			return
		}
		setIsSubmitting(true)
		const isPostSubmitted = await onSubmit(null, false)
		setIsSubmitting(false)
		if(isPostSubmitted){
			resetPost()
			loadPosts()
		}
		else{
			setPostError("Post submission failed! Please, try again or refresh the page.")
			setTimeout( () => setPostError(""), 3000)
		}
	}, [onSubmit, setPostError])

	const handleImageChange = useCallback(async (event) => {
		let file = event.target.files?.[0]
		if (!file) {
			console.log("file input error")
			return
		}

		setIsUploading(true)

		const imageDataURL = await readAsDataURL(file)
		const {image, width, height} = await getImageDimensions(imageDataURL)

		const MAX_ORIG_LENGTH = 1400;
		const MAX_THUMB_LENGTH = 640;

		let shouldGenThumb = true
		if(Math.max(width, height) <= MAX_THUMB_LENGTH + 100){
			shouldGenThumb = false
		}

		const imageProm = getResizedImageBlob(image, width, height, MAX_ORIG_LENGTH)
		const blobProms = [imageProm]
		if(shouldGenThumb){
			const thumbProm = getResizedImageBlob(image, width, height, MAX_THUMB_LENGTH)
			blobProms.push(thumbProm)
		}
		
		let defaultImage, thumb;
		[defaultImage, thumb] = await Promise.all(blobProms)

		try {
			let thumbId;
			const imageId = genUUID();
			const imageUploadProm = uploadTemporaryFileAsync(imageId + '.jpeg', defaultImage.blob)
			const uploadProms = [imageUploadProm]

			if(shouldGenThumb){
				thumbId = genUUID();
				const thumbUploadProm = uploadTemporaryFileAsync(thumbId + '.jpeg', thumb.blob)
				uploadProms.push(thumbUploadProm)
			}
			await Promise.all(uploadProms)
			const imageSet = [{
				src: imageId + '.jpeg',
				width: defaultImage.width,
				height: defaultImage.height
			}]
			if(shouldGenThumb){
				imageSet.push({
					src: thumbId + '.jpeg',
					width: thumb.width,
					height: thumb.height
				})
			}
			setData('image', imageSet)
			setIsUploading(false)
			setUploadProgress(0)
			setUploadedImageSrc(imageDataURL)
		} catch (error) {
			setIsUploading(false)
			setUploadProgress(0)
			console.log(error)
		}
		// uploadTemporaryFile(imageId + '.jpeg', resizedImage, 
		// 	(snapshot)=>{
		// 		console.log("on state change", snapshot)
		// 		const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
		// 		setUploadProgress(progress)
		// 	}, 
		// 	() => { //SUCCESS
		// 		setData('image', [
		// 			{
		// 				src: imageId + '.jpeg',
		// 				width: width,
		// 				height: height
		// 			},
		// 			{
		// 				src: imageId + '.jpeg',
		// 				width: width,
		// 				height: height
		// 			},
		// 		])
		// 		setIsUploading(false)
		// 		setUploadProgress(0)
		// 		setUploadedImageSrc(imageDataURL)
		// 	},
		// 	(err) => { //FAIL
		// 		setIsUploading(false)
		// 		setUploadProgress(0)
		// 		console.log(err)
		// 	}, 
		// )

		setFileInputKey(key => key + 1)
	}, [setData, value])

	return (
		<div className="ugc" style={{ margin: '30px', marginTop: marginTop, position: 'relative', textAlign: 'center' }}>
			<div className="mbottom--20"><button onClick={() => {if(!isDemo) setIsPostModal(true)}} className="btn btn-primary vm--align">{data.postButtonText}</button></div>
			{userPosts?.length > 0 &&
				<ColumnsPhotoAlbum
					photos={ userPosts.map( post => {
							let photo = {}
							let image = post.data.image
							if(image){
								if(image.length >= 2){
									image = image[1] //get thumb if it exists
								}
								else{
									image = image[0] //get full sized image
								}
								photo = {...image}
							}
							else{
								photo = {
									key: post.id,
									width: 200,
									height: 50
								}
							}
							return { 
								...photo,
								height: photo.height,
								caption: post.data.caption, 
								firstName: post.data.firstName,
								lastName: post.data.lastName,
							} 
						})//.filter( post => post.src) 
					}
					onClick={({ index }) => setIndex(index)}
					columns={(containerWidth) => {
							if (containerWidth < 500) return 1;
							if (containerWidth < 700) return 2;
							return 3;
						}}
					render={{
						image: (props, { photo, index }) => {
							return (
								<div>
									{photo.src &&
										<img {...props} />
									}
									<div style={{padding: '10px', backgroundColor: 'white', textAlign: 'left'}}>
										<div>{photo.caption}</div>
										<div className="text--muted font--14 mtop--5">{photo.firstName} {photo.lastName}</div>
									</div>
								</div>
							)
						},
						// render custom styled link
						// photo: (onclickprops, props) => {
						// 	console.log("props", props)
						// 	console.log("onclickprops", onclickprops)
						// 	return (
						// 		<div> {props.width} {props.height} </div>
						// 	)
						// }
					}}
				/>
			}
			<Lightbox
				open={index >= 0}
				index={index}
				close={() => setIndex(-1)}
				slides={userPosts.map( post => {
						let displayName = (post.data.firstName||"");
						if (displayName) displayName += " ";
						displayName += (post.data.lastName||"");
						return { 
							...post.data.image?.[0],
							caption: post.data.caption, 
							displayName: displayName
						} 
					})}
				render={{
						slideFooter: ({ slide }) => {
							if(!slide.src) return undefined

							return (
								<div style={{padding: '10px 20px', backgroundColor: 'white', textAlign: 'left', width: '100%'}}>
									<div>{slide.caption}</div>
									<div className="text--muted font--14 mtop--5">{slide.displayName}</div>
								</div>
							)
						},
						slide: ({ slide, offset, rect }) => {
							if(slide.src) return undefined

							return (
								<div style={{padding: '10px 20px', backgroundColor: 'white', textAlign: 'left', width: '100%'}}>
									<div>{slide.caption}</div>
									<div className="text--muted font--14 mtop--5">{slide.displayName}</div>
								</div>
							)
						},
					}}
				styles={{
						slide: {
							flexDirection: 'column',
						}
					}}
				controller={{ closeOnBackdropClick: true, closeOnPullUp: true, closeOnPullDown: true }}
				plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
			/>
			{isPostModal &&
				<Modal
					isOpen={isPostModal}
					onRequestClose={resetPost}
					contentLabel="Create post modal"
					style={modalStyles}
					appElement={document.getElementById("root")}
				>
					<div className="modal active animate__animated animate__fadeIn" style={{width: 'unset', maxWidth:'unset'}}>
						<div className="modal__header">
							<div className="modal__header-title">
								<h2 className="pright--20 pbottom-20 pleft--20">Create post</h2>
							</div>
							<div className="close-modal modal--x" onClick={resetPost}>
								<svg viewBox="0 0 20 20">
									<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
								</svg>
							</div>
						</div>
						<div className="modal-content">
							<div className="modal__body" style={{paddingBottom: 0}}>
								{allowImage &&
									<div>
										{uploadedImageSrc &&
											<div className="text--center">
												<img src={uploadedImageSrc} style={{ display: 'inline-block', maxHeight: '400px', maxWidth: '100%' }} />
											</div>
										}
										<div className="text--center mtop--10">
											{!isUploading &&
												<>
													<label htmlFor="ugc_image_input" className="pointer">
														<span className="material-icons text--muted" style={{ fontSize: '36px' }}>
															add_photo_alternate
														</span>
														{!uploadedImageSrc && <span>Upload Image</span>}
														{uploadedImageSrc && <span>Change Image</span>}
													</label>
													<input id="ugc_image_input" key={fileInputKey} type="file" name="post_image" onChange={handleImageChange} accept="image/*" style={{ display: 'none' }} />
												</>
											}
											{isUploading &&
												<MiniLoader />
											}
											{/* <span className="text--muted"> &nbsp;( {Math.round(uploadProgress)}% ) </span> */}
										</div>
									</div>
								}
								<div>
									{allowCaption &&
										<UGCFieldInput inputType="text" name={'caption'} value={value?.caption || ""} label={data.captionTitle || 'Caption'} placeholder={'Caption'} elementErrors={null} onChange={setData} charLimit={256} />
									}
									{allowFirstName &&
										<UGCFieldInput inputType="text" name={'firstName'} value={value?.firstName || ""} label={data.firstNameTitle || 'First Name'} placeholder={'First Name'} elementErrors={null} onChange={setData} charLimit={32} />
									}
									{allowLastName &&
										<UGCFieldInput inputType="text" name={'lastName'} value={value?.lastName || ""} label={data.lastNameTitle || 'Last Name'} placeholder={'Last Name'} elementErrors={null} onChange={setData} charLimit={64} />
									}
								</div>

							</div>
							<div className="text--center">
								{postError &&
									<div className="text--danger">{postError}</div> 
								}
								<div>
									<button className="btn btn-primary" disabled={isSubmitting} style={{ width: '150px', margin: '30px 10px' }} onClick={handleSubmitPost}>
										{!isSubmitting && <span>{data.postButtonText}</span>}
										{isSubmitting && <span>Submitting...</span>}
									</button>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			}
		</div>
	);
};
export default UGC;
