import { useEffect, useState } from "react";
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import useDebounce from "./UseDebounce";

function RichText(props) {
	const { item, value, updateAction, extra, isSmall, returnEmptyString } = props;
	const [newValue, setValue] = useState(value);
	const [backgroundColor, setBackgroundClor] = useState('hls(0, 0%, 100%)');
	//const throttledValue = useDebounce(newValue, 900);
	const [saving, setSaving] = useState(false);

	let config = {
		fontSize: {
			options: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 28, 30, 34, 38, 42, 48, 55 ],
		},
		toolbar: {
			items: [
				'alignment',
				'bold', 'italic', 'strikethrough', 'underline', '|',
				'link', '|',
				'bulletedList', 'numberedList', 'todoList',
				'fontfamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|',
				'insertTable', '|',
				'-',
				'undo', 'redo'
			],
			shouldNotGroupWhenFull: true
		}
	};
	if(isSmall){
		config = {
			fontSize: {
				options: [9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 28, 30, 38, 42, 55 ],
			},
			toolbar: {
				items: [
					'alignment',
					'bold', 'italic', 'underline', '|',
					'fontSize', 'fontColor', 'fontBackgroundColor', 'link','|',
					'undo', 'redo'
				],
				shouldNotGroupWhenFull: true
			}
		};
	}


	function hslToRgb(h, s, l) {
		var r, g, b;

		if (s == 0) {
			r = g = b = l;
		} else {
			var hue2rgb = function hue2rgb(p, q, t) {
				if (t < 0) t += 1;
				if (t > 1) t -= 1;
				if (t < 1 / 6) return p + (q - p) * 6 * t;
				if (t < 1 / 2) return q;
				if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
				return p;
			}

			var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
			var p = 2 * l - q;
			r = hue2rgb(p, q, h + 1 / 3);
			g = hue2rgb(p, q, h);
			b = hue2rgb(p, q, h - 1 / 3);
		}

		return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
	}

	function getContrastingColor(rgb) {
		var color = rgb.substring(4, rgb.length-1)
				.replace(/ /g, '')
				.split(',');
		var o = Math.round(((parseInt(color[0]) * 299) +
						(parseInt(color[1]) * 587) +
						(parseInt(color[2]) * 114)) / 1000);
		if (o > 125) {
			return "rgb(0,0,0)";
		} else {
			return "rgb(255,255,255)";
		}
	}

	function calcBackground(color) {
		var hslColor = { h: color.h, s: color.s, l: color.l };
		var rgbColor = hslToRgb(hslColor.h / 360, hslColor.s, hslColor.l);
		return getContrastingColor(`rgb(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b})`);
	}
	const getBackground = () => {
		if(!newValue) return 'transparent';

		var hslRegex = /(?<!-)color:hsl\((\d{1,3}),\s*([\d.]+)%,\s*([\d.]+)%\)/gi;
		var colors = [];
		var matches = newValue.match(hslRegex);
		if(matches != null){
			matches.forEach(function (match) {
				var hslRegex = /hsl\((\d{1,3}),\s*([\d.]+)%,\s*([\d.]+)%\)/gi;
				var match = hslRegex.exec(match);
				if(match){
					colors.push({h: parseInt(match[1]), s: parseInt(match[2]), l: parseInt(match[3])})
				}
			});
		}
		return colors.length > 0 ? setBackgroundClor(calcBackground(colors[0])) : 'transparent';
	}
	const save = () => {
		setSaving(true)
		const calculated = !newValue && returnEmptyString ? ' ' : newValue;
		updateAction(item, calculated, extra)
		setTimeout(() => {
			setSaving(false);
		}, 1000)
	}
	useEffect(() => {
		getBackground();
		if (newValue === value) return;
		save();
	}, [newValue])

	return (
		<div style={{background:backgroundColor}}>
			<div className="flex flex--row" style={{minHeight: '40px'}}>
				{ saving && <div className="btn btn-light float--right btn-md"> <span className="loader loader__sm"></span> Saving...</div> }
			</div>
			<CKEditor
				onReady={ editor => {
					editor.ui.getEditableElement().parentElement.insertBefore(
						editor.ui.view.toolbar.element,
						editor.ui.getEditableElement()
						);
				} }
				onError={ ( error, { willEditorRestart } ) => {
					if ( willEditorRestart ) {
						this.editor.ui.view.toolbar.element.remove();
					}
				} }
				onBlur={(event, editor) => {
					setValue(editor.getData())
				}}
				editor={ DecoupledEditor }
				data={newValue}
				config={config}
			/>
		</div>
	);
}

export default RichText;
