import { useDispatch } from 'react-redux';
import EditableInput from "../../../../utils/EditableInput";
import { updateElement } from "../../../../utils/UpdateFunctions";
import { editElement } from "../../../../../actions/UserActions";
import { getDefaultElement } from "../../CampaignAddDefaults";

function SoccerLineUpSelectorCategories({currentCampaignId, currentPage, currentElement, elementCopy, setElementCopy}) {
	const dispatch = useDispatch();
	const {categories} = elementCopy;
	const update = (key, value, extra) => {
		let f = elementCopy.categories.find(category => category.id === extra['category_id']);
		if(f) f[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const addCategory = () => {
		elementCopy.categories.push(getDefaultElement('soccerLineUpCategory'));
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const deleteCategory = (category_id) => {
		const o = elementCopy['categories'].filter(category => category.id !== category_id);
		elementCopy.categories = o;
		elementCopy.players = elementCopy.players.map((player) => {
			player.categories = player.categories.filter(ct => ct !== category_id);
			return player
		})
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	return (
		<div className="campaign__option mtop--20">
			<label>Create the categories in which you will split up your players.</label>
			{categories.map((category, index) => {
				return (
					<div className="campaign__option mtop--10" key={`cat__${index}`}>
						<div className="mtop--10 flex flex--row">
							<EditableInput extra={{category_id: category.id}} item="title" type="text" startValue={category.title} updateAction={update} placeholder={'Type here...'} />
							<button className="btn btn-secondary mleft--10 float--right" onClick={() => deleteCategory(category.id)}>
								<span className="material-icons font--20 text--danger">
									delete
								</span>
							</button>
						</div>
					</div>
				)
			})}
			<button className="btn btn-primary btn-100 mtop--10" onClick={() => addCategory()}>
				<span className="material-icons font--20 vm--align">add</span>
				Add Category
			</button>
		</div>
	);
}

export default SoccerLineUpSelectorCategories;
