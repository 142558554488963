import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {  getUser, on, off, storeUser, sendEmailVerification } from './firebaseActions.mjs';
import Loader from './Loader.jsx'
import { setUser } from "./actions/UserActions";
import CampaignList from './components/pages/CampaignList';
import Campaign from './components/pages/Campaign';
import Login from "./components/pages/LoginRegistration/Login";
import Registration from "./components/pages/LoginRegistration/Registration";
import PasswordReset from "./components/pages/LoginRegistration/PasswordReset";
import IncomingRequests from './components/pages/IncomingRequests';
import "./scss/main.scss";
import { ToastContainer } from 'react-toastify';
import AdminAuth from "./components/pages/LoginRegistration/AdminAuth.jsx";
import TwoFactorModal from "./components/pages/LoginRegistration/TwoFactorModal.jsx";

function App() {
	const dispatch = useDispatch();
	const user = useSelector(state => state.user.user);
	const [MFAResolver, setMFAResolver] = useState(null);
	const [loadingStatus, setLoadingStatus] = useState('loading');
	const [hasAccess, setHasAccess] = useState(true);
	const onLogin = function (user) {
		console.log(user)
		dispatch(setUser(user));
		setLoadingStatus('loggedIn');
		getUser(user.uid)
			.then(function (data) {
				if (data === undefined) {
					if (user.emailVerified === false) {
						sendEmailVerification(user);
					}
					storeUser(user.uid)
					.then(function (dt) {
						console.log('dt.data', dt.data)
						window.location.reload();
					})
					.catch(function (error) {
						console.log('error', error)
					})
				} else {
					if(data.settings.removedAccess){
						setHasAccess(false);
					}
					//console.log('User:', data.settings)
					/*if(campaignId){
						getCampaign(campaignId)
						.then(function({campaign}){
							console.log('campaign:', campaign);
						})
					}else{

					}*/
				}
			})
			.catch(function (error) {
				console.error('User error', error)
			})
	};
	const onLogout = function () {
		setLoadingStatus('loggedOut');
	};
	const onTwoFactor = function (resolver) {
		setMFAResolver(resolver);
	};
	useEffect(() => {
		on("login", onLogin);
		on("logout", onLogout);
		on("two-factor", onTwoFactor);
		return () => {
			off("login", onLogin);
			off("logout", onLogout);
			off("two-factor", onTwoFactor);
		}
	}, []);
	return (
		<div className="App">
			<ToastContainer />
			{(loadingStatus === 'loading') && <Loader />}
			{hasAccess &&
			<>
				<BrowserRouter>
					<Routes>
						{(loadingStatus === 'loggedIn') &&
							<>
								<Route index path="/" element={<CampaignList />} />
								<Route path="/campaigns/:id/*" element={<Campaign />} />
								<Route path="/registration" element={<CampaignList />} />
								<Route path="/login" element={<CampaignList />} />
								<Route path="/adminAuth/:token" element={<AdminAuth />} />
							</>
						}
						{(loadingStatus === 'loggedOut') &&
							<>
								<Route index path="/" element={<Login loadingStatus={loadingStatus} setLoadingStatus={setLoadingStatus} />} />
								<Route index path="/campaigns/*" element={<Login loadingStatus={loadingStatus} setLoadingStatus={setLoadingStatus} />} />
								<Route index path="/registration/" element={<Registration loadingStatus={loadingStatus} setLoadingStatus={setLoadingStatus} />} />
								<Route index path="/:defaultEmail/:signInProvider" element={<Login loadingStatus={loadingStatus} setLoadingStatus={setLoadingStatus} />} />
								<Route index path="/registration/:defaultEmail" element={<Registration loadingStatus={loadingStatus} setLoadingStatus={setLoadingStatus} />} />
								<Route index path="/passwordReset" element={<PasswordReset />} />
								<Route path="/adminAuth/:token" element={<AdminAuth />} />
							</>
						}
						<Route path="/incomingRequests/:id/:secret" element={<IncomingRequests />} />
					</Routes>
				</BrowserRouter>
				{ MFAResolver && <TwoFactorModal resolver={MFAResolver} />}
			</>}
			{!hasAccess && <div style={{'textAlign':'center'}}>Please contact the SportoVivo administrator <a href="mailto:info@sportovivo.com">info@sportovivo.com</a></div>}
		</div>
	);
}

export default App;
