import { useState, useEffect, useCallback } from "react";
import {useSelector} from 'react-redux';
import useDebounce from "../../../utils/UseDebounce";
import 'react-tippy/dist/tippy.css';
import {Tooltip} from 'react-tippy';
import { getNumericPredictionResults } from './ResultsCreator';
import DetailedResultsTable from "./DetailedResultsTable";

function NumericPredictionResults() {
	const {resultsCurrentElement, unMappedUserResults} = useSelector(state => state.user);
	const [mappedResults, setMappedResults] = useState([])
	const [mappedGeneralResults, setMappedGeneralResults] = useState({})
	const [calculatedResults, setCalculatedResults] = useState([])
	const [calculatedGeneralResults, setCalculatedGeneralResults] = useState([])
	const [calculatedByScoreGeneralResults, setCalculatedByScoreGeneralResults] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const throttledSearchTerm = useDebounce(searchTerm, 600);
	const [view, setView] = useState('general');
	const [sorting, setSorting] = useState('popularity');
	const [onlyValidAnswers, setOnlyValidAnswers] = useState(false);
	const [loading, setLoading] = useState(false);
	
	const mapResults = useCallback(async () => {
		setLoading(true)
		const results = await getNumericPredictionResults(unMappedUserResults, resultsCurrentElement, onlyValidAnswers)
		setMappedResults(results[0]);
		setMappedGeneralResults(results[1]);
		setLoading(false)
	}, [onlyValidAnswers, unMappedUserResults, resultsCurrentElement])
	const calculateMappedResults = useCallback(() => {
		if(view === 'general'){
			if(sorting === 'popularity'){
				let sortable = [];
				for (let score in mappedGeneralResults) {
					sortable.push([score, mappedGeneralResults[score]['value'], mappedGeneralResults[score]['percentage']]);
				}
				sortable.sort(function(a, b) {
					return b[1] - a[1];
				});
				setCalculatedGeneralResults(sortable);
			}else{
				const options = resultsCurrentElement.tableHeader.filter((element) => element.type === "options")
				let o = {
					homeWin: {
						title: options?.[0].value,
						values: []
					},
					draws: {
						title: 'Draw',
						values: []
					},
					awayWin: {
						title: options?.[1].value,
						values: []
					},
				}
				for (let score in mappedGeneralResults) {
					let nums = score.split('-');
					console.log(score)
					if(parseInt(nums[0]) > parseInt(nums[1])){
						o['homeWin']['values'].push([score, mappedGeneralResults[score]['value'], mappedGeneralResults[score]['percentage']])
					}else if(parseInt(nums[0]) === parseInt(nums[1])){
						o['draws']['values'].push([score, mappedGeneralResults[score]['value'], mappedGeneralResults[score]['percentage']])
					}else{
						o['awayWin']['values'].push([score, mappedGeneralResults[score]['value'], mappedGeneralResults[score]['percentage']])
					}
				}
				setCalculatedByScoreGeneralResults(o);
			}
		}else{
			let o = [];
			if(throttledSearchTerm){
				o = mappedResults.filter((row) => {
					return row.find(cell => {
						return cell?.toString().toLowerCase().search(throttledSearchTerm.toLowerCase()) >= 0
					});
				})
			}else{
				o = mappedResults
			}
			o.sort((a, b) => {
				let a1 = a[a.length-1]
				let b1 = b[b.length - 1]
				return new Date(b1) - new Date(a1);
			})
			setCalculatedResults(o)
		}
	}, [mappedGeneralResults, mappedResults, resultsCurrentElement, sorting, view, throttledSearchTerm])

	const mapSortedGeneral = (key) => {
		const d = calculatedByScoreGeneralResults.hasOwnProperty(key) && calculatedByScoreGeneralResults[key]['values'];
		if(!d) return;
		return (
			d.map((result, index) => {
				return (
					<div className="bar__chart mtop--10" key={`bar__${index}`}>
						<span className="bar__chart-title">
							{result[0]}
							<div className="font--12 text--normal">({result[1]} Vote{result[1] !== 1 && 's'})</div>
						</span>
						<div className="bar__chart-bar" style={{width: result[2]+'%'}}>
							<span className="bar__chart-percentage">{result[2] + '%'}</span>
						</div>
					</div>
				)
			})
		)
	}
	const getScoreTitle = (key) => {
		const d = calculatedByScoreGeneralResults.hasOwnProperty(key) && calculatedByScoreGeneralResults[key]['title'];
		return d ? d : 'key'
	}
	useEffect(() => {
		calculateMappedResults();
	}, [mappedResults, throttledSearchTerm, calculateMappedResults])
	useEffect(() => {
		if(resultsCurrentElement && unMappedUserResults) mapResults();
	}, [resultsCurrentElement, unMappedUserResults, mapResults])
	return (
		<div className="numericPrediction__results">
			<div className="flex flex--row">
				<div className='tabs'>
					<div className={`tabs__item ${view == 'general' ? 'tabs__item-active' : ''}`} onClick={() => setView('general')}>
						<span className="material-icons font--25 vm--align">
							donut_small
						</span>
						General Stats
					</div>
					<div className={`tabs__item ${view == 'detailed' ? 'tabs__item-active' : ''}`} onClick={() => setView('detailed')}>
						<span className="material-icons font--25 vm--align">
							table_chart
						</span>
						Detailed Stats
					</div>
				</div>
			</div>
			<div className="mtop--10">
				{ view === 'detailed' &&
					<div className="numericPrediction__results-detailed">
						<div className="numericPrediction__results-detailedActions">
							<div className="item">
								<input type="text" placeholder="Search by name, email..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
							</div>
							<div className="item float--right flex flex--row text--right">
								<Tooltip title="This options works only if you have a registration element. Verified answers are the ones that have at least a name or a surname" position="bottom">
									<div className="md__input mtop--10 mright--10" style={{width: '300px'}}>
										<input type="checkbox" className="switch" checked={onlyValidAnswers} onChange={(e) => setOnlyValidAnswers(e.target.checked)}/>
										<label>Only verified answers</label>
									</div>
								</Tooltip>
							</div>
						</div>
						<div className="mtop--20 table__wrapper">
							<DetailedResultsTable total={calculatedResults.length} header={resultsCurrentElement.tableHeader} rows={calculatedResults} />
							{ loading &&
								<div className="text--center">
									<span className="loader loader__md"></span>
								</div>
							}
						</div>
					</div>
				}
				{ view === 'general' &&
					<div className="inner">
						<div className="flex flex--row">
							<button onClick={() => setSorting('popularity')} className={ sorting === 'popularity' ? 'btn btn-primary-soft':'btn btn-secondary'}>Popularity</button>
							<button onClick={() => setSorting('score')} className={ sorting === 'score' ? 'btn btn-primary-soft':'btn btn-secondary'}>Score</button>
						</div>
						<div className="mtop--20">
							{ sorting === 'popularity' &&
								<div className="contain__general-popularity">
									{
										calculatedGeneralResults.map((result, index) => {
											return (
												<div className="bar__chart" key={`bar__${index}`}>
													<span className="bar__chart-title">
														{result[0]}
														<div className="font--12 text--normal">({result[1]} Vote{result[1] != 1 && 's'})</div>
													</span>
													<div className="bar__chart-bar" style={{width: result[2]+'%'}}>
														<span className="bar__chart-percentage">{result[2] + '%'}</span>
													</div>
												</div>
											)
										})
									}
								</div>
							}
							{ sorting === 'score' &&
								<div className="flex flex--row">
									<div className="flex--33">
										<div>
											<h3>{getScoreTitle('homeWin')}</h3><br />
											{mapSortedGeneral('homeWin')}
										</div>
									</div>
									<div className="flex--33 mleft--10">
										<h3>Draw</h3><br />
										{mapSortedGeneral('draws')}
									</div>
									<div className="flex--33 mleft--10">
										<h3>{getScoreTitle('awayWin')}</h3><br />
										{mapSortedGeneral('awayWin')}
									</div>
								</div>
							}
						</div>
					</div>
				}
			</div>
		</div>
	);
}

export default NumericPredictionResults;
