import { useEffect, useState } from "react";
import "../../../scss/pages/Registration.scss";
import { useParams } from "react-router";
import logo from '../../../images/logo__white.png';
import googleImage from "../../../images/google.png"
import ThemeSwitch from "../../utils/ThemeSwitch";
import { showLogin, on, off, getUser, createAccount, registerWithEmailAndPassword } from '../../../firebaseActions.mjs';
import PasswordChecklist from "react-password-checklist"

function Registration({loadingStatus, setLoadingStatus}) {
    const { defaultEmail } = useParams();
    const [email, setEmail] = useState(defaultEmail ? defaultEmail : '');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [error, setError] = useState(null);
    const initRegistration = () => {
        setError(null)
        if(password !== passwordRepeat){
            setError('The passwords do not match!')
            return
        }
        const dt = JSON.parse(localStorage.getItem("sportovivo__request")||null);
        const request = (dt?.email === email)?{id:dt.id, secret:dt.secret}:null;
        registerWithEmailAndPassword(email, password, request)
        .then((data) => {
            localStorage.removeItem("sportovivo__request");
            window.location.href = '/'
        })
        .catch((error) => {
            console.log(error.code)
            if (error.code == 'auth/invalid-email'){
                setError('Please insert a valid email address')
            } else if (error.code == 'auth/email-already-in-use'){
                setError('The email is already registered. Try logging in.')
            } else if (error.code == 'auth/weak-password'){
                setError('Please provide a stronger password')
            }
        })
    }
    useEffect(() => {
        console.log(loadingStatus)
        if(loadingStatus == 'loggedIn') window.location.href = '/'
    }, [])
    return (
        <div className="cms__registration">
            <div className="cms__registration__content">
                <div className="cms__registration__body">
                    <div className="flex flex--row justify--center">
                        <img src={logo} width="250" />
                        <ThemeSwitch />
                    </div>
                    <div className="text--center mtop--20">
                        <h2 className="">Registration</h2>
                    </div>
                    <div className="text--center mtop--10">Welcome, <br />Register in to continue.</div>
                    <div className="mtop--20">
                        <div className="mtop--10">
                            <label className="text--bold">Email</label>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Type here..." disabled={defaultEmail} />
                        </div>
                        <div className="mtop--10">
                            <label className="text--bold">Password</label>
                            <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Type here..." />
                        </div>
                        <div className="mtop--10 mbottom--20">
                            <label className="text--bold">Repeat Password</label>
                            <input value={passwordRepeat} onChange={(e) => setPasswordRepeat(e.target.value)} type="password" placeholder="Type here..." />
                        </div>
                        <PasswordChecklist
                            rules={["minLength", "specialChar", "number", "match"]}
                            minLength={5}
                            value={password}
                            valueAgain={passwordRepeat}
                            onChange={(isValid) => { setValidPassword(isValid) }}
                        />
                        {error &&
                            <div className="text--danger mtop--10">
                                {error}
                            </div>
                        }
                        <div className="mtop--10 btn btn-primary btn-100" disabled={!email || !password || !validPassword} onClick={initRegistration}>
                            Submit
                        </div>
                    </div>
                    <div disabled={defaultEmail}>
                        <div className="text--center mtop--10">OR</div>
                        <div className="btn btn-light btn-100" onClick={showLogin}>
                            <img src={googleImage} width="30" className="mright--20" />
                            Register with Google
                        </div>
                        <div className="mtop--20 text--center">
                            Already an account? <a href="/">Login</a>
                        </div>	
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Registration;
