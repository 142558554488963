import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import "../../scss/pages/IncomingRequests.scss";
import { getRequestUsingSecret, logout } from '../../firebaseActions.mjs'
import ThemeSwitch from "../utils/ThemeSwitch";
import Loader from "../../Loader";

function IncomingRequests() {
	const { id, secret } = useParams();
	const {user} = useSelector( state => state.user);
	const [loading, setLoading] = useState(null);
	const [request, setRequest] = useState(null);
	const [provider, setProvider] = useState('password');

	const login = () => {
		localStorage.setItem("sportovivo__request", JSON.stringify({ id: id, secret: secret, email:request.email }))
		if (user) {
			if (user.email !== request.email) logout()
			if (user.email === request.email) {
				window.location.href = `/`
				return;
			}
		}
		window.location.href = `/${request.email}/${provider}`
	}
	const register = () => {
		localStorage.setItem("sportovivo__request", JSON.stringify({ id: id, secret: secret, email:request.email }))
		if (user) {
			if (user.email !== request.email) logout()
			if (user.email === request.email) window.location.href = `/`
		}
		window.location.href = `/registration/${request.email}/`
	}
	useEffect(() => {
		setLoading(true)
		if(secret){
			getRequestUsingSecret(id, secret)
			.then((data) => {
				setRequest(data.request);
				setProvider(data.signInProvider)
			})
			.catch((e) => {
				console.error(e)
			})
			.finally( () => setLoading(false))
		}
	}, [id, secret])
	return (
		<div className="incomingRequests">
			<div className="incomingRequests__inner">
				<div className="incomingRequests__content">
					<ThemeSwitch />
					{ loading && <span className="loader loader__md"></span> }
					{ request &&
						<div className="mtop--20">
							<div className="text--center">
								<b>{request?.granter?.email}</b>
								&nbsp;has requested you join their organization with the following account:&nbsp;
								<b>{request.email}</b> as  <b className="capitalize">{request?.role}</b>
								{request?.granteeId &&
									<div className="btn btn-primary btn-100" onClick={login}>Login with <b>{request?.email}</b> to respond</div>
								}
								{!request?.granteeId &&
									<div className="btn btn-primary btn-100" onClick={register}>Register with <b>{request?.email}</b> to respond</div>
								}
							</div>
						</div>
					}
					
				</div>
			 </div>
		</div>
	);
}

export default IncomingRequests;
