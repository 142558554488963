import {useState} from 'react';
import pieImage from '../../../../images/undraw_pie_chart_re_bgs8.svg';
import giftImage from '../../../../images/undraw_happy_birthday_re_c16u.svg';
import { getDefaultElement } from '../CampaignAddDefaults';

function WheelofPrizesWizard({ data, clickAction }) {
	const [element, setElement] = useState(getDefaultElement(data.type))
	return (
		<div>
			<div className="mtop--20">
				<div className="editable__input-inp flex flex--row flex--wrap">
					<label className="text--bold">Have a fake spin page first?</label>
					&nbsp;
					<input  type="checkbox" className='switch' value={element.hasPreview} onChange={(e) => element.hasPreview = e.target.checked } />
				</div>
				<div className="font--12 text--muted">
					Fake spin page is a page that appears on the home screen of the campaign. The user will spin, lose and be prompted with a registration page
					to enter his details before going to the actual wheel of prizes page.
					By having this, you ensure that the user will enter his details before spinning so that you have only valid winners on your results.
				</div>
			</div>
			<div className="mtop--20 btn btn-primary btn-100 btn-xl" onClick={() => clickAction(data, element)}>
				<span className="material-icons font--20">add</span>
				Create
			</div>
		</div>
	);
}

export default WheelofPrizesWizard;