import {useState, useEffect} from 'react';
import { getTypesAndElements } from '../../utils/PageElements';

function AddCampaignModal({ closeCb, addAction, name, setName, adding, selectedType, setSelectedType }) {
	const [step, setStep] = useState(1);
	const [selectedElement, setSelectedElement] = useState(null);

	const [types, decorativeElements] = getTypesAndElements()

	const getElementText = (type) => {
		const f = types.find( element => element.type === type);
		return f?.text || type
	}

	useEffect(() => {
		const f = types?.find(element => element.type === selectedType)
		if(!f) return;
		setSelectedElement(f);
	}, [selectedType])

	return (
		<div className="modal-overlay active">
			<div className={`modal active animate__animated animate__fadeInDown ${step === 1 && 'modal--50'} ${step === 2 && 'modal--lg'}`}>
				<div className="modal__header">
					<div className="modal__header-title">
					</div>
					<div className="close-modal modal--x" onClick={ () => { closeCb(false) }}>
						<svg viewBox="0 0 20 20">
							<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
						</svg>
					</div>
				</div>
				<div className="modal-content">
					<div className="modal__body">
						{step === 1 && 
							<div className="mtop--10">
								<label className="text--bold">Campaign Name</label>
								<div className="text--muted font--13">Type the name of your campaign</div>
								<br/>
								<input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Type here..." className="mtop--20"/>
							</div>
						}
						{step === 2 &&
							<div className="mtop--10">
								<label className="text--bold">Campaign Type</label>
								<div className="text--muted font--13">Pick what type of campaign you want to create.</div>
								<br/>
								<div className="campaign__types">
									<div className="campaign__types-left">
										{types.map((element, idx) => {
											return (
												<div key={`campaign__type-${idx}`} className={`campaign__types-type ${element.type === selectedType && 'campaign__type-typeActive'}`} onClick={() => setSelectedType(element.type)}>	
													<span className="material-icons font--25">
														{element.icon}
													</span>
													<div className="mtop--5 mleft--10">
														{element.text}
													</div>
												</div>
											)
										})}
									</div>
									<div className="campaign__types-right">
										<h3>Included</h3>
										<div className="text--muted font--12">These are the features you can have into this type of campaign.</div>
										<ul className="mtop--20" style={{listStyle: 'square'}}>
											{selectedElement?.allowedNonDecorativeFeaturesOnCampaign?.map((element, idx) => {
												return <li key={`custom_type_${idx}`}>{getElementText(element)}</li>
											})}
											{decorativeElements.map((element, idx) => {
												return <li key={`deco_type_${idx}`}>{element.text}</li>
											})}
										</ul>
									</div>
								</div>
							</div>
						}
					</div>
					<div className="modal__footer flex flex--row" style={{justifyContent: 'space-between'}}>
						<button className="btn btn-transparent" disabled={step === 1} onClick={() => setStep(step - 1)}>
							<span className="material-icons font--20">
								arrow_back_ios
							</span>
							Back
						</button>
						{ step === 1 && 
							<button className="btn btn-transparent text--primary" onClick={() => setStep(step + 1)}>
								Next
								&nbsp;
								<span className="material-icons font--20">
									arrow_forward_ios
								</span>
							</button>
						}
						
						{step === 2 &&
							<button className="btn btn-primary" disabled={!name || adding} onClick={ () => { addAction() }}>
								{ adding ? <span className="loader loader__sm"></span> : 'Create Campaign'}
							</button>
						}
						
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddCampaignModal;
