import {useState} from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {CopyToClipboard} from 'react-copy-to-clipboard';

function EmbedModal({closeCb, campaign_url, custom_user_url}) {
	const code = "<iframe frameborder='0' border='0' cellspacing='0' style='border-style: none;width: 100%; height: 600px' src='"+campaign_url+"'></iframe>";
	const [copiedHtml, setCopiedHtml] = useState(false);
	const [copiedCustomUser, setCopiedCustomUser] = useState(false);
	const copy = (cb) => {
		cb(true);
		setTimeout(() => cb(false), 3000);
	}
	const getCss = (val) => {
		let s = "code__card";
		if(val) s += ' code__card-copied';
		return s;
	}
	return (
		<div className="modal-overlay active">
			<div className="modal active animate__animated animate__slideInRight">
				<div className="modal__header">
					<div className="modal__header-title">
					</div>
					<div className="close-modal modal--x" onClick={ () => { closeCb(false) }}>
						<svg viewBox="0 0 20 20">
							<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
						</svg>
					</div>
				</div>
				<div className="modal-content">
					<div className="modal__body">
						{custom_user_url && <div>
							<h4>Native App</h4>
							<div className={getCss(copiedCustomUser)}>
								{ !copiedCustomUser && <CopyToClipboard text={custom_user_url} onCopy={() => copy(setCopiedCustomUser)}>
									<div>
										{custom_user_url}
										<button className="btn btn-primary-soft btn-sm code__card-copy">
											<span className="material-icons font--20">
												content_copy
											</span>
										</button>
									</div>
								</CopyToClipboard>}
								{ copiedCustomUser &&
									<div className="text--center">
										<span className="material-icons font--30 text--success">
											verified
										</span>
										<br />
										Copied
									</div>
								}
							</div>
						</div>}
						<h4>Copy this piece of code and add into your page.</h4>
						<div className="mtop--15">
							<label>HTML</label>
							<div className={getCss(copiedHtml)}>
								{ !copiedHtml &&
									<>
										<CopyToClipboard text={code} onCopy={() => copy(setCopiedHtml)}>
											<button className="btn btn-primary-soft btn-sm code__card-copy">
												<span className="material-icons font--20">
													content_copy
												</span>
											</button>
										</CopyToClipboard>
										<SyntaxHighlighter wrapLongLines language="html" style={docco}>
											{code}
										</SyntaxHighlighter>
									</>
								}
								{ copiedHtml &&
									<div className="text--center">
										<span className="material-icons font--30 text--success">
											verified
										</span>
										<br />
										Copied
									</div>
								}
							</div>
						</div>
					</div>
					<div className="modal__footer text--right ">
						<button className="btn btn-primary"  onClick={ () => { closeCb(false) }}>
							Done
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EmbedModal;
