import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { QRCode } from 'react-qrcode-logo';
import '../../scss/pages/Share.scss'
import EditableInput from './EditableInput';

function ShareModal({ campaign_url, isStatic, showShare, updateAction, showOnlyIcon }) {
	const [modal, setModal] = useState(false)
	const [copied, setCopied] = useState();
	const copy = () => {
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 3000);
	}
	const share = where => {
		var url = campaign_url
		if (where == 'facebook') {
			window.open('https://www.facebook.com/sharer/sharer.php?u=' + url,
				'facebook-share-dialog',
				'width=800,height=600'
			);
		} else if (where == 'linkedin') {
			window.open('http://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(url), '', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
		} else if (where == 'twitter') {
			window.open('https://twitter.com/intent/tweet?text=Participate in the campaign&url=' + url,
				'twitter-share-dialog',
				'width=800,height=600'
			);
		}
	}
	return (
		<div className={` share inline--flex ${isStatic && 'btn__share-static'}`}>
			{!modal &&
				<>
					{ !isStatic &&
						<button className={`btn btn-secondary btn__open ${showOnlyIcon && 'btn-md btn-transparent'}`} onClick={() => setModal(true)}>
							<i className="fa-solid fa-share vm--align mright--5 font--20"></i>
							{ !showOnlyIcon && <span className="topbar__actionItem">Share</span>}
						</button>
					}
					{ isStatic &&
						<div className="share__social">
							<div className="btn btn-secondary share__round-button" onClick={() => share('facebook')}>
								<i className="fa-brands fa-facebook-f font--20"></i>
							</div>
							<div className="btn btn-secondary share__round-button" onClick={() => share('linkedin')}>
								<i className="fa-brands fa-linkedin-in font--20"></i>
							</div>
							<div className="btn btn-secondary share__round-button" onClick={() => share('twitter')}>
								<i className="fa-brands fa-twitter font--20"></i>
							</div>
							<div className="btn btn-secondary share__round-button" onClick={() => setModal(true)}>
								<i className="fa-solid fa-share font--20 vm--align"></i>
							</div>
						</div>
					}
				</>
			}
			{modal &&
				<div className="modal-overlay active">
					<div className="modal active animate__animated animate__fadeIn">
						<div className="modal__header">
							<div className="modal__header-title">
								<h2>Share</h2>
								<div className="text--muted font--12">Copy the url, share directly to social media or just scan the qr code</div>
							</div>
							<div className="close-modal modal--x" onClick={() => { setModal(false) }}>
								<svg viewBox="0 0 20 20">
									<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
								</svg>
							</div>
						</div>
						<div className="modal-content">
							<div className="modal__body share">
								<div className='share__copy'>
									<input type="text" value={campaign_url} disabled />
									<CopyToClipboard text={campaign_url} onCopy={() => copy()}>
										<button className={`btn ${copied ? 'btn-success' : 'btn-primary'} btn-md share__copy-button`}>
											<span className="material-icons font--16 vm--align mright--5">
												content_copy
											</span>
											{copied ? 'Copied...' : 'Copy'}
										</button>
									</CopyToClipboard>
								</div>
								<div className="share__social">
									<div className="share__round-button share__round-buttonFacebook" onClick={() => share('facebook')}>
										<i className="fa-brands fa-facebook-f font--25"></i>
									</div>
									<div className="share__round-button share__round-buttonLinkedin" onClick={() => share('linkedin')}>
										<i className="fa-brands fa-linkedin-in font--25"></i>
									</div>
									<div className="share__round-button share__round-buttonTwitter" onClick={() => share('twitter')}>
										<i className="fa-brands fa-twitter font--25"></i>
									</div>
								</div>
								<div className="share__qr">
									<QRCode value={campaign_url} qrStyle='dots' size="250" eyeRadius={5} />
								</div>
								{ !isStatic &&
									<div className="text--center mtop--20 flex flex--row">
										<EditableInput type="checkbox" isSwitch item="showShare" startValue={showShare} updateAction={updateAction} />
										&nbsp;
										<h5>Show the share button on the app?</h5>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	);
}

export default ShareModal;
