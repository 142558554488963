import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import 'animate.css'
import {
	getOrganizations,
	deleteOrganization
} from '../../../firebaseActions.mjs';
import managerImage from '../../../images/undraw_designer_girl_re_h54c.svg'
import { storeAddOrganization, storeClearOrganizations, storeDeleteOrganization } from '../../../actions/OrganizationsActions'
import ConfirmDelete from '../../utils/ConfirmDeleteModal'
import AddOrganization from './AddOrganization.jsx';
import { Notification } from '../../utils/Misc.jsx';
import Organization from './Organization.jsx';

const Organizations = ({ user }) => {
	const dispatch = useDispatch()
	const state = useSelector(state => state.organizations);
	const [deleteOrganizationModal, setDeleteOrganizationModal] = useState(false);
	const [currentOrganization, setCurrentOrganization] = useState(null);

	const isActiveItem = (organization_id) => {
		if(!currentOrganization) return false;
		return organization_id === currentOrganization.id
	}
	const initDeleteOrganization = (e, organization_id) => {
		e.stopPropagation();
		setDeleteOrganizationModal(organization_id);
	}
	const submitDeleteOrganization = (organization_id) => {
		deleteOrganization(organization_id)
		.then((data) => {
			dispatch(storeDeleteOrganization(organization_id))
		})
		.catch((e) => {
			console.error(e)
			Notification('An error occured. The organization could not be deleted!', 'error')
		} )
		.finally(() => setDeleteOrganizationModal(false))
	}
	
	useEffect(() => {
		dispatch(storeClearOrganizations())
		getOrganizations(user.uid)
		.then((data) => {
			if(data){
				data.forEach( organization => {
					dispatch(storeAddOrganization(organization))
				})
				setCurrentOrganization(data[0])
			}
		})
	}, [])

	return (
		<div>
			<div className='animate__animated animate__fadeIn'>
					<div className="mtop--20 flex flex--row">
							<div className="profile__sidebar profile__sidebar-accounts">
								<AddOrganization user={user} />
								<hr />
								{ state.organizations.map((organization, idx) => {
									return (
										<div key={`organization__${idx}`}>
											<div className={`profile__sidebar-item ${ isActiveItem(organization.id) && 'profile__sidebar-itemActive'}`} onClick={() => setCurrentOrganization(organization)}>
												<span className="material-icons font--20 vm--align mright--10"> business </span>
												{organization?.data?.name}
												<div className="profile__sidebar-itemDelete" onClick={ e => initDeleteOrganization(e, organization.id) }>
													<span className="material-icons text--danger">clear</span>
												</div>
											</div>
										</div>
									)
								})}
							</div>
						<div className="profile__content">
						{currentOrganization && <Organization organization={currentOrganization} setCurrentOrganization={setCurrentOrganization} /> }
						</div>
					</div>
			</div>
			{deleteOrganizationModal && <ConfirmDelete closeCb={setDeleteOrganizationModal} confirmCb={submitDeleteOrganization} confirmData={deleteOrganizationModal} text={'Are you sure you want to delete this organization? The organization should have no campaigns associated with it!'} />}
		</div>
	)
}

export default Organizations