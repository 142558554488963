import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import "../../../scss/pages/Login.scss";
import logo from '../../../images/logo__white.png';
import ThemeSwitch from "../../utils/ThemeSwitch";
import { loginWithCustomToken, logout, base64UrlDecode } from '../../../firebaseActions.mjs';

function AdminAuth() {
	const user = useSelector(state => state.user.user);
	const { token } = useParams();
	useEffect(() => {
		if(user){
			const tmp = JSON.parse(base64UrlDecode(token.split('.')[1], 'utf-8'));
			if(user.uid === tmp.uid){
				window.location.href = '/';
				return;
			}
			logout();
		}
		loginWithCustomToken(token)
		.then((data) => {
			console.log(data);
			window.location.href = '/';
		})

	}, [token])
	return (
		<div className="login">
			<div className="login__content">
				<div className="login__body">
					<div className="flex flex--row justify--center">
						<img src={logo} width="250" />
						<ThemeSwitch />
					</div>
					<div className="text--center mtop--20">
						<h2 className="">Admin Login</h2>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminAuth;
