import {useSelector} from 'react-redux';
import ClearAnswers from "./ClearAnswers";
import { getPageElements } from './PageElements';

function CampaignLocked(props) {
	const { currentCampaign, currentCampaignId, currentElement} = useSelector(state => state.user);
	const {locked} = currentCampaign;
	const showLocked = () => {
		const pageElement = getPageElements(currentElement?.type);
		const allowedOnLocked = pageElement ? pageElement?.allowedOnLocked : false;
		return locked ? !allowedOnLocked : false;
	}
	return (
		<>
			{ showLocked() ?
				<div className="campaign__locked">
					{locked &&
						<div className="locked text--center">
							<span className="material-icons font--40 text--warning">
								lock
							</span>
							<div className='font--16 text--bold'>Editing is disabled because the campaign is locked</div>
							<div className='font--14'>The campaign is automatically locked if you have at least one answer. <br /> We do this in order to prevent mistakes in the results page</div>
							<div className='font--14 mtop--20'>You can edit the campaign again if you <b className="text--danger">clear the answers at your own risk</b></div>
							<div>
								<ClearAnswers currentCampaign={currentCampaign} currentCampaignId={currentCampaignId} />
							</div>
						</div>
					}
					<div style={{ pointerEvents: locked ? 'none' : '' }}>
						{props.children}
					</div>
				</div>
				:
				<div>
					{props.children}
				</div>
			}
			
		</>
	)
}

export default CampaignLocked
