import React, {useState, useEffect, useCallback, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Organizations from './Organizations';
import IncomingRoleRequests from './IncomingRoleRequests'
import RequestsModal from './RequestsModal';
import '../../../scss/pages/Profile.scss'
import { storeAddIncomingRoleRequest, storeClearIncomingRoleRequests, storeEditIncomingRoleRequest } from '../../../actions/OrganizationsActions'
import { getIncomingRequestsLive, logout} from '../../../firebaseActions.mjs'
import Dropdown from '../../utils/Dropdown'

const Profile = ({user}) => {
	const dispatch = useDispatch()
	const state = useSelector(state => state.organizations);
	const [showProfile, setShowProfile] = useState(false)
	const [requestsModal, setRequestsModal] = useState(false)
	const [currentPage, setCurrentPage] = useState()
	const [displayName, setDisplayName] = useState('')
	const [notificationsCount, setNotificationsCount] = useState(0)
	const requestListener = useRef();

	const goTo = (target) => {
		setShowProfile(true)
		setCurrentPage(target);
	}
	useEffect(() => {
		if(state.incomingRequests.length > 0){
			const a = 0
			const sumWithInitial = state.incomingRequests.reduce(
				(accumulator, request) => {
					if(request.status === 'pending') accumulator += 1
					return accumulator
				},
				a
			);
			setNotificationsCount(sumWithInitial)
			const hasSeenRequestsModal = localStorage.getItem('sportovivo_hasSeenRequestsModal')
			if(!hasSeenRequestsModal && (sumWithInitial > 0)){
				setRequestsModal(true)
			}
			localStorage.removeItem('sportovivo_hasSeenRequestsModal')
		}
	}, [state.incomingRequests])
	useEffect(() => {
		const name = user.displayName || user.email
		
		const firstTwoChars = name?.substring(0, 2)
		if (firstTwoChars) setDisplayName(firstTwoChars.toUpperCase())

		if(!requestListener.current){
			dispatch(storeClearIncomingRoleRequests())
			console.log('Initializing requests listener');
			requestListener.current = getIncomingRequestsLive(user.uid, (id, data) => {
				const o = {
					...{ id: id },
					...data
				}
				dispatch(storeAddIncomingRoleRequest(o))
			}, (id, data) => {
				const f = state.incomingRequests.find(r => r.id === id)
				if (f){
					dispatch(storeEditIncomingRoleRequest(f))
				}
			}, (id) => {
				
			})
		}
	}, [dispatch, requestListener, state.incomingRequests, user])
	return (
		<div className="profile">
			<div className="profile__actions">
				<div>
					<Dropdown isRight={true}>
						<div className="profile__icon dropdown-trigger">
							{displayName}
							<div className="profile__icon-open material-icons">expand_more</div>
							{notificationsCount > 0 ?
								<div className="profile__icon-notification">
									{notificationsCount}
								</div>
								: ''
							}
						</div>
						<div className="dropdown__menu">
							<div className="dropdown__content">
								<div className="font--11 text--muted mleft--10 mbottom--10">
									GENERAL
								</div>
								<div className='dropdown__item pleft--10' onClick={() => goTo('profile')}>
									<span className="material-icons font--20 vm--align mright--10">
										account_circle
									</span>
									Profile
									<div className="text--muted font--11 mleft--30">{user.email}</div>
								</div>
								<div className="font--11 text--muted mleft--10 mbottom--10">
									USER MANAGEMENT
								</div>
								<div className='dropdown__item pleft--10' onClick={() => goTo('organizations')}>
									<span className="material-icons font--20 vm--align mright--10">
										corporate_fare
									</span>
									Organizations
								</div>
								<div className='dropdown__item pleft--10' onClick={() => goTo('incomingRoleRequests')}>
									<span className="material-icons font--20 vm--align mright--10">
										call_received
									</span>
									Received Invitations
									{notificationsCount > 0 &&
										<div className="profile__icon-notification profile__icon-notificationRelative">
											{notificationsCount}
										</div>
									} 
								</div>
								<div className="dropdown__divider"></div>
								<div className='dropdown__item' onClick={() => {
									logout()
									window.location.reload()
								}}>
									<span className="material-icons font--20 vm--align mright--10 text--warning">
										logout
									</span>
									Logout
								</div>
							</div>
						</div>
					</Dropdown>
				</div>
			</div>
			
			{ showProfile ?
				<div className="modal-overlay active">
					<div className="modal active animate__animated fadeIn modal--full">
						<div className="modal__header">
							<div className="modal__header-title">
							</div>
							<div className="close-modal modal--x" onClick={() => { setShowProfile(false) }}>
								<svg viewBox="0 0 20 20">
									<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
								</svg>
							</div>
						</div>
						<div className="modal-content">
							<div className="modal__body profile__modal">
								<div className="profile__sidebar">
									<div className="text--muted font--12">
										<span className='material-icons'>
											account_circle
										</span>
										&nbsp;
										{user.email}
									</div>
									<hr />
									<div className="text--muted font--12 mtop--20">USER MANAGEMENT</div>
									<div className={`profile__sidebar-item ${currentPage === 'organizations' && 'profile__sidebar-itemActive'}`} onClick={() => setCurrentPage('organizations')}>Organizations</div>
									<div className={`profile__sidebar-item ${currentPage === 'incomingRoleRequests' && 'profile__sidebar-itemActive'}`} onClick={() => setCurrentPage('incomingRoleRequests')}>
										{ notificationsCount > 0 && 
											<div className="profile__icon-notification">
												{notificationsCount}
											</div>
										} 
										Received invitations
									</div>
									<div className="text--muted font--12">GENERAL</div>
									<div className={`profile__sidebar-item ${currentPage === 'profile' && 'profile__sidebar-itemActive'}`} onClick={() => setCurrentPage('profile')}>Profile</div>
								</div>
								<div className="profile__content">
									{currentPage === 'profile' && <div className='text--center font--25 mtop--25'>Coming soon...</div>}
									{currentPage === 'organizations' && <Organizations user={user} />}
									{currentPage === 'incomingRoleRequests' && <IncomingRoleRequests user={user} />}
									{/* {currentPage == 'outgoingRoleRequests' && <OutgoingRoleRequests user={user} />} */}
									
								</div>
							</div>
						</div>
					</div>
				</div>
				: ''
			}
			{requestsModal &&
				<RequestsModal user={user} closeCb={setRequestsModal} />
			}
		</div>

	)
}

export default Profile