import { useState } from "react";
import {useSelector} from 'react-redux';
import WheelOfPrizesGeneral from "./WheelOfPrizes/WheelOfPrizesGeneral";
import WheelOfPrizesLook from "./WheelOfPrizes/WheelOfPrizesLook";
import '../../../../scss/pages/Wheel.scss';
import Lottie from "react-lottie-player";
import duplicate from '../../../../animations/duplicate.json';

/* Fields have to become options */
function WheelOfPrizesElementOptions({isPreview}) {
	const [tab, setTab] = useState('general');
	const {currentCampaign, currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const {locked} = currentCampaign;

	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	//currentCampaignId, currentPage, currentElement, elementCopy, setElementCopy
	// Calendars on prizes -> Only if limited prizes?
	// 
	return (
		<div className="wheel__cms">
			{isPreview && 
				<div className="text--center">
					<Lottie
						loop
						animationData={duplicate}
						play
						style={{ width: 300, height: 300, margin: 'auto' }}
					/>
					<h3>This element is not editable. It's a fake spin.</h3>
					<div className="text--muted font--14">
						Changes on this elements are a reflection of the main wheel element.
					</div>
				</div>
			}
			{!isPreview &&
				<>
					<div className='tabs'>
						<div className={`tabs__item ${tab === 'general' && 'tabs__item-active'}`} onClick={() => setTab('general')}>
							General
						</div>
						<div className={`tabs__item ${tab === 'look' && 'tabs__item-active'}`} onClick={() => setTab('look')}>
							Look & Feel
						</div>
					</div>
					<div className="hr" />
					{tab === 'general' && <WheelOfPrizesGeneral
						currentCampaignId={currentCampaignId}
						currentPage={currentPage}
						currentElement={currentElement}
						elementCopy={elementCopy}
						setElementCopy={setElementCopy}
						locked={locked}
					/>}
					{tab === 'look' && <WheelOfPrizesLook
						currentCampaignId={currentCampaignId}
						currentPage={currentPage}
						currentElement={currentElement}
						elementCopy={elementCopy}
						setElementCopy={setElementCopy}
					/>}
				</>
			}
		</div>
	);
}

export default WheelOfPrizesElementOptions;
