import { useState } from "react";
import { useSelector} from 'react-redux';

import SoccerLineUpSelectorCategories from "./SoccerLineUpSelector/SoccerLineUpSelectorCategory";
import SoccerLineUpSelectorPlayers from "./SoccerLineUpSelector/SoccerLineUpSelectorPlayers";
import SoccerLineUpSelectorStyling from "./SoccerLineUpSelector/SoccerLineUpSelectorStyling";

function SoccerLineUpSelectorElementOptions() {
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const [currentTab, setCurrentTab] = useState('categories')
	return (
		<div>
			<div className='tabs'>
				<div className={`tabs__item ${currentTab === 'categories' && 'tabs__item-active'}`} onClick={() => setCurrentTab('categories')}>
					<span className="material-icons font--20 vm--align mright--10">
						category
					</span>
					Categories
				</div>
				<div className={`tabs__item ${currentTab === 'players' && 'tabs__item-active'}`} onClick={() => setCurrentTab('players')}>
					<span className="material-icons font--20 vm--align mright--10">
						sports_martial_arts
					</span>
					Players
				</div>
				<div className={`tabs__item ${currentTab === 'styling' && 'tabs__item-active'}`} onClick={() => setCurrentTab('styling')}>
					<span className="material-icons font--20 vm--align mright--10">
						palette
					</span>
					Styling
				</div>
			</div>
			{ currentTab === 'categories' &&
				<SoccerLineUpSelectorCategories 
					currentCampaignId={currentCampaignId}
					currentPage={currentPage}
					currentElement={currentElement}
					elementCopy={elementCopy}
					setElementCopy={setElementCopy}
				/>
			}
			{ currentTab === 'players' &&
				<SoccerLineUpSelectorPlayers
					currentCampaignId={currentCampaignId}
					currentPage={currentPage}
					currentElement={currentElement}
					elementCopy={elementCopy}
					setElementCopy={setElementCopy}
				/>
			}
			{ currentTab === 'styling' &&
				<SoccerLineUpSelectorStyling
					currentCampaignId={currentCampaignId}
					currentPage={currentPage}
					currentElement={currentElement}
					elementCopy={elementCopy}
					setElementCopy={setElementCopy}
				/>
			}
		</div>
	);
}

export default SoccerLineUpSelectorElementOptions;
