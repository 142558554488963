import React, { useState, useEffect, useRef } from 'react';
import ReactCrop, {
	centerCrop,
	makeAspectCrop,
	Crop,
	PixelCrop,
	convertToPixelCrop,
} from 'react-image-crop';

function centerAspectCrop(mediaWidth, mediaHeight, aspect, width) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: width,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	)
}

function CropperModal({ closeCb, confirmCb, fileData }) {

	const [crop, setCrop] = useState();
	const [completedCrop, setCompletedCrop] = useState();
	const cropperImageRef = useRef()

	useEffect(() => {
		console.log("fileData", fileData)
		setCrop(centerAspectCrop(fileData.width, fileData.height, fileData.aspectRatio || 1.2, fileData.aspectRatio ? 100 : 80))

	}, [fileData])


	const confirmCrop = async () => {
		let finalWidth = fileData.width
		let finalHeight = fileData.height

		console.log("crop.width", crop.width)
		console.log("crop.height", crop.height)

		const calcCropAspect = crop.width * fileData.width / (crop.height * fileData.height)
		if (calcCropAspect < 1 && fileData.height > 1400) {
			finalHeight = 1400
		}
		else if (calcCropAspect > 1 && fileData.width > 1400) {
			finalWidth = 1400
		}
		finalWidth = finalHeight * calcCropAspect
		finalHeight = finalWidth / calcCropAspect

		const finalCrop = convertToPixelCrop(crop, fileData.width, fileData.height)
		const offscreen = new OffscreenCanvas(
			finalWidth,
			finalHeight,
		)
		const ctx = offscreen.getContext('2d')
		if (!ctx) {
			closeCb()
			return
		}

		ctx.drawImage(
			cropperImageRef.current,
			finalCrop.x,
			finalCrop.y,
			finalCrop.width,
			finalCrop.height,
			0,
			0,
			offscreen.width,
			offscreen.height,
		)
		// You might want { type: "image/jpeg", quality: <0 to 1> } to
		// reduce image size
		let outSettings = {
			type: 'image/png'
		}
		if (fileData.suffix === 'jpg' || fileData.suffix === 'jpeg') {
			outSettings = {
				type: 'image/jpeg',
				quality: 0.9
			}
		}
		const blob = await offscreen.convertToBlob(outSettings)

		const croppedImageFile = new File(
			[blob],
			"croppedImage." + fileData.suffix,
			{
				type: outSettings.type,
				lastModified: new Date()
			}
		);

		confirmCb(croppedImageFile)
	}

	return (
		<div className="modal-overlay active">
			<div className="modal active animate__animated fadeIn modal--sm" style={{width: 'unset', maxWidth: '600px', maxHeight: '78vh'}}>
				<div className="modal__header">
					<div className="modal__header-title">
					</div>
					<div className="close-modal modal--x" onClick={() => closeCb(false)}>
						<svg viewBox="0 0 20 20">
							<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
						</svg>
					</div>
				</div>
				<div className="modal-content">
					<div className="modal__body text--center">
						<ReactCrop
							crop={crop}
							onChange={(_, percentCrop) => { console.log("percentCrop", percentCrop); setCrop(percentCrop) }}
							onComplete={(c) => { console.log("completed crop", c); setCompletedCrop(c) }}
							aspect={fileData.aspectRatio}
							keepSelection={true}
						>
							<img ref={cropperImageRef} src={fileData.dataURL}/>
						</ReactCrop>
					</div>
					<div className="modal__footer text--right ">
						<button className="btn btn-secondary" onClick={closeCb}>Cancel</button>
						<button className="btn btn-danger" onClick={confirmCrop}>Confirm</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CropperModal