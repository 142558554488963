import { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import ColorPallete from "../../../utils/ColorPallete";
import RichText from "../../../utils/RichText";
import EditableInput from "../../../utils/EditableInput";
import { genUUID, Notification } from "../../../utils/Misc";
import {updateElement} from "../../../utils/UpdateFunctions";
import { editElement } from "../../../../actions/UserActions";
import dropdownChoiceImage from '../../../../images/dropdown__choice.png';
import buttonChoiceImage from '../../../../images/button__choice.png';
import multipleChoiceImage from '../../../../images/multiple__choice.png';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import '../../../../scss/pages/Poll.scss';

function PollElementOptions() {
	const dispatch = useDispatch();
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const {background, question, marginTop, textColor, options, min, max, layout} = elementCopy;
	const [dragLoader, setDragLoader] = useState(false);
	const update = (key, value) => {
		if(key == 'marginTop') value += 'px';
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const updateOption = (key, value, extra) => {
		for (let i = 0; i < options.length; i++) {
			if(options[i]['id'] == extra.option_id){
				elementCopy.options[i][key]  = value;
			}
		}
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const addOption = (data) => {
		const d = {
			'id': genUUID(),
			'text': 'New Option'
		}
		elementCopy.options.push(d);
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const deleteOption = option_id => {
		if(elementCopy.options.length == 1 ){
			Notification('There should be at least one option in the poll')
			return
		}
		const o = elementCopy.options.filter( current => current.id !== option_id )
		elementCopy.options = o;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const onSortEnd = (data) => {
		setDragLoader(true);
		const toBeMoved = options[data.oldIndex];
		let filteredOptions = options.filter((option) => option.id != toBeMoved.id)
		filteredOptions.splice(data.newIndex, 0, toBeMoved);
		elementCopy['options'] = filteredOptions;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
		setTimeout(() => {
			setDragLoader(false);
		}, 1000)
	}

	const DragHandle = SortableHandle(() => <span className="material-icons font--25 item pointer mtop--10">drag_indicator</span>);
	const SortableItem = SortableElement(({ option }) => (
		<li style={{ width: '100%' }}>
			<div className="poll__option">
				<DragHandle />
				<EditableInput extra={{ option_id: option.id }} item="text" type="text" startValue={option.text} updateAction={updateOption} placeholder={'Type here...'} />
				<span className="poll__option-delete">
					<span className={"material-icons font--25 pointer " + (option.allowComment?"text--success":"text--muted")} onClick={(e)=>updateOption("allowComment", !option.allowComment, {option_id: option.id})} title="Allows comments">comment</span>
					<span className="material-icons font--25 pointer text--danger" onClick={() => deleteOption(option.id)}>
						clear
					</span>
				</span>
			</div>
		</li>
	));
	const SortableList = SortableContainer(({ items }) => {
		return (
			<ul className="sortable__container sortable__inline">
				{
					items.map((value, index) => (
						<SortableItem key={`pollOption_${index}`} i={index} index={index} option={value} />
					))
				}
			</ul>
		);
	});
	return (
		<div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Question</label>
				<RichText item={'question'} value={question} updateAction={update} />
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Options</label>
				<div className="mtop--15">
					{dragLoader && <div className="page__selector-loader text--center"><span className="loader"></span></div>}
					{!dragLoader && <SortableList axis="y" useDragHandle items={options} onSortEnd={onSortEnd} />}
					<button className="btn btn-primary-soft btn-100 mtop--10" onClick={() => addOption()}>
						<span className="material-icons font--20 vm--align">add</span>
						Add Option
					</button>
				</div>
			</div>
			<div className="flex flex--row mtop--20">
				<div className="flex--50">
					<label className="text--bold">Minimum Selections</label>
					<EditableInput type="number" max={max} item="min" startValue={min} updateAction={update} />
				</div>
				<div className="flex--50 mleft--15">
					<label className="text--bold">Maximum Selections</label>
					<EditableInput type="number" max={options.length} item="max" startValue={max} updateAction={update} />
				</div>
			</div>
			<div className="campaign__option mtop--20">
				<label className="text--bold">Answers layout</label>
				<div className="flex flex--row flex--wrap">
					<div className={`card__check-option ${layout == 'dropdown' && 'card__check-optionActive'}`} onClick={() => update('layout', 'dropdown')}>
						<div className="card__check-radio">
							<span className="material-icons">
								done
							</span>
						</div>
						<img src={dropdownChoiceImage} className="img__responsive"/>
						Dropdown
					</div>
					<div className={`card__check-option ${layout == 'radio' && 'card__check-optionActive'}`} onClick={() => update('layout', 'radio')}>
						<div className="card__check-radio">
							<span className="material-icons">
								done
							</span>
						</div>
						<img src={multipleChoiceImage} className="img__responsive"/>
						Radio Buttons
					</div>
					<div className={`card__check-option ${layout == 'button' && 'card__check-optionActive'}`} onClick={() => update('layout', 'button')}>
						<div className="card__check-radio">
							<span className="material-icons">
								done
							</span>
						</div>
						<img src={buttonChoiceImage} className="img__responsive" width={240} />
						Buttons
					</div>
				</div>

			</div>
			<div className="campaign__option mtop--20">
				<label className="text--bold">Top Margin (pixels)</label>
				<div className="font--12 text--muted">The space the element has from the top</div>
				<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
			</div>
			<div className="flex flex--row flex--wrap mtop--20">
				<div className="campaign__option campaign__option-50">
					<label className="text--bold">Text color</label>
					<div className="campaign__option-act">
						<ColorPallete item="textColor" value={textColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="campaign__option campaign__option-50 mleft--5">
					<label className="text--bold">Background color</label>
					<div className="campaign__option-act">
						<ColorPallete item="background" value={background} type={'rgba'} updateAction={update} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default PollElementOptions;
