import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { getPollResults } from './ResultsCreator';
import DetailedResultsTable from "./DetailedResultsTable";

function PollResults() {
	const { resultsCurrentElement, unMappedUserResults } = useSelector(state => state.user);
	const [mappedResults, setMappedResults] = useState([])
	const [mappedDetailedResults, setMappedDetailedResults] = useState([])
	const [view, setView] = useState('general')
	const [onlyValidAnswers, setOnlyValidAnswers] = useState(false);
	let comments = [];
	for(let i=0;i<mappedResults.length;i++){
		const answers = mappedResults[i].answers;
		for(let j=0;j<answers.length;j++){
			if(answers[j].comment) comments.push(answers[j].comment);
		}
	}

	const mapResults = async () => {
		const [generalResults, detailedResults] = await getPollResults(unMappedUserResults, resultsCurrentElement, onlyValidAnswers)
		setMappedResults(generalResults)
		setMappedDetailedResults(detailedResults)
	}
	const totalVotes = () => {
		return mappedResults.reduce((acc, res) => acc + res.answers.length, 0)
	}
	useEffect(() => {
		if (unMappedUserResults) mapResults();
	}, [unMappedUserResults, onlyValidAnswers])
	return (
		<div className="poll__results">
			<div className="flex flex--row">
				<div className='tabs'>
					<div className={`tabs__item ${view == 'general' ? 'tabs__item-active' : ''}`} onClick={() => setView('general')}>
						<span className="material-icons font--25 vm--align">
							donut_small
						</span>
						General Stats
					</div>
					<div className={`tabs__item ${view == 'detailed' ? 'tabs__item-active' : ''}`} onClick={() => setView('detailed')}>
						<span className="material-icons font--25 vm--align">
							table_chart
						</span>
						Detailed Stats
					</div>
				</div>
			</div>
			{view === 'general' &&
				<div className="poll__results-container mtop--30">
					<h1 className="mtop--20">{resultsCurrentElement.title}</h1>
					<div className="poll__results-totalVotes">
						<span className="material-icons">
							how_to_vote
						</span>
						<span className='vm--align mtop--5 animate__animated animate__tada'>
							{totalVotes()}
							<div className="font--15 text--muted" style={{marginTop: '-15px'}}>
								Votes
							</div>
						</span>

					</div>
					<div className="poll__results-containerInner">
						{mappedResults.map((option, index) => {
							return (
								<div key={`option__${index}`} className="poll__result">
									<div className="poll__result-text">
										{option.text}
									</div>
									<div className="bar__chart">
										<div className="bar__chart-bar" style={{ width: option.percentage + '%' }}>
											<span className="bar__chart-percentage">{option.percentage + '%'}</span>
										</div>
									</div>
									<div className="bar__chart-pollVotes text--muted float--right">
										{option.answers.length} {option.answers.length == 1 ? 'Vote' : 'Votes'}
									</div>
								</div>
							)
						})}
					</div>
					{(comments.length||null) && <div>
						<h1 className="mtop--20">Comments</h1>
						<ul className="poll__results-containerInner">
							{comments.map((comment, index) => {
								return <li key={index}>{comment}</li>;
							})}
						</ul>
					</div>}
				</div>
			}
			{view === 'detailed' &&
				<>
					<div className="flex flex--row">
						<div>
							{/* <input type="text" placeholder="Search by name, email..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/> */}
						</div>
						<div className="float--right flex flex--row">
							<Tooltip title="This options works only if you have a registration element. Verified answers are the ones that have at least a name or a surname" position="bottom">
								<div className="md__input mtop--10 mright--10">
									<input type="checkbox" className="switch" checked={onlyValidAnswers} onChange={(e) => setOnlyValidAnswers(e.target.checked)} />
									<label>Only verified answers</label>
								</div>
							</Tooltip>
						</div>
					</div>
					<div className="mtop--20">
						<DetailedResultsTable total={mappedResults.length} header={resultsCurrentElement.tableHeader} rows={mappedDetailedResults} />
					</div>
				</>
			}
		</div>
	);
}

export default PollResults;
