import { useState, useEffect } from "react";
import { sendSMSLoginCode, verifyPhoneCodeAndLogin } from "../../../firebaseActions.mjs";
import twofaJson from '../../../animations/2fa.json';
import robotJson from '../../../animations/robot.json';
import Lottie from "react-lottie-player";
const RECAPTCHA_CONTAINER_ID = "8c7cef44-044e-4a18-bb78-c51f4c50deea";

function TwoFactorModal({ resolver }) {
	const [phoneCode, setPhoneCode] = useState("");
	const [verificationId, setVerificationId] = useState("");
	const [error, setError] = useState(null);
	const [step, setStep] = useState(0);
	useEffect(() => {
		sendSMSLoginCode(resolver, RECAPTCHA_CONTAINER_ID, () => {
			setTimeout(() => {
				setStep(1);	
			}, 2000);
		})
		.then(function (vId) {
			setVerificationId(vId);
		})
	}, [resolver]);

	function submitCode(e) {
		e.preventDefault();
		verifyPhoneCodeAndLogin(resolver, verificationId, phoneCode)
			.then(function () {
				window.location.reload();
			})
			.catch((error) => {
				if (error.code === "auth/invalid-verification-code") {
					setError('Invalid code. Please try again with the correct one.')
				}else{
					setError('An unexpected error occured. Please try again later.')
				}
			})
	}
	return (
		<div className="modal-overlay active">
			<div className="modal active animate__animated animate__fadeIn">
				<div className="modal__header">
					<div className="modal__header-title">
						<h2>2FA Authentication</h2>
						<div className="text--muted font--12">Enter the 6 digit code we sent you</div>
					</div>
				</div>
				<div className="modal-content">
					<div className="modal__body">
						{ step === 0 &&
							<div className="text--center">
								<Lottie
									loop
									animationData={robotJson}
									play
									style={{ width: 200, height: 200, margin: 'auto' }}
								/>
								<h4>Verifying you are not a robot.</h4>
								<div className="mtop--10" id={RECAPTCHA_CONTAINER_ID}></div>
							</div>
						}
						{ step === 1 && 
							<div className="card card__bordered">
								<Lottie
									loop
									animationData={twofaJson}
									play
									style={{ width: 200, height: 200, margin: 'auto' }}
								/>
								<div className="text--center">
									<h4>Fill in the code we sent you on this phone number: {resolver?.hints?.[0]?.phoneNumber}</h4>
									<form onSubmit={submitCode}>
										<input type="text" className="twofa__input" onChange={(e) => { setPhoneCode(e.target.value); }} value={phoneCode} required placeholder="######" />
										{error && <div className="text--danger">{error}</div>}
										<button type="submit" disabled={phoneCode.length !== 6} className="btn btn-primary btn-100 mtop--20">Submit Code</button>
									</form>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	);
}
export default TwoFactorModal;
