import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { getSoccerLineUpResults } from './ResultsCreator';
import "../../../../scss/pages/SoccerLineUpResults.scss";


function SoccerLineupSelectorResults() {
	const { resultsCurrentElement, unMappedUserResults } = useSelector(state => state.user);
	const [view, setView] = useState('general')
	const [totalVotes, setTotalVotes] = useState(0)
	const [onlyValidAnswers, setOnlyValidAnswers] = useState(false);
	const [mappedResults, setMappedResults] = useState(null)
	const [mappedDetailedResults, setMappedDetailedResults] = useState([])
	const readableTitles = {
		'ext': 'Centre Forwards',
		'fwd': 'Forwards',
		'mid': 'Midfielders',
		'def': 'Defenders',
		'goal': 'Goalkeeper'
	}

	const mapResults = async () => {
		const results = await getSoccerLineUpResults(unMappedUserResults, resultsCurrentElement, onlyValidAnswers)
		const [generalResults, detailedResults, totalVotes] = results;
		setMappedResults(generalResults)
		setMappedDetailedResults(detailedResults);
		setTotalVotes(totalVotes)
	}
	const getMostVotedPlayer = (position, index) => {
		if(!mappedResults) return false;
		const {mostVotedPlayer} = mappedResults[position][index];
		if(!mostVotedPlayer) return;
		return (
			<div>
				{mostVotedPlayer.data.image && <img src={mostVotedPlayer.data.image} alt='player' /> }
				<div className="font--12 field__player-name">
					{mostVotedPlayer.data.name}
				</div>
				<div className="percentage">
					{mostVotedPlayer.percentage.toFixed(1)}% ({mostVotedPlayer.absolute})
				</div>
				<div className="lineup__bar" style={{height: `${mostVotedPlayer.percentage}%`}}>
				</div>
            </div>
		)
	}
	const getPosition = (position, index) => {
		let arr = resultsCurrentElement.generalReportPlaceholder.formation[position];
		let o = <div key={`${position}_${index}`} className="formation__grid-column"></div>;
		
		if (arr) {
			arr.forEach(p => {
				if (p === index) {
					o = <div className="formation__grid-column lineup__player">
						{getMostVotedPlayer(position, index)}
					</div>
				}
			})
		}
		return o;
	}
	const generateLineUp = (position) => {
		return (
			<div className="formation__grid-row">
				<div className="formation__grid-rowSub">
					{
						(() => {
							const rows = [];
							for (let i = 0; i < 5; i++) {
								rows.push(getPosition(position, i));
							}
							return rows;
						})()
					}
				</div>
				<div className="formation__grid-rowSub">
					{
						(() => {
							const rows = [];
							for (let i = 5; i < 10; i++) {
								rows.push(getPosition(position, i));
							}
							return rows;
						})()
					}
				</div>
			</div>
		)
	}
	const generateGroupResults = (position) => { //position = ext, mide, def, goal etc
		if (!mappedResults) return '';
		const players = []
		Object.values(mappedResults[position]).map((entry) => {
			return Object.values(entry.votes).map(vote => {
				players.push(vote)
			})
		})

		const shirts = Array(10).fill(false);
		Object.keys(mappedResults[position]).forEach( (pos) => {
			shirts[parseInt(pos)] = true;
		})
		return (
			<div>
				<h3>{readableTitles[position]}</h3>
				<div className="mtop--20 soccer__lineup">
					{ Object.entries(mappedResults[position]).map( ([pos, entry], index) => (
						<>
							<div style={{marginLeft: '100px'}}>
								<div className="soccer__lineup-position">
										{ shirts && shirts.map( (isOn, idx) => (
											<span>
												{ isOn &&
													<span className={"material-symbols-outlined font--25 vm--align" + (idx == parseInt(pos)? " text--success": " text--muted")}>
														apparel
													</span>
												}
												{ !isOn &&
													<span style={{color: 'lightgray'}} className="hello">.</span>
												}
											</span>
										))}
								</div>
							</div>
							{ Object.values(entry.votes).sort((a, b) => {return b.absolute - a.absolute}).map((player, idx) => {
								const {data, percentage, absolute} = player;
								console.log("data, percentage, absolute", data, percentage, absolute)
								return ( 
									<div className="soccer__player" key={`soccer__${idx}`}>
										<div className="soccer__player-side">
											{ data.image && <img src={data.image} className="img-responsive" style={{ width: '80px' }} /> }
										
										</div>
										<div className="bar__chart">
											<div className="bar__chart-bar" style={{ width: percentage + '%' }}>
												<span className="bar__chart-percentage">{percentage.toFixed(1) + '%'} ({absolute})</span>
											</div>
											<div className="soccer__player-name">
												{data.name}
											</div>
										</div>
									</div>
								)
							})}
							<div style={{marginTop: '50px'}}></div>
						</>
					))}
				</div>
			</div>
		)
	}
	useEffect(() => {
		if (unMappedUserResults) mapResults();
	}, [unMappedUserResults, onlyValidAnswers])
	return (
		<div className="soccer">
			<div className="flex flex--row">
				<div className='tabs'>
					<div className={`tabs__item ${view == 'general' ? 'tabs__item-active' : ''}`} onClick={() => setView('general')}>
						<span className="material-icons font--25 vm--align">
							donut_small
						</span>
						General Stats
					</div>
					<div className={`tabs__item ${view == 'detailed' ? 'tabs__item-active' : ''}`} onClick={() => setView('detailed')}>
						<span className="material-icons font--25 vm--align">
							table_chart
						</span>
						Detailed Stats
					</div>
				</div>
			</div>
			{view === 'general' &&
				<div className="soccer__results-container">
					<h1>{resultsCurrentElement.title}</h1>
					<div className="soccer__results-totalVotes">
						<span className="material-icons">
							how_to_vote
						</span>
						<span className='vm--align mtop--5 animate__animated animate__tada'>
							{totalVotes}
							<div className="font--15 text--muted" style={{ marginTop: '-15px' }}>
								Votes
							</div>
						</span>
					</div>
					<div>
						<div className="js-stage stage texture">
							<div className="js-world world">
								<div className="terrain js-terrain">
									<div className="field ground">
										<div className="formation__grid">
											{generateLineUp('ext')}
											{generateLineUp('fwd')}
											{generateLineUp('mid')}
											{generateLineUp('def')}
											{generateLineUp('goal')}
										</div>
										<div className="field__texture field__texture--gradient"></div>
										<div className="field__texture field__texture--gradient-b"></div>
										<div className="field__texture field__texture--grass"></div>
										<div className="field__line field__line--goal"></div>
										<div className="field__line field__line--goal field__line--goal--far"></div>
										<div className="field__line field__line--outline"></div>
										<div className="field__line field__line--penalty"></div>
										<div className="field__line field__line--penalty-arc"></div>
										<div className="field__line field__line--penalty-arc field__line--penalty-arc--far"></div>
										<div className="field__line field__line--mid"></div>
										<div className="field__line field__line--circle"></div>
										<div className="field__line field__line--penalty field__line--penalty--far"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
			{view === 'detailed' &&
				<>
					<div className="mtop--20">
						{generateGroupResults('ext')}
						{generateGroupResults('fwd')}
						{generateGroupResults('mid')}
						{generateGroupResults('def')}
						{generateGroupResults('goal')}
					</div>
				</>
			}
		</div>
	);
}

export default SoccerLineupSelectorResults;
