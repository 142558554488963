
const initialState = {
	user: null,
	campaigns: [],
	currentCampaign: null,
	currentCampaignId: null,
	currentPage: null,
	currentElement: null,
	preview: "desktop",
	colorPallete: {},
	resultsCurrentElement: null,
	unMappedUserResults: [],
	unMappedUserPosts: {},
	projectors: [],
}
export default function UserReducer(state = initialState, action){
	switch(action.type){
		case 'USER_SET':
			return {
				...state,
				user: action.data
			}
		case 'USER_ADD_CAMPAIGN':
			return {
				...state,
				campaigns: [...state.campaigns, ...[action.data]]
			}
		case 'USER_CLEAR_CAMPAIGNS':
			return {
				...state,
				campaigns: []
			}
		case 'USER_SET_CAMPAIGNS':
			return {
				...state,
				campaigns: [...state.campaigns, ...action.data]
			}
		case 'USER_SET_CURRENT_CAMPAIGN':
			return {
				...state,
				currentCampaign: action.data
			}
		case 'USER_SET_PROJECTORS':
			return {
				...state,
				projectors: action.data
			}
		case 'USER_SET_CURRENT_PAGE':
			return {
				...state,
				currentPage: action.data
			}
		case 'USER_SET_CURRENT_ELEMENT':
			return {
				...state,
				currentElement: action.data
			}
		case 'USER_SET_CURRENT_CAMPAIGNID':
			return {
				...state,
				currentCampaignId: action.data
			}
		case 'USER_EDIT_ELEMENT':
			const expPage = state.currentCampaign.expiredPage;
			if(state.currentPage.type == 'expiredPage'){
				return {
					...state,
					currentCampaign: {...state.currentCampaign, expiredPage: {...expPage, elements: expPage.elements.map((element) => {
						if(action.data.id == element.id){
							return action.data.data;
						}else{
							return element;
						}
					})}},
					currentPage: {...state.currentPage, elements: state.currentPage.elements.map((element) => {
						if(action.data.id == element.id){
							return action.data.data;
						}else{
							return element;
						}
					})}
				}
			}else{
				return {
					...state,
					currentCampaign: {...state.currentCampaign, pages: state.currentCampaign.pages.map((page) => {
						return {...page, elements: page.elements.map((element) => {
							if(action.data.id == element.id){
								return action.data.data;
							}else{
								return element;
							}
						})}
					})},
					currentPage: {...state.currentPage, elements: state.currentPage.elements.map((element) => {
						if(action.data.id == element.id){
							return action.data.data;
						}else{
							return element;
						}
					})}
				}
			}
		case 'USER_ADD_ELEMENT':
			if(state.currentPage.type == 'expiredPage'){
				return {
					...state,
					currentCampaign: {...state.currentCampaign, expiredPage: {...state.currentCampaign.expiredPage, elements: [...state.currentCampaign.expiredPage.elements, ...[action.data]]}},
					currentPage: {...state.currentPage, ...{elements: [...state.currentPage.elements, ...[action.data]]}},
					currentElement: action.data
				}
			}else{
				return {
					...state,
					currentCampaign: {...state.currentCampaign, pages: state.currentCampaign.pages.map((page) => {
						if( page.id == state.currentPage.id){
							return { ...page, elements: [...page.elements, ...[action.data]] }
						}else{
							return page
						}
					})},
					currentPage: {...state.currentPage, ...{elements: [...state.currentPage.elements, ...[action.data]]}},
					currentElement: action.data
				}
			}
		case 'USER_REMOVE_ELEMENT':
			let p = state.currentPage;
			if(p.type == 'expiredPage'){
				return {
					...state,
					currentCampaign: {...state.currentCampaign, expiredPage: {...state.currentCampaign.expiredPage, elements: p.elements.filter( (el) => el.id !== action.data )}},
					currentPage: {...p, ...{elements: p.elements.filter( (el) => el.id !== action.data ) }},
					currentElement: state.currentElement && (action.data == state.currentElement.id ? null : state.currentElement)
				}
			}else{
				let filteredPages = state.currentCampaign.pages.map((page) => {
					if(page.id == state.currentPage.id){
						return {...page, elements: page.elements.filter( (element) => element.id != action.data)}
					}else{
						return page
					}
				})
				return {
					...state,
					currentCampaign: {...state.currentCampaign, pages: filteredPages},
					currentPage: {...p, ...{elements: p.elements.filter( (el) => el.id !== action.data ) }},
					currentElement: state.currentElement && (action.data == state.currentElement.id ? null : state.currentElement)
				}
			}
		case 'USER_SET_CAMPAIGN_PAGES':
			return {
				...state,
				currentCampaign: {...state.currentCampaign, ...{pages: action.data} },
				currentPage: action.data[action.data.length-2]
			}
		case 'USER_REMOVE_PAGE':
			let c = state.currentCampaign;
			return {
				...state,
				currentCampaign: {...c, ...{pages: c.pages.filter( (pg) => pg.id !== action.data ) }},
			}
		case 'USER_REORDER_ELEMENTS':
			if(state.currentPage.type == 'expiredPage'){
				return {
					...state,
					currentCampaign: {...state.currentCampaign, expiredPage: {...state.currentCampaign.expiredPage, elements: action.data}},
					currentPage: {...state.currentPage, ...{elements: action.data }},
				}
			}else{
				let p = state.currentCampaign.pages.map((page) => {
					if(page.id == state.currentPage.id){
						return {...page, elements: action.data}
					}else{
						return page
					}
				})
				return {
					...state,
					currentCampaign: {...state.currentCampaign, pages: p},
					currentPage: {...state.currentPage, ...{elements: action.data }},
				}
			}
		case 'USER_REORDER_PAGES':
			return {
				...state,
				currentCampaign: {...state.currentCampaign, ...{pages: action.data }},
			}
		case 'USER_EDIT_CAMPAIGN':
			return {
				...state,
				currentCampaign: {...state.currentCampaign, ...action.data}
			}
		case 'USER_DELETE_CAMPAIGN':
			return {
				...state,
				campaigns: state.campaigns.filter( (cp) => cp.id !== action.data ),
			}
		case 'USER_SET_COLOR_PALLETE':
			return {
				...state,
				colorPallete: {...state.colorPallete, ...{
					[action.data.campaign_id]: action.data.colors
				}}
			}
		case 'USER_ADD_TO_PALLETE':
			let tmp = state.colorPallete[action.data.campaign_id][action.data.type].push(action.data.color);
			return {
				...state,
				colorPallete: {...state.colorPallete,...tmp	}
			}
		case 'USER_RESULTS_SET_CURRENT_ELEMENT':
			return {
				...state,
				resultsCurrentElement: action.data
			}
		case 'USER_ADD_UNMAPPED_RESULT':
			return {
				...state,
				unMappedUserResults: {...state.unMappedUserResults, [action.data.id]: action.data.data }
			}
		case 'USER_DELETE_UNMAPPED_RESULT':
			const copy = {...state.unMappedUserResults};
			delete copy[action.data.id];
			return {
				...state,
				unMappedUserResults: copy
			}
		case 'USER_ADD_UNMAPPED_POST':
			return {
				...state,
				unMappedUserPosts: {...state.unMappedUserPosts, [action.data.id]: action.data.data }
			}
		case 'USER_DELETE_UNMAPPED_POST':{
			const copy = {...state.unMappedUserPosts};
			delete copy[action.data.id];
			return {
				...state,
				unMappedUserPosts: copy
			}
		}
		case 'USER_SET_CAMPAIGN_LOCKED':
			return {
				...state,
				currentCampaign: {...state.currentCampaign, ...{locked: true}}
			}
		default:
			return state;
	}
}