import { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import EditableInput from "../../../utils/EditableInput";
import {updateElement} from "../../../utils/UpdateFunctions";
import ImageUpload from "../../../utils/ImageUpload";
import { editElement } from "../../../../actions/UserActions";

function ImageElementOptions() {
	const dispatch = useDispatch();
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const {background, size, marginTop, target, url} = elementCopy;
	const update = (key, value) => {
		if(key == 'marginTop') value += 'px';
		if(key == 'size') value += 'px';
		if((key == 'url') && !value) value = null;

		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	};
	const updateBackground = (key, value) => {
		elementCopy.background[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	};
	return (
		<div>
			<div className="campaign__option imageChoice__elementOptions">
				<label className="text--bold">Image</label>
				<ImageUpload campaign_id={currentCampaignId} file={background.url} item="url" updateAction={updateBackground} />
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Size (pixels)</label>
				<EditableInput item="size" type="number" startValue={size.substring(0, size.length - 2)} updateAction={update} placeholder="Type here..." />
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Top Margin (pixels)</label>
				<div className="font--12 text--muted">The space the element has from the top</div>
				<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
			</div>
			{/* <div className="campaign__option mtop--15">
				<label className="text--bold">Background color</label>
				<div className="text--muted font--12">Preferable if you have a background image and the text is not visible</div>
				<div>
					<ColorPallete/>
				</div>
			</div> */}
			<div className="campaign__option mtop--15">
				<label className="text--bold">Link</label>
				<div className="text--muted font--12">Add a link to your image</div>
				<EditableInput item="url" type="text" startValue={url} updateAction={update} placeholder="Type here..." />
				{ url &&
					<div className="mtop--20 mleft--10">
						<label className="text--bold">Open link</label>
						<div className="flex flex--row mtop--10">
							<button className={ target == 'self' ? 'btn btn-primary' : 'btn btn-secondary'} onClick={() => update('target', 'self')}>Same window</button>
							<button className={ target == 'newTab' ? 'btn btn-primary' : 'btn btn-secondary'} onClick={() => update('target', 'newTab')}>New window</button>
						</div>
					</div>
				}

			</div>
		</div>
	);
}

export default ImageElementOptions;
