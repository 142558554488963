import { useState, useEffect } from "react";
import { useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router";
import "../../../scss/pages/Login.scss";
import logo from '../../../images/logo__white.png';
import googleImage from "../../../images/google.png"
import ThemeSwitch from "../../utils/ThemeSwitch";
import { showLogin, on, off, getUser, createAccount, loginWithEmailAndPassword } from '../../../firebaseActions.mjs';
import { setUser } from "../../../actions/UserActions";

function Login({ loadingStatus, setLoadingStatus }) {
	const dispatch = useDispatch();
	const user = useSelector(state => state.user.user);
	const { defaultEmail, signInProvider } = useParams();

	const [email, setEmail] = useState(defaultEmail ? defaultEmail : '');
	const [password, setPassword] = useState('');
	const [error, setError] = useState(null);
	const onLogin = function (user) {
		setError(null)
		dispatch(setUser(user));
		//setLoadingStatus('loggedIn');
		window.location.href = '/';
	};
	const onLogout = function () {
		setLoadingStatus('loggedOut');
	};
	const initEmailAnsPasswordLogin = () => {
		setError(null)
		loginWithEmailAndPassword(email, password)
		.then((data) => {
			console.log(data)
		})
		.catch((e, b) => {
			setError('An error occured. Please check your credentials and make sure you have registered!')
		})
	}
	useEffect(() => {
		on("login", onLogin);
		on("logout", onLogout);
		return () => {
			off("login", onLogin);
			off("logout", onLogout);
		}
	}, []);
	const canViewEmailPassword = () => {
		if(!defaultEmail) return true;
		if(signInProvider === 'google.com') return false
		return true
	}
	return (
		<div className="login">
			<div className="login__content">
				<div className="login__body">
					<div className="flex flex--row justify--center">
						<img src={logo} width="250" />
						<ThemeSwitch />
					</div>
					<div className="text--center mtop--20">
						<h2 className="">Login</h2>
					</div>
					<div className="text--center mtop--10">Welcome back, <br />Log in to continue.</div>
					{ canViewEmailPassword() &&
						<div className="mtop--20">
							<div className="mtop--10">
								<label className="text--bold">Email</label>
								<input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={'Type here...'} disabled={defaultEmail} />
							</div>
							<div className="mtop--10">
								<label className="text--bold">Password</label>
								<input value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Type here..." />
							</div>
							{error &&
								<div className="text--danger mtop--10">
									{error}
								</div>
							}
							<div className="mtop--10 btn btn-primary btn-100" disabled={!email || !password} onClick={initEmailAnsPasswordLogin}>
								Submit
							</div>
							<div className="text--center mtop--10">OR</div>
						</div>
					}
					<div>
						<div className="btn btn-light btn-100 mtop--20" onClick={showLogin} disabled={defaultEmail && (signInProvider !== 'google.com')}>
							<img src={googleImage} width="30" className="mright--20" />
							Login with Google
						</div>
						<div className="mtop--20 text--center" disabled={defaultEmail}>
							Dont have an account? <a href="/registration">Register</a>
						</div>
						<div className="mtop--20 text--center" disabled={defaultEmail}>
							Forgot your password? <a href={`/passwordReset`}>Reset</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
