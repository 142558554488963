import { saveAs } from 'file-saver';
export function rowToCSV(row) {
	const parts = row.reduce((result, value, index) => {
		if (index > 0) result = result + ',';
		if ((value === null) || (value === undefined)) {
			return result + '""';
		} else if (value === false) {
			return result + '"0"';
		} else if (value === true) {
			return result + '"1"';
		} else if (Array.isArray(value)) {
			return result + value.reduce((previous, currentValue) => `${previous} ${stringStrip(currentValue)}`, '')
		} else {
			let s = ''
			switch (typeof value) {
				case 'string':
					s = '"' + stringStrip(value) + '"';
					break;
				case 'number':
					s = '"' + value + '"';
					break;
				default:
					/* Just add an empty cell */
					s = ' '
					break;
			}
			return result + s
		}
	}, '');
	return parts;
}
function stringStrip(string) {
	const r = /[\n\r,]+/g;
	/* Replace all new lines with a space */
	string = string.replaceAll(r, ' ');
	/* Replace all " with a ' */
	string = string.replaceAll('"', "'");
	/* Replace all + with a space or empty string */
	string = string.replace(/^\+/g, '_+');
	return string;
}

export function tableToCSV(rows) {
	const parts = rows.reduce((result, row) => {
		const d = result + rowToCSV(row) + '\n'
		/* Log the row for debugging */
		console.log(d)
		return d;
	}, '');
	return parts;
}
export const saveCSV = async (rows, filename) => {
	filename = filename.replaceAll(/[\\/?*[\]]/g, '_');
	const o = tableToCSV(rows);
	const blob = new Blob([o], { type: "text/csv;charset=utf8" });
	saveAs(blob, filename + ".csv");
}
