import {saveAs} from 'file-saver';
import XLSX from 'sheetjs-style';

export const saveExcel = async (inputData, filename) => {
    filename = filename.replaceAll(/[\\/?*[\]]/g, '_');
    const WS = XLSX.utils.aoa_to_sheet(inputData);
    const wb = { Sheets: { [filename]: WS }, SheetNames: [filename] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
	saveAs(data, filename+".xlsx");
}