import {replacePageElement, modifyCampaign} from '../../firebaseActions.mjs';
import {ErrorNotification} from './Misc';
import {setCampaignLocked} from '../../actions/UserActions';

const elementCacheMap = {};

export const updateCampaignDB = (currentCampaignId, changes, dispatch, dispatchAction) => {
	return modifyCampaign(currentCampaignId, changes)
	.then((data) => {
		dispatch(dispatchAction(changes));
	})
	.catch((e) => {
		ErrorNotification('Could not modify campaign.', e, {});
		console.error(e, {...e});
	})
}
export const updateElement = (currentCampaignId, currentPage, currentElementId, editedElement, originalElement, revertFunc, dispatch, dispatchAction) => {
	return new Promise((resolve, reject) => {
		const pageType = currentPage.type == 'expiredPage' ? 'expiredPage' : 'pages';
		replacePageElement(currentCampaignId, pageType, currentPage.id, currentElementId, editedElement)
		.then((data) => {
			elementCacheMap[currentElementId] = JSON.parse(JSON.stringify(editedElement));
			dispatch(dispatchAction(editedElement, currentElementId));
			resolve();
		})
		.catch((e) => {
			ErrorNotification('Could not modify element.', e, {});
			revertFunc(JSON.parse(JSON.stringify(elementCacheMap[currentElementId]||originalElement)));
			if(e.details.errorName == 'CampaignLockedError'){
				dispatch(setCampaignLocked(true))
			}

			reject()
		})
	})

}
