import ImageUpload from "../../utils/ImageUpload";
import ColorPallete from "../../utils/ColorPallete";

function ProjectorEditor({campaignId, projector, onChange, closeCb}) {
	const updateBackground = (key, value) => {
		if(!projector.background) {
			projector.background = {
				"url": null,
				"size": "cover",
			}
		}
		projector.background[key] = value;
		onChange(campaignId, {...projector});
	}
	const updateLogo = (key, value) => {
		if(!projector.logo) {
			projector.logo = {
				"url": null,
				"size": "contain",
			}
		}
		projector.logo[key] = value;
		onChange(campaignId, {...projector});
	}
	const updateProjector = (key, value) => {
		projector[key] = value;
		onChange(campaignId, {...projector});
	}

	return (
		<div className="modal-overlay active modal__right">
			<div className="modal modal--lg active animate__animated animate__slideInRight modal--100">
				<div class="modal__header">
					<div class="modal__header-title">
						<h3 class="mtop--10"></h3>
					</div>
					<div class="close-modal modal--x" onClick={ () => { closeCb(false) }}>
						<svg viewBox="0 0 20 20"><path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path></svg>
					</div>
				</div>
				<div className="modal-content">
					<h2>Background</h2>
					<div className="image__upload-desktop" style={{'maxWidth':'500px', 'maxHeight':'500px'}}>
						<div className="text--muted font--12">Upload a landscape background image here.</div>
						<br />
						<ImageUpload dimensions={'1250x800px'} campaign_id={campaignId} file={projector.background?.url} item="url" updateAction={updateBackground}/>
					</div>
					<h2>Logo</h2>
					<div className="image__upload-desktop" style={{'maxWidth':'500px', 'maxHeight':'500px'}}>
						<label className="text--bold">Logo</label>
						<br />
						<ImageUpload dimensions={'800x800px'} campaign_id={campaignId} file={projector.logo?.url} item="url" updateAction={updateLogo} />
					</div>
					<h2>Main Text Color</h2>
					<div className="campaign__appearance-color">
						<div className="campaign__appearance-colorLeft">
							<ColorPallete dropdown={true} item="textColor" value={projector.textColor} type={'hex'} updateAction={updateProjector} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProjectorEditor;
