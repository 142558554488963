import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { getPollResults } from './ResultsCreator';
import DetailedResultsTable from "./DetailedResultsTable";

function MvpResults() {
	const { resultsCurrentElement, unMappedUserResults } = useSelector(state => state.user);
	const [view, setView] = useState('general')
	const [onlyValidAnswers, setOnlyValidAnswers] = useState(false);
	const [mappedResults, setMappedResults] = useState([])
	const [mappedDetailedResults, setMappedDetailedResults] = useState([])

	const mapResults = async () => {
		const results = await getPollResults(unMappedUserResults, resultsCurrentElement, onlyValidAnswers)
		const [generalResults, detailedResults] = results;
		setMappedResults(generalResults)
		setMappedDetailedResults(detailedResults);
	}
	const totalVotes = () => {
		return mappedResults.reduce((acc, res) => acc + res.answers.length, 0)
	}
	useEffect(() => {
		if (unMappedUserResults) mapResults();
	}, [unMappedUserResults, onlyValidAnswers])
	return (
		<div className="poll__results">
			<div className="flex flex--row">
				<div className='tabs'>
					<div className={`tabs__item ${view == 'general' ? 'tabs__item-active' : ''}`} onClick={() => setView('general')}>
						<span className="material-icons font--25 vm--align">
							donut_small
						</span>
						General Stats
					</div>
					<div className={`tabs__item ${view == 'detailed' ? 'tabs__item-active' : ''}`} onClick={() => setView('detailed')}>
						<span className="material-icons font--25 vm--align">
							table_chart
						</span>
						Detailed Stats
					</div>
				</div>
			</div>
			{view === 'general' &&
				<div className="poll__results-container">
					<h1>{resultsCurrentElement.title}</h1>
					<div className="poll__results-totalVotes">
						<span className="material-icons">
							how_to_vote
						</span>
						<span className='vm--align mtop--5 animate__animated animate__tada'>
							{totalVotes()}
							<div className="font--15 text--muted" style={{ marginTop: '-15px' }}>
								Votes
							</div>
						</span>
					</div>
					<div className="image__choice-inner mtop--30">
						{mappedResults.map((option, index) => {
							return (
								<div key={`option__${index}`} className="image__choice__result">
									<div className="flex flex--row">
										<div className="image__choice-text">
											{option.text}
										</div>
										<div className="float--right text--muted">
											{option.answers.length} {option.answers.length == 1 ? 'Vote' : 'Votes'}
										</div>
									</div>
									<div className="image__choice-image">
										<img src={option.image} className="img__responsive" />
									</div>
									<div className="image__choice-content">
										<div className="bar__chart">
											<div className="bar__chart-bar" style={{ width: option.percentage + '%' }}>
												<span className="bar__chart-percentage">{option.percentage + '%'}</span>
											</div>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			}
			{view === 'detailed' &&
				<>
					<div className="flex flex--row">
						<div>
							{/* <input type="text" placeholder="Search by name, email..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/> */}
						</div>
						<div className="float--right flex flex--row">
							<Tooltip title="This options works only if you have a registration element. Verified answers are the ones that have at least a name or a surname" position="bottom">
								<div className="md__input mtop--10 mright--10">
									<input type="checkbox" className="switch" checked={onlyValidAnswers} onChange={(e) => setOnlyValidAnswers(e.target.checked)} />
									<label>Only verified answers</label>
								</div>
							</Tooltip>
						</div>
					</div>
					<div className="mtop--20">
						<DetailedResultsTable total={mappedResults.length} header={resultsCurrentElement.tableHeader} rows={mappedDetailedResults} />
					</div>
				</>
			}
		</div>
	);
}

export default MvpResults;
