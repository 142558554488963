const MAX_PROBABILITY = 1000000;
/**
 * Spin the wheel and get the result
 * @param {string} campaignId The campaign's id, used for logs
 * @param {object} settings The wheel of prizes config
 * @param {object} taken A map of taken prizes, where the key is the prize id and the value is the number of times the prize was already won
 * @param {number} spinTime The time the spin happened in milliseconds, used to determine if some prizes are available
 * @param {function} randomInteger A function that returns a random number between in the [min, max] range provided
 * @param {Error} InvalidStrategyError An error class to be used if the strategy for a prize is invalid
 * @return {object}
 *                 won {boolean} Whether the user won a prize or not
 *                 participated {boolean} Whether the user participated in the prize raffle. Only used for stats keeping.
 *                 sliceIndex {number} The index of the slice the wheel landed on
 *                 slice {object} The slice that contained the winning prize or the loss
 *                 prize {object|undefined} The prize won or undefined if no prize was won
 */
function spinWheel(campaignId, settings, taken, spinTime, randomInteger, InvalidStrategyError) {
	const {participationProbability, slices} = settings;

	const out = {
		won: false,
		participated: false,
		sliceIndex: undefined,
		slice: undefined,
		prize: undefined,
	};

	const lossingSlices = [];
	/* Extract all prizes */
	const allPrizes = [];
	slices.forEach((slice, i)=>{
		if (slice.prizes.length) {
			slice.prizes.forEach((prize)=>{
				allPrizes.push({
					"prize": prize,
					"taken": taken[prize.id]?taken[prize.id]:0,
					"slice": slice,
					"sliceIndex": i,
				});
			});
		} else {
			lossingSlices.push(i);
		}
	});
	if (participated(participationProbability, randomInteger)) {
		out.participated = false;
		// No prize won
		const index = lossingSlices[Math.floor(Math.random()*lossingSlices.length)];
		out.won = false;
		out.sliceIndex = index;
		out.slice = slices[index];
	} else {
		out.participated = true;
		const compLeft = calculatePrizeProbabilities(campaignId, allPrizes, spinTime, InvalidStrategyError);
		const p = pickPrize(compLeft, randomInteger);

		if (p) {
			out.won = true;
			out.sliceIndex = p.sliceIndex;
			out.slice = p.slice;
			out.prize = p.prize;
		} else {
			// No prize won
			const index = lossingSlices[Math.floor(Math.random()*lossingSlices.length)];
			out.won = false;
			out.sliceIndex = index;
			out.slice = slices[index];
		}
	}
	return out;
}
/**
 * Pick a scatch ticket
 * @param {string} campaignId The campaign's id, used for logs
 * @param {object} settings The scratch config
 * @param {object} taken A map of taken prizes, where the key is the prize id and the value is the number of times the prize was already won
 * @param {number} spinTime The time the spin happened in milliseconds, used to determine if some prizes are available
 * @param {function} randomInteger A function that returns a random number between in the [min, max] range provided
 * @param {Error} InvalidStrategyError An error class to be used if the strategy for a prize is invalid
 * @return {object}
 *                 won {boolean} Whether the user won a prize or not
 *                 participated {boolean} Whether the user participated in the prize raffle. Only used for stats keeping.
 *                 prize {object|undefined} The prize won or undefined if no prize was won
 */
function getScratchPrize(campaignId, settings, taken, spinTime, randomInteger, InvalidStrategyError) {
	const {participationProbability, prizes} = settings;

	const out = {
		won: false,
		participated: false,
		prize: undefined,
	};

	/* Extract all prizes */
	const allPrizes = prizes.map((prize, i)=>{
		return {
			"prize": prize,
			"taken": taken[prize.id]?taken[prize.id]:0,
		};
	});
	if (participated(participationProbability, randomInteger)) {
		out.participated = false;
		// No prize won
		out.won = false;
	} else {
		out.participated = true;
		const compLeft = calculatePrizeProbabilities(campaignId, allPrizes, spinTime, InvalidStrategyError);
		const p = pickPrize(compLeft, randomInteger);

		if (p) {
			out.won = true;
			out.prize = p.prize;
		} else {
			// No prize won
			out.won = false;
		}
	}
	return out;
}
/**
 * Randomly picks whether the user participates in a raffle
 * @param {number} participationProbability The probability that a user can participate
 * @param {function} randomInteger A function that returns a random number between in the [min, max] range provided
 * @return {boolean} Whether the user can participate
 */
function participated(participationProbability, randomInteger) {
	return ( (participationProbability<MAX_PROBABILITY) && randomInteger(0, MAX_PROBABILITY)>participationProbability);
}

/**
 * Calculates the probability of the given prizes and returns the available ones
 * @param {string} campaignId The campaign's id, used for logs
 * @param {array} prizes A list of all available prizes at the time of the spin and their settings
 * @param {number} spinTime The time the spin happened in milliseconds, used to determine if some prizes are available
 * @param {Error} InvalidStrategyError An error class to be used if the strategy for a prize is invalid
 * @return {array} A list of objects containing the available prizes and their probabilities
 *                 prize {object} The prize
 *                 taken {number} The number of already won prizes
 *                 slice {object} The slice that contains the prize
 *                 sliceIndex {number} The index of the slice
 *                 probability {number} The calculated probability
 *                 stockLeft {number} The amount of stock left
 */
function calculatePrizeProbabilities(campaignId, prizes, spinTime, InvalidStrategyError) {
	// Copy the prizes so there are no side-effects
	let prizesCopy = prizes.map((p)=>{
		return {
			"prize": p.prize,
			"taken": p.taken,
			"slice": p.slice,
			"sliceIndex": p.sliceIndex,
			"probability": undefined,
			"stockLeft": undefined,
		};
	});
	// Calculate stock left
	prizesCopy.forEach((p)=>{
		let stockLeft = (p.prize.stock===null)?Infinity:p.prize.stock;
		// Apply strategy
		if (p.prize.strategy) {
			switch (p.prize.strategy.strategy) {
				case "active": {
					const {open, close} = p.prize.strategy;
					if ( (open!==null) && (open!==undefined) ) {
						if (spinTime<open) stockLeft = 0;
					}
					if ( (close!==null) && (close!==undefined) ) {
						if (spinTime>close) stockLeft = 0;
					}
					break;
				}
				case "spread": {
					if (stockLeft===Infinity) throw new InvalidStrategyError(campaignId, p.prize, "Invalid strategy \"spread\" for unlimited stock");
					const {from, to} = p.prize.strategy;
					if (spinTime<from) {
						stockLeft = 0;
					} else {
						stockLeft = Math.ceil(p.prize.stock * (spinTime-from)/(to-from));
					}
					break;
				}
				case "reserve": {
					if (stockLeft===Infinity) throw new InvalidStrategyError(campaignId, p.prize, "Invalid strategy \"reserve\" for unlimited stock");
					p.prize.strategy.dates.forEach(({from, quantity})=>{
						if (from > spinTime) {
							stockLeft = stockLeft - quantity;
						}
					});
					break;
				}
				default: {
					throw new InvalidStrategyError(campaignId, p.prize, "Invalid strategy: "+p.prize.strategy.strategy);
				}
			}
		}
		// Remove the taken stock
		stockLeft = stockLeft - p.taken;

		p.stockLeft = stockLeft;
	});
	// Remove prizes that are not available
	prizesCopy = prizesCopy.filter((p)=>p.stockLeft>0);

	// Setup the probabilities if they are set by the user and prepare for the "auto" calculations
	let probabilityLeft = MAX_PROBABILITY;
	const autoProbabilityPrizes = [];
	let totalStock = 0;
	let infiniteStockCounter = 0;
	prizesCopy.forEach((p)=>{
		if (p.prize.probability!==null) {
			p.probability = p.prize.probability;
			probabilityLeft = probabilityLeft - p.probability;
		} else {
			autoProbabilityPrizes.push(p);
			if (p.stockLeft===Infinity) infiniteStockCounter++;
			else totalStock = totalStock + p.stockLeft;
		}
	});
	const hasPrizesWithInfiniteStock = infiniteStockCounter>0;

	// Calculate the probability of prizes with "auto" probability
	autoProbabilityPrizes.forEach((p)=>{
		// If there is at least one prize with infinite stock then only prizes with infinite stock have a chance to win
		if (hasPrizesWithInfiniteStock) {
			if (p.stockLeft===Infinity) {
				const chunk = Math.floor(probabilityLeft/infiniteStockCounter);
				infiniteStockCounter--;
				probabilityLeft = probabilityLeft - chunk;
				p.probability = chunk;
			} else {
				p.probability = 0;
			}
		} else {
			const chunk = Math.floor(probabilityLeft*p.stockLeft/totalStock);
			probabilityLeft = probabilityLeft - chunk;
			totalStock = totalStock - p.stockLeft;
			p.probability = chunk;
		}
	});
	return prizesCopy;
}

/**
 * Pick a random prize from the list of prizes
 * @param {array} prizes A list of all available prizes with their probabilities
 * @param {function} randomInteger A function that returns a random number between in the [min, max] range provided
 * @return {object|undefined} The prize won or undefined if no prize was won
 */
function pickPrize(prizes, randomInteger) {
	const rand = randomInteger(0, MAX_PROBABILITY);
	let s = 0;
	return prizes.find((p) => {
		const from = s;
		const to = s + p.probability;
		s = to;
		return (rand>=from) && (rand<=to);
	});
}

export {
	MAX_PROBABILITY,
	spinWheel,
	getScratchPrize,
	calculatePrizeProbabilities,
};
