import React, { useState, useEffect, useRef, useCallback } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { getCampaigns, getOrganizationCampaigns, createCampaign, deleteCampaign, getCampaign, modifyCampaignData, sendEmailVerification, getParsedToken} from '../../firebaseActions.mjs';
import {setCampaigns, clearCampaigns, deleteCampaignStore} from "../../actions/UserActions";
import "../../scss/pages/CampaignList.scss";
import moment from 'moment';
import TopBar from "./CampaignListElements/TopBarList";
import AddCampaignModal from "./CampaignListElements/AddCampaignModal";
import DeleteCampaignModal from "./CampaignListElements/DeleteCampaignModal";
import ForceDeleteCampaignModal from "./CampaignListElements/ForceDeleteCampaignModal";
import {CAMPAIGNDATA, CAMPAIGNMETA} from "./CampaignElements/CampaignAddDefaults";
import CampaignListActions from "./CampaignListElements/CampaignListActions";
import {genUUID, Notification} from '../utils/Misc';
import OrganizationsDropdown from "../utils/OrganizationsDropdown.jsx";
import EditableInput from '../utils/EditableInput';
import noCampaignsImage from '../../images/undraw_add_information_j2wg.svg';
import Loader from "../../Loader";
import Dropdown from "../utils/Dropdown";

function CampaignList() {
	const dispatch = useDispatch();
	const intersectionRef = useRef(false);
	const lastRow = useRef(false);
	const [addCampaignModal, setAddCampaignModal] = useState();
	const [emailVerificationResend, setEmailVerificationResend] = useState(false);
	const [deleteCampaignModal, setDeleteCampaignModal] = useState();
	const [forceDeleteCampaignModal, setForceDeleteCampaignModal] = useState();
	const [loading, setLoading] = useState(false);
	const [adding, setAdding] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [duplicating, setDuplicating] = useState(false);
	const {user, campaigns} = useSelector(state => state.user);
	const { organizations } = useSelector((state) => state.organizations)
	const [newCampaignName, setNewCampaignName] = useState('New Campaign');
	const [selectedType, setSelectedType] = useState('numericPrediction');
	const [lastCampaign, setLastCampaign] = useState(null);
	const PAGE_SIZE = 30;
	const [lastPageSize, setLastPageSize] = useState(PAGE_SIZE);
	const [selectedOrganization, setSelectedOrganization] = useState(localStorage.getItem('sportovivo_selectedOrganizationId')  ? {
		id: localStorage.getItem('sportovivo_selectedOrganizationId'),
		data: { name: localStorage.getItem('sportovivo_selectedOrganizationName') },
	} : null);
	const hideHome = getParsedToken()?.hideHome;

	const getList = useCallback((lastCampaign) => {
		setLoading(true);
		if(selectedOrganization){
			getOrganizationCampaigns(selectedOrganization.id, PAGE_SIZE, lastCampaign)
			.then(function (userCampaigns) {
				let tmp = [];
				userCampaigns.forEach(((campaign) => {
					setLastCampaign(campaign.doc);
					tmp = [...tmp, ...[{ ...campaign.data, id: campaign.id, organizationId: selectedOrganization.id }]];
				}))
				dispatch(setCampaigns(tmp));
				setLastPageSize(tmp.length);
			})
			.catch(function (error) {
				console.error('userCampaigns error', error)
			})
			.finally(function () {
                setLoading(false);
            });
		}else{
			getCampaigns(user.uid, PAGE_SIZE, lastCampaign)
			.then(function (userCampaigns) {
				let tmp = [];
				userCampaigns.forEach(((campaign) => {
					setLastCampaign(campaign.doc);
					tmp = [...tmp, ...[{ ...campaign.data, id: campaign.id, organizationId: null }]];
				}))
				dispatch(setCampaigns(tmp));
				setLastPageSize(tmp.length);
			})
			.catch(function (error) {
				console.error('userCampaigns error', error)
			})
			.finally(function () {
                setLoading(false);
            });
		}
	}, [user, dispatch, selectedOrganization]);

	const add =  useCallback(() => {
		setAdding(true);
		let campaignData = CAMPAIGNDATA(selectedType);
		let campaignMeta = CAMPAIGNMETA();
		console.log(campaignData);
		campaignMeta.name = newCampaignName;
		campaignMeta.share.image= new URL("https://via.placeholder.com/150/433F66/fff?Text="+newCampaignName).href;
		campaignMeta.share.description = newCampaignName;
		createCampaign(campaignData, campaignMeta, selectedOrganization?.id)
		.then(function({campaignId}){
			setAddCampaignModal(null);
			setNewCampaignName(`Campaign ${campaigns.length+1}`);
			window.location = "campaigns/"+campaignId+"/edit";
		})
		.catch((e) => {
			console.log(e);
		})
		.finally(() => setAdding(false));
	}, [user, selectedOrganization, newCampaignName, selectedType]);
	function traverse(o) {
		for (const key in o) {
			if(key === 'id'){
				const oldVal = o[key];
				o[key] = genUUID();
				console.log('key : ' + key + ' ' + oldVal + ' -> ' + o[key]);
				console.log('_____________');
			}
			if (o[key] !== null && typeof(o[key])=="object") {
				traverse(o[key]);
			}
		}
		return o;
	}
	function extractWheelOfPrizePreviewPositions(campaign){
		const toSet = new Map();
		for(let i = 0;i < campaign.pages.length; i++){
			for(let j = 0;j < campaign.pages[i].elements.length; j++){
				if(campaign.pages[i].elements[j].type === "wheelOfPrizesPreview"){
					toSet.set(campaign.pages[i].elements[j].wheelOfPrizeId, {to:[i,j], from:null})
				}
			}
		}
		if(toSet.size){
			for(let i = 0;i < campaign.pages.length; i++){
				for(let j = 0;j < campaign.pages[i].elements.length; j++){
					if(campaign.pages[i].elements[j].type === "wheelOfPrizes"){
						if(toSet.has(campaign.pages[i].elements[j].id)){
							toSet.get(campaign.pages[i].elements[j].id).from = [i,j];
						}
					}
				}
			}
		}
		return toSet;
	}
	function copyWheelOfPrizeIds(campaignData, toSet){
		if(toSet.size){
			const tmp = toSet.values();
			for(const {to, from} of tmp) {
				if(to&&from){
					campaignData.pages[to[0]].elements[to[1]].wheelOfPrizeId = campaignData.pages[from[0]].elements[from[1]].id;
				}
			}
		}
	}
	function onBottomReach(callback) {
		const observer = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting) {
				callback(observer);
				console.log("Last row is visible");
			}
		});
		observer.observe(lastRow.current);
		intersectionRef.current = observer;
	}
	const duplicate = useCallback((campaign_id) => {
		setDuplicating(campaign_id);
		getCampaign(campaign_id)
		.then( data => {
			// Find and keep the position of wheel of prize previews and their wheel of prizes
			const toSet = extractWheelOfPrizePreviewPositions(data.campaign);
			let campaignData = traverse(data.campaign);
			// Copy the id of the new wheel of prizes if needed
			copyWheelOfPrizeIds(campaignData, toSet);
			const endDate = campaignData?.endDate;
			delete campaignData?.timestamp;
			delete campaignData?.endDate;
			delete campaignData?.lockedTimestamp;
			delete campaignData?.locked;
			delete campaignData?.googleTagId;
			createCampaign(campaignData, {name: 'Copy of '+ data.campaignMeta.name, endDate: endDate}, selectedOrganization?.id)
			.then(function({campaignId}){
				window.location = "campaigns/"+campaignId+"/edit";
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => setDuplicating(false) )

		})
	}, [selectedOrganization, user, selectedType])
	const deleteCampaignDB =  (campaign_id) => {
		setDeleting(true);
		setDeleteCampaignModal(null);
		deleteCampaign(campaign_id, false)
		.then(() => {
			dispatch(deleteCampaignStore(campaign_id));
		})
		.catch((e)=> {
			setForceDeleteCampaignModal(campaign_id);
		})
		.finally(() => setDeleting(false))
	}
	const forceDeleteCampaign = (campaign_id) => {
		setDeleting(true);
		setForceDeleteCampaignModal(null);
		deleteCampaign(campaign_id, true)
		.then(() => {
			dispatch(deleteCampaignStore(campaign_id));
		})
		.catch((e)=> {
			console.log('CampaignDelete:', e);
			Notification('An error occured. Please try again later.');
		})
		.finally(() => setDeleting(false))
	}
	const updateCampaign = (key, value, extra) => {
		if(key === 'name'){
			if(value.length === 0){
				Notification("The name field can't be empty, This value will not be saved.");
				return false;
			}
		}
		modifyCampaignData(extra['campaign_id'], {[key]: value})
	}
	const sendEmail = () => {
		sendEmailVerification(user)
		setEmailVerificationResend(true);
		setTimeout(() => {setEmailVerificationResend(false)} , 50000)
	}
	useEffect(() => {
		dispatch(clearCampaigns([]));		
		setLastPageSize(PAGE_SIZE);
		setLastCampaign(null)
		getList();
		if(selectedOrganization){
			localStorage.setItem('sportovivo_selectedOrganizationId', selectedOrganization.id)
			localStorage.setItem('sportovivo_selectedOrganizationName', selectedOrganization.data.name)
		}else{
			localStorage.removeItem('sportovivo_selectedOrganizationId')
			localStorage.removeItem('sportovivo_selectedOrganizationName')
		}
	}, [selectedOrganization]);
	useEffect(() => {
		if(!intersectionRef.current && lastRow.current){
			if (lastPageSize < PAGE_SIZE) return;
			onBottomReach((observer) => {
				observer.disconnect();
				intersectionRef.current = false;
				getList(lastCampaign);
			});
			intersectionRef.current = true;
		}
	}, [lastRow.current]);
	return (
		<>
			<TopBar />
			<div className="campaign__list campaign__list flex flex--column">
				{!user?.emailVerified &&
					<div className="user__notVerified">
						<span className="material-icons font--25 mright--10">
							warning_amber
						</span><br />
						Please verify your email address in order to continue through the link we've sent you.
						<div className="font--14">
							Haven't received a verification email?
							<br />
							<button className="btn btn-primary btn-sm" disabled={emailVerificationResend} onClick={sendEmail}>Re-send verification email</button>
						</div>
					</div>
				}
				<div className="flex flex--row">
					{campaigns.length > 0 &&
						<button className="btn btn-primary campaign__list-create" onClick={() => setAddCampaignModal(true) }>
							<span className="material-icons font--20 vm--align">
								add
							</span>
							<span className="campaign__list-createText">Create campaign</span>
						</button>
					}
					<div className="float--right flex flex--row">
						&nbsp;
						{campaigns.length > 0 &&
							<input type="text" placeholder="Search by name..." />
						}
					</div>
				</div>
				<div className="flex flex--row flex--content">
					<div className="campaign__list-accounts" style={{ width: organizations.length === 0 ? '0px' : ''}}>
						<OrganizationsDropdown user={user} value={selectedOrganization} setValue={setSelectedOrganization} isSidebar={true} />
					</div>
					<div className={`mtop--10 campaign__list-list ${organizations.length > 1 && 'campaign__list-listMd'}`}>
						{loading && <Loader />}
						<div className="campaign___list-organizationsMob">
							<Dropdown>
								<div className="dropdown-trigger btn btn-light mtop--10 text--left">
									{selectedOrganization ? selectedOrganization?.data?.name : 'Select an organization'}
									<span className="material-icons font--20 vm--align">
										keyboard_arrow_down
									</span>
								</div>
								<div className="dropdown__menu">
									<div className="dropdown__content campaign__list-accounts">
										<OrganizationsDropdown user={user} value={selectedOrganization} setValue={setSelectedOrganization} isSidebar={true} />			
									</div>
								</div>
							</Dropdown>
						</div>
						{campaigns.length > 0 &&
							<table>
								<thead className="campaign__list-tableHeader">
									<tr>
										<th>
											<span className="text--muted">#{campaigns.length} | &nbsp;</span> 
											NAME
										</th>
										<th>RESPONSES</th>
										<th>CREATED</th>
										<th className="campaign__list-actionsDesk"></th>
									</tr>
								</thead>
								<tbody>
									{campaigns.map((campaign, index) => {
										return (
											<React.Fragment key={`campaign__item${index}`}>
												<tr ref={lastRow} className="campaign__list-row">
													<td className="campaign__list-rowName">
														<div className="text--bold">
															<EditableInput nonEmpty type="text" extra={{ campaign_id: campaign.id }} characterCount={150} href={"campaigns/" + campaign.id + "/edit"} item="name" withPencil startValue={campaign.name} updateAction={updateCampaign} />
														</div>
													</td>
													<td>{campaign.totalResponses}</td>
													<td>{moment.unix(campaign.timestamp).format("DD/MM")}</td>
													<td className="campaign__list-actionsDesk">
														<CampaignListActions
															campaign={campaign}
															duplicate={duplicate}
															duplicating={duplicating}
															setDeleteCampaignModal={setDeleteCampaignModal}
														/>
													</td>
												</tr>
												<tr className="campaign__list-actionsMob">
													<td colSpan={3}>
														<CampaignListActions
																campaign={campaign}
																duplicate={duplicate}
																duplicating={duplicating}
																setDeleteCampaignModal={setDeleteCampaignModal}
														/>
													</td>
												</tr>
											</React.Fragment>
										)
									})}
								</tbody>
							</table>
						}
						{campaigns.length === 0 &&
							<div className="text--center mtop--20">
								<img src={noCampaignsImage} className="img__responsive" width="300" />
								{(hideHome && !selectedOrganization)?
									<h3>You can only create campaigns in an organization</h3>
									:
									<>
										<h3>Add your first campaign</h3>
										<button className="btn btn-primary" onClick={() => setAddCampaignModal(true)}>
											<span className="material-icons font--20 vm--align">
												add
											</span>
											Create campaign
										</button>
									</>
								}
							</div>
						}
					</div>
				</div>
			</div>
			{ addCampaignModal &&
				<AddCampaignModal
					closeCb={setAddCampaignModal}
					addAction={add}
					adding={adding}
					name={newCampaignName}
					setName={setNewCampaignName}
					selectedType={selectedType}
					setSelectedType={setSelectedType}
					user={user}
				/>}
			{ deleteCampaignModal && <DeleteCampaignModal deleting={deleting} closeCb={setDeleteCampaignModal} deleteAction={deleteCampaignDB} campaign_id={deleteCampaignModal} />}
			{ forceDeleteCampaignModal && <ForceDeleteCampaignModal deleting={deleting} closeCb={setForceDeleteCampaignModal} deleteAction={forceDeleteCampaign} campaign_id={forceDeleteCampaignModal} />}
		</>
	);
}

export default CampaignList;
