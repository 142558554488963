import CountrySelect from "react-country-select";
import PhoneInput from 'react-phone-number-input'

function RegistrationPhoneElementOptions({field, updateField}) {
	return (
		<div className="mtop--10 mbottom--10">
			<label className="text--bold font--12">Default country</label>
			<div className="registration__react-select">
				<PhoneInput defaultCountry={field?.defaultCountry} />
				<div className="registration__react-selectDropdown">
					<CountrySelect multi={false} value={field?.defaultCountry} onSelect={(e) => updateField('defaultCountry', e.value, { field_id: field.id })} />
				</div>
			</div>
		</div>
	)
}

export default RegistrationPhoneElementOptions