import { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import ColorPallete from "../../../utils/ColorPallete";
import EditableInput from "../../../utils/EditableInput";
import Dropdown from "../../../utils/Dropdown";
import {updateElement} from "../../../utils/UpdateFunctions";
import { editElement } from "../../../../actions/UserActions";
import ImageUpload from "../../../utils/ImageUpload";
import {getDefaultElement} from "../CampaignAddDefaults";

function SlideShowElementOptions() {
	const dispatch = useDispatch();
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const { categories, marginTop, background, slidesPerView, textColor, height} = elementCopy;

	const update = (key, value) => {
		if(key == 'marginTop') value += 'px';
		if(key == 'height') value += 'px';
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const updateCategory = (key, value, extra) => {
		let f = elementCopy.categories.filter( category => category.id == extra['category_id']);
		if(f){
			f[0][key] = value;
		}
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const updateSlide = (key, value, extra) => {
		let f = elementCopy.categories.filter( category => category.id == extra['category_id']);
		if(f){
			let slide = f[0]['slides'].filter( slide => slide.id == extra['slide_id']);
			if(slide){
				slide[0][key] = value;
			}
		}
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const addCategory = () => {
		let category = getDefaultElement('slideShowCategory');
		elementCopy.categories.push(category);
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const deleteCategory = (category_id) => {
		const o = elementCopy['categories'].filter( category => category.id !== category_id);
		elementCopy.categories = o;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const addSlide = (category_id) => {
		let slide = getDefaultElement('slide');
		if(category_id){
			let f = elementCopy.categories.filter( category => category.id == category_id);
			if(f){
				f[0]['slides'].push(slide);
			}
		}
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const deleteSlide = (slide_id, category_id) => {
		let o = [];
		if(category_id){
			let f = elementCopy.categories.filter( category => category.id == category_id);
			if(f){
				o = f[0]['slides'].filter( slide => slide.id !== slide_id);
			}
			f[0]['slides'] = o
		}
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	return (
		<div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Categories</label>
				<div className="mtop--15">
					{	categories.map((category, index) => {
							return (
								<div className="campaign__option categorized__poll-category" key={`cat__${index}`}>
									<div className="mtop--10 flex flex--row">
										<div>
											<EditableInput item="title" extra={{category_id: category.id}} type="text" startValue={category.title} updateAction={updateCategory} placeholder={'Type here...'} />
										</div>
										<button className="btn btn-secondary mleft--10 float--right" onClick={() => deleteCategory(category.id)}>
											<span className="material-icons font--20 text--danger">
												delete
											</span>
										</button>
									</div>
									<div className="mtop--20" key={`category_${index}`}>
										{ category.slides.map((slide, index) => {
											return (
												<div className="registration__element" key={`slide_${index}`}>
													<div className="flex flex--row">
														<div style={{width: '33%'}}>
															<ImageUpload dimensions={'150x150px'} extra={{category_id: category.id, slide_id: slide.id}} campaign_id={currentCampaignId} file={slide.image} item="image" updateAction={updateSlide} />
														</div>
														<div className="registration__element-inp mleft--10">
															<div>
																<label>Title</label>
																<EditableInput extra={{category_id: category.id, slide_id: slide.id}} item="label" type="text" startValue={slide.label} updateAction={updateSlide} placeholder={'Type here...'} />
															</div>
															<div className="mtop--15 btn btn-primary-soft btn-100 text--left">
																<div>
																	<label>Link</label>
																	<EditableInput extra={{category_id: category.id, slide_id: slide.id}} item="url" type="text" startValue={slide.url} updateAction={updateSlide} placeholder={'Type here...'} />
																</div>
																<div className="flex flex--row mtop--10">
																	<div onClick={() => updateSlide('target', 'self', {category_id: category.id, slide_id: slide.id})} className={slide.target == 'self' ? 'btn btn-primary btn-md' : 'btn btn-light btn-md'}>Same window</div>
																	<div onClick={() => updateSlide('target', 'newTab', {category_id: category.id, slide_id: slide.id})} className={slide.target == 'newTab' ? 'btn btn-primary btn-md' : 'btn btn-light btn-md'}>New window</div>
																</div>
															</div>
														</div>
														<span className="material-icons font--25 text--danger mtop--10" onClick={() => deleteSlide(slide.id, category.id)}>
															clear
														</span>
													</div>
												</div>
											)
										})}
										<div className="text--center">
											<button className="btn btn-primary-pastel mtop--10" onClick={() => addSlide(category.id)}>
												<span className="material-icons font--20 vm--align">add</span>
												Add Slide
											</button>
										</div>
									</div>
								</div>
							)
						})
					}
					<button className="btn btn-primary btn-100 mtop--10" onClick={() => addCategory()}>
						<span className="material-icons font--20 vm--align">add</span>
						Add Category
					</button>
				</div>
			</div>
			<div className="campaign__option mtop--20">
				<label className="text--bold">Slider Per View</label>
				<div className="font--12 text--muted">Define how many slides are going to appear side by side on screen</div>
				<EditableInput item="slidesPerView" type="number" startValue={slidesPerView} updateAction={update} placeholder="3" />
			</div>
			<div className="campaign__option mtop--20">
				<label className="text--bold">Slideshow Height(pixels)</label>
				<EditableInput item="height" type="number" startValue={height.substring(0, height.length - 2)} updateAction={update} placeholder="150" />
			</div>
			<div className="campaign__option mtop--20">
				<label className="text--bold">Top Margin (pixels)</label>
				<div className="font--12 text--muted">The space the element has from the top</div>
				<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
			</div>
			<div className="flex flex--row flex--wrap mtop--20">
				<div className="campaign__option campaign__option-50">
					<label className="text--bold">Background Color</label>
					<div className="campaign__option-act">
						<ColorPallete item="background" value={background} type={'rgba'} updateAction={update} />
					</div>
				</div>
				<div className="campaign__option campaign__option-50">
					<label className="text--bold">Text Color</label>
					<div className="campaign__option-act">
						<ColorPallete item="textColor" value={textColor} type={'hex'} updateAction={update} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default SlideShowElementOptions;
