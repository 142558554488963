import { useDispatch } from 'react-redux';
import EditableInput from "../../../../utils/EditableInput";
import ImageUpload from '../../../../utils/ImageUpload';
import { updateElement } from "../../../../utils/UpdateFunctions";
import { editElement } from "../../../../../actions/UserActions";
import ColorPallete from '../../../../utils/ColorPallete';


function SoccerLineUpStyling({ currentCampaignId, currentPage, currentElement, elementCopy, setElementCopy }) {
	const dispatch = useDispatch();
	const { formations, marginTop, shirtColor, shirtImage, gkShirtColor, gkShirtImage } = elementCopy;
	const availableFormations = [
		{
			"type": "4-4-2",
			"ext": [1, 3],
			"fwd": [0, 4],
			"mid": [1, 3],
			"def": [0, 1, 3, 4],
			"goal": [2],
		},
		{
			"type": "4-4-2 (D)",
			"ext": [1, 3],
			"fwd": [2, 6, 8],
			"mid": [7],
			"def": [0, 1, 3, 4],
			"goal": [2],
		},
		{
			"type": "4-3-3",
			"ext": [2],
			"fwd": [0, 4, 6, 8],
			"mid": [7],
			"def": [0, 1, 3, 4],
			"goal": [2],
		},
		{
			"type": "3-4-3",
			"ext": [2],
			"fwd": [0, 4],
			"mid": [0, 1, 3, 4],
			"def": [1, 2, 3],
			"goal": [2],
		},
		{
			"type": "4-2-3-1",
			"ext": [2],
			"fwd": [1, 2, 3],
			"mid": [1, 3],
			"def": [0, 1, 3, 4],
			"goal": [2],
		}
	];
	const update = (key, value) => {
		if (key == 'marginTop') value += 'px';
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
		//updateElement(currentCampaignId, currentPage.id, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const getFormationCss = f => {
		return f === formations[0]['type'] ? "btn btn-primary" : 'btn btn-secondary';
	}
	return (
		<div className="mtop--20">
			<div className="campaign__option mtop--20">
				<label className="text--bold">Formation</label>
				<div className="font--12 text--muted"></div>
				<div className="mtop--20">
					{
						availableFormations.map((formation, index) => {
							return (
								<div className={getFormationCss(formation.type)} key={`formation_${index}`} onClick={() => update('formations', [formation])}>
									{formation.type}
								</div>
							)
						})
					}
				</div>
			</div>
			<div className="campaign__option mtop--20">
				<label className="text--bold">Top Margin (pixels)</label>
				<div className="font--12 text--muted">The space the element has from the top</div>
				<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
			</div>
			<div className="flex flex--row flex--wrap mtop--20">
				<div className="campaign__option campaign__option-50">
					<label className="text--bold">Shirt color</label>
					<div className="campaign__option-act">
						<ColorPallete item="shirtColor" value={shirtColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="campaign__option campaign__option-50 mleft--5">
					<label className="text--bold">Shirt image</label>
					<div className="campaign__option-act">
						<ImageUpload dimensions={'W:170px x H:250px'} campaign_id={currentCampaignId} file={shirtImage} item="shirtImage" updateAction={update} />
					</div>
				</div>
			</div>
			<div className="flex flex--row flex--wrap mtop--20">
				<div className="campaign__option campaign__option-50">
					<label className="text--bold">Goalkeeper Shirt color</label>
					<div className="campaign__option-act">
						<ColorPallete item="gkShirtColor" value={gkShirtColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="campaign__option campaign__option-50 mleft--5">
					<label className="text--bold">Goalkeeper Shirt image</label>
					<div className="campaign__option-act">
						<ImageUpload dimensions={'W:170px x H:250px'} campaign_id={currentCampaignId} file={gkShirtImage} item="gkShirtImage" updateAction={update} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default SoccerLineUpStyling;
