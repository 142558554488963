import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDetectClickOutside } from 'react-detect-click-outside';
import ColorPallete from "../../utils/ColorPallete";
import {updateCampaignDB} from "../../utils/UpdateFunctions";
import { editCampaign } from "../../../actions/UserActions";
import ImageUpload from "../../utils/ImageUpload";
import {getDefaultElement} from './CampaignAddDefaults';
import Dropdown from "../../utils/Dropdown";
import { updateAppThemeIfNeeded } from '../../utils/Misc'
import { useRef } from "react";

function CampaignAppearance(props) {
	const dispatch = useDispatch();
	const {currentCampaign, currentCampaignId} = useSelector( state => state.user );
	const [currentTab, setCurrentTab] = useState('background')
	const {closeCb} = props;
	const {background, mobileBackground, tint, font, primaryTextColor, primaryColor, secondaryColor, secondaryTextColor} = currentCampaign;
	const availableFonts = [
		"Arial",
		"Courier New",
		"Georgia",
		"Tahoma",
		"Times New Roman",
		"Trebuchet MS",
		"Verdana"
	];
	const update = (key, value) => {
		currentCampaign[key] = value;
		updateCampaignDB(currentCampaignId,  {[key]: currentCampaign[key]}, dispatch, editCampaign)
	}
	const updateBackground = (key, value) => {
		if(!currentCampaign.hasOwnProperty('background')) currentCampaign['background'] = getDefaultElement('background');
		currentCampaign['background'][key] = value;
		updateCampaignDB(currentCampaignId,  {background: currentCampaign['background']}, dispatch, editCampaign)
	}
	const updateMobileBackground = (key, value) => {
		if(!currentCampaign.hasOwnProperty('mobileBackground')) currentCampaign['mobileBackground'] = getDefaultElement('background');
		currentCampaign['mobileBackground'][key] = value;
		updateCampaignDB(currentCampaignId,  {mobileBackground: currentCampaign['mobileBackground']}, dispatch, editCampaign)
	}
	const updateTint = (key, value) => {
		if(!currentCampaign.hasOwnProperty('tint')) currentCampaign['tint'] = getDefaultElement('tint');
		currentCampaign['tint'][key] = value;
		updateCampaignDB(currentCampaignId,  {tint: currentCampaign['tint']}, dispatch, editCampaign)
	}
	const updateFont = (key, value) => {
		if(!currentCampaign.hasOwnProperty('font')) currentCampaign['font'] = getDefaultElement('font');
		currentCampaign['font'][key] = value;
		updateCampaignDB(currentCampaignId,  {font: currentCampaign['font']}, dispatch, editCampaign)
	}
	const getFontItemCss = f => {
		let o = "dropdown__item";
		if(font){
			if(font.family == f)
				o += ' dropdown__item-active'

		}
		return o;
	}

	const clickOutsideCount = useRef(0)
	const ref = useDetectClickOutside({ onTriggered: () => {
		console.log("is it triggering")
		if(clickOutsideCount.current > 0) closeCb(false) 
		else clickOutsideCount.current++ 
	} });

	useEffect(() => {
		updateAppThemeIfNeeded(primaryColor, primaryTextColor, secondaryColor, secondaryTextColor)
	}, [background, secondaryColor, primaryColor, primaryTextColor, secondaryTextColor])

	return (
		<div ref={ref} className="campaign__appearance animate__animated animate__slideInRight">
			<div className="campaign__appearance-header">
				<h3 className="mtop--10">
					<span className="material-icons vm--align font--30">
						brush
					</span>
					&nbsp;
					Campaign Appearance
				</h3>
				<div className="close-modal modal--x" onClick={() => { closeCb(false) }}>
					<svg viewBox="0 0 20 20">
						<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
					</svg>
				</div>
			</div>
			<div className="campaign__appearance-content">
				<div className='tabs'>
					<div className={`tabs__item ${currentTab == 'background' && 'tabs__item-active'}`} onClick={() => setCurrentTab('background')}>
						<span className="material-icons font--20 vm--align mright--10">
							wallpaper
						</span>
						Background
					</div>
					<div className={`tabs__item ${currentTab == 'colors' && 'tabs__item-active'}`} onClick={() => setCurrentTab('colors')}>
						<span className="material-icons font--20 vm--align mright--10">
							palette
						</span>
						Colors
					</div>
					<div className={`tabs__item ${currentTab == 'font' && 'tabs__item-active'}`} onClick={() => setCurrentTab('font')}>
						<span className="material-icons font--20 vm--align mright--10">
							text_fields
						</span>
						Font
					</div>
				</div>
				{currentTab == 'background' &&
					<>
						<div className="card card__warning text--center">
							<span className="material-icons font--30">warning</span>
							<div className="mtop--20">
								Please make sure to adjust your colors according to the background image and theme you want to set for your campaign!
							</div>
						</div>
						<div className="mtop--15">
							<label className="text--bold">Set a background image on your campaign</label>
							<div className="text--muted font--12">You need to set 2 images with different dimensions. 1 for mobile and 1 for desktop screens.</div>
							<div className="mtop--10 image__upload">
								<div className="image__upload-mobile">
									<label className="text--bold">Mobile Image</label>
									<div className="text--muted font--12">Portrait.</div>
									<div className="text--muted font--12">This image will appear in mobile devices</div>
									<ImageUpload dimensions={'1050x1845 px'} campaign_id={currentCampaignId} file={mobileBackground?.url} item="url" updateAction={updateMobileBackground} />
								</div>
								<div className="image__upload-desktop">
									<label className="text--bold">Desktop Image</label>
									<div className="text--muted font--12">Upload a landscape background image here.</div>
									<div className="text--muted font--12">This image will appear in desktop devices</div>
									<br />
									<ImageUpload dimensions={'1250x800px'} campaign_id={currentCampaignId} file={background?.url} item="url" updateAction={updateBackground} />
								</div>
							</div>
						</div>
						<div className="mtop--10 flex flex--row">
							<div className="appearance__item mleft--10 flex--50">
								<label className="text--bold">Background style</label>
								<div className="text--muted font--12">Set how the backgroud image is going to behave</div>
								<div className="flex flex--row float--right">
									<button onClick={() => updateBackground('size', 'cover')} className={background?.size ? (background.size == 'cover' ? 'btn btn-primary' : 'btn btn-secondary') : 'btn btn-secondary'}>Cover</button>
									<button onClick={() => updateBackground('size', 'contain')} className={background?.size ? (background.size == 'contain' ? 'btn btn-primary' : 'btn btn-secondary') : 'btn btn-secondary'}>Fit to screen</button>
								</div>
							</div>
						</div>
					</>
				}
				{currentTab == 'colors' &&
					<div className="flex flex--column">
						<div className="campaign__appearance-color">
							<div className="campaign__appearance-colorLeft">
								<ColorPallete dropdown={true}  item="background" value={tint?.background} type={'rgba'} updateAction={updateTint} />
							</div>
							<div className="campaign__appearance-colorRight">
								<label className="text--bold">Background color</label>
								<div className="text--muted font--12">This overlay will appear above your background image and below your elements.</div>
								<div className="text--muted font--12">Or it can be used as a background color if you don't have an image.</div>
							</div>
						</div>
						<div className="campaign__appearance-color">
							<div className="campaign__appearance-colorLeft">
								<ColorPallete dropdown={true} item="secondaryColor" value={secondaryColor} type={'rgba'} updateAction={update} />
							</div>
							<div className="campaign__appearance-colorRight">
								<label className="text--bold">Secondary color</label>
								<div className="text--muted font--12">This color is used on the elements</div>
							</div>
						</div>
						<div className="campaign__appearance-color">
							<div className="campaign__appearance-colorLeft">
								<ColorPallete dropdown={true} item="primaryColor" value={primaryColor} type={'hex'} updateAction={update} />
							</div>
							<div className="campaign__appearance-colorRight">
								<label className="text--bold">Primary color</label>
								<div className="text--muted font--12">This color is used for action buttons, links and more.</div>
							</div>
						</div>
						<div className="campaign__appearance-color">
							<div className="campaign__appearance-colorLeft">
								<ColorPallete dropdown={true} item="primaryTextColor" value={primaryTextColor} type={'hex'} updateAction={update} />
							</div>
							<div className="campaign__appearance-colorRight">
								<label className="text--bold">Main Text Color</label>
								<div className="text--muted font--12">Used on all text elements</div>
							</div>
						</div>
						<div className="campaign__appearance-color">
							<div className="campaign__appearance-colorLeft">
								<ColorPallete dropdown={true} item="secondaryTextColor" value={secondaryTextColor} type={'hex'} updateAction={update} />
							</div>
							<div className="campaign__appearance-colorRight">
								<label className="text--bold">Secondary Text Color</label>
								<div className="text--muted font--12">Used for buttons text. (Example: Blue button needs white text)</div>
							</div>
						</div>
					</div>
				}
				{currentTab == 'font' &&
					<div className="mtop--15">
						<div className="mtop--10 flex flex--row">
							<div className="appearance__item flex--33">
								<label className="text--bold">Type</label>
								<Dropdown dropup={false}>
									<div className="dropdown-trigger btn btn-secondary btn-100">
										{font ? font.family : 'Arial'}
										<span className="material-icons font--20 vm--align">
											keyboard_arrow_down
										</span>
									</div>
									<div className="dropdown__menu">
										<div className="dropdown__content">
											{availableFonts.map((font, index) => {
												return (
													<div style={{ fontFamily: font }} className={getFontItemCss(font)} key={`font__${index}`} onClick={() => updateFont('family', font)}>
														{font}
													</div>
												)
											})}
										</div>
									</div>
								</Dropdown>
							</div>
							<div className="appearance__item mleft--10 flex--33">
								<label className="text--bold">Size</label>
								<Dropdown dropup={false}>
									<div className="dropdown-trigger btn btn-secondary btn-100">
										{font ? font.size : '14px'}
										<span className="material-icons font--20 vm--align">
											keyboard_arrow_down
										</span>
									</div>
									<div className="dropdown__menu">
										<div className="dropdown__content">
											<div className="dropdown__item" >
												14px
											</div>
											<div className="dropdown__item" >
												15px
											</div>
											<div className="dropdown__item" >
												16px
											</div>
										</div>
									</div>
								</Dropdown>
							</div>
						</div>
					</div>
				}
			</div>				
		</div>
	);
}

export default CampaignAppearance;
