import {useState, useEffect} from 'react';
import DateTimePicker from 'react-datetime-picker';
import TimezoneSelect from 'react-timezone-select';

function CampaignDateSelector({date, setDate, timezone, setTimezone}){
    const [hasDate, setHasDate] = useState(date ? true : false);
    const getMaxDate = () => {
        let d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();
        return new Date(year + 5, month, day);
    }
    const getMinDate = () => {
        let d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();
        return new Date(year - 1, month, day);
    }
	useEffect(() =>{
		if(!hasDate) setDate(null);
	 }, [hasDate])
    return(
        <>
            <div className="campaign__option mtop--20">
                <div className="flex flex--row">
                    <div className={`card__check-option pointer flex--50 ${!hasDate && 'card__check-optionActive'}`} onClick={() => setHasDate(false)}>
                        <div className="card__check-radio">
                            <span className="material-icons">
                                done
                            </span>
                        </div>
                        <div className="material-icons font--30 text--danger">
                            clear
                        </div>
                        No
                    </div>
                    <div className={`card__check-option pointer flex--50 ${hasDate && 'card__check-optionActive'}`} onClick={() => setHasDate(true)}>
                        <div className="card__check-radio">
                            <span className="material-icons">
                                done
                            </span>
                        </div>
                        <div className="material-icons font--30 text--success">
                            check
                        </div>
                        Yes
                    </div>
                </div>
            </div>
            { hasDate &&
                <div className="mtop--20 card__secondary">
                    <label className="text--bold">Please select a date</label>
                    <div className="mtop--10">
                        <DateTimePicker onChange={setDate} value={date ? new Date(date) : null} minDate={getMinDate()} maxDate={getMaxDate()} />
                        {(setTimezone!==undefined) &&
                            <div className="mtop--10">
                                <label className="text--bold font--12">Please also select a timezone</label>
                                <TimezoneSelect className="timezone__select" value={timezone} onChange={setTimezone} />
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}
export default CampaignDateSelector;