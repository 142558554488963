import React, { useState } from 'react';
import PrizeSimulation from './PrizeSimulation';

function GridRow ({i, startDate, endDate, expectedPeople, onChange, onRemove}) {
	return (<>
		<span key={'sD'+i}>startDate:</span>
		<input key={'sDI'+i} value={startDate} onChange={(event)=>onChange(i, 0, event.target.value)} />

		<span key={'eD'+i}>endDate:</span>
		<input key={'eDI'+i} value={endDate} onChange={(event)=>onChange(i, 1, event.target.value)} />

		<span key={'eP'+i}>expectedPeople:</span>
		<input key={'ePI'+i} value={expectedPeople} onChange={(event)=>onChange(i, 2, event.target.value)} />

		<button onClick={()=>onRemove(i)}>Remove</button>
	</>)
}

function PrizeSimulationModal({ currentElement, closeCb }) {
	const [graphType, setGraphType] = useState('stockLeft');
	const [simulationCount, setSimulationCount] = useState(50);

	const [attendance, setAttendance] = useState([
		['2024-07-22 09:00:00', '2024-07-22 23:59:59', 200],
		['2024-07-23 09:00:00', '2024-07-23 23:59:59', 500],
		['2024-07-24 09:00:00', '2024-07-24 23:59:59', 450],
	]);

	const setAttendanceValue = (i, j, value) => {
		attendance[i][j] = value;
		const tmp = [...attendance];
		setAttendance(tmp);
	};
	const addAttendance = () => {
		const tmp = [...attendance, ['', '', 0]];
		setAttendance(tmp);
	};
	const removeAttendance = (index) => {
		setAttendance(attendance.filter((a, i)=>{
			return i!==index;
		}));
	};

	return (
		<div className="modal-overlay active">
			<div className="modal active animate__animated fadeIn modal--sm" style={{width: '95%', maxWidth: '95%', height: '95%', maxHeight: '95%'}}>
				<div className="modal__header">
					<div className="modal__header-title"></div>
					<div className="close-modal modal--x" onClick={()=>closeCb(false)}>
						<svg viewBox="0 0 20 20">
							<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
						</svg>
					</div>
				</div>
				<div className="modal-content">
					<div className="modal__body text--center">
						<div style={{"display":"grid","gridTemplateColumns":"auto 1fr","gap":"5px 10px"}}>
							<span>graphType:</span>
							<select value={graphType} onChange={(event)=>setGraphType(event.target.value)}>
								<option value="percentages">percentages</option>
								<option value="stockLeft">stockLeft</option>
							</select>
							<span>simulationCount:</span><input value={simulationCount} onChange={(event)=>setSimulationCount(event.target.value)} />
						</div>
						<div style={{"display":"grid","gridTemplateColumns":"repeat(7, auto)","gap":"5px 10px"}}>
							{attendance.map(([startDate, endDate, expectedPeople], i)=>{
								return <GridRow i={i} startDate={startDate} endDate={endDate} expectedPeople={expectedPeople} onChange={setAttendanceValue} onRemove={removeAttendance} key={i}/>;
							})}
						</div>
						<div>
							<button onClick={addAttendance}>Add</button>
						</div>
						<div style={{height:'400px', width:'80%'}}>
							<PrizeSimulation
								settings={currentElement}
								graphType={graphType}
								simulationCount={simulationCount}
								attendance={attendance}
							/>
						</div>
					</div>
					<div className="modal__footer text--right ">
						<button className="btn btn-secondary" onClick={()=>closeCb(false)}>Close</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PrizeSimulationModal