import { useState } from "react";
import EditableInput from "../../../../utils/EditableInput";
import ImageUpload from "../../../../utils/ImageUpload";
import ScratchPrizeOptionsModal from './ScratchPrizeOptionsModal';
import ScratchPrizeAdvancedOptions from './ScratchPrizeAdvancedOptions';

function ScratchPrize({ prize, update, deletePrize, currentCampaignId, _updateSubmit, advancedOnModal, locked }) {
    const [showAdvanced, setShowAdvanced] = useState(false);
    const extra = {prize_id: prize.id};
    const compStock = () => {
        return prize.stock ? prize.stock : <span className="font--16">∞</span>
    }
    const autosave = async (key, value, extra) => {
        await update(key, value, extra)
        _updateSubmit();
    }
    const done = () => {
        setShowAdvanced(false);
    }
	return (
		<div className="scratch-prize">
            <div className="scratch-contentPrize">
                <div className="scratch-contentPrizeImage">
                    <ImageUpload extra={extra} campaign_id={currentCampaignId} file={prize.image} item="image" updateAction={autosave} 
                        dimensions={'up to 800 x 800 px'}
                    />
                </div>
                <div className="scratch-contentPrizeText">
                    {locked?
                        <div>{prize?.text}</div>
                        :
                        <EditableInput type="text" extra={extra} withPencil item="text" startValue={prize?.text} updateAction={autosave} />
                    }
                    <div className="text--muted font--12">Stock: {compStock()}</div>
                </div>
                <div className="scratch-contentPrizeActions">
                    <button className="btn btn-transparent btn-sm text--danger" onClick={() => deletePrize(prize)} disabled={locked}>
                        <span className="material-icons font--18">
                            clear
                        </span>
                    </button>
                    <button className="btn btn-transparent btn-sm" onClick={() => setShowAdvanced(!showAdvanced)}>
                        {advancedOnModal &&
                            <span className="material-icons font--22">
                                edit
                            </span>
                        }
                        {!advancedOnModal && 
                            <span className="material-icons font--22">
                                {showAdvanced ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }
                            </span>
                        }
                        
                    </button>
                </div>
            </div>
            {advancedOnModal ?
                <>
                    {showAdvanced &&
                        <ScratchPrizeOptionsModal prize={prize} update={autosave} extra={extra} setModal={setShowAdvanced} save={done} />
                    }
                </>
                :
                <>
                    {showAdvanced &&
                        <ScratchPrizeAdvancedOptions prize={prize} update={autosave} extra={extra} />
                    }
                </>
            }
            
		</div>
	);
}

export default ScratchPrize;
