import { useState, useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import "../../../scss/pages/CampaignModeration.scss";
import {getResponsesLive, getCampaign, changePostStatus} from '../../../firebaseActions.mjs';
import {setCurrentCampaign, addUnMappedResult, deleteUnMappedResult} from "../../../actions/UserActions";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const dateParser = function(timestamp) {
	const tmp = new Date(timestamp);
	let out = null;
	const year = tmp.getFullYear();
	const month = months[tmp.getMonth()];
	const day = tmp.getDate()<10?'0'+tmp.getDate():tmp.getDate();
	const hour = tmp.getHours()<10?'0'+tmp.getHours():tmp.getHours();
	const minute = tmp.getMinutes()<10?'0'+tmp.getMinutes():tmp.getMinutes();

	const today = new Date();
	const yesterday = new Date();
	yesterday.setDate(today.getDate() - 1);
	if( today.getFullYear()===tmp.getFullYear() && today.getMonth()===tmp.getMonth() && today.getDate()===tmp.getDate() ){
		out = hour+':'+minute;
	}else if( yesterday.getFullYear()===tmp.getFullYear() && yesterday.getMonth()===tmp.getMonth() && yesterday.getDate()===tmp.getDate() ){
		out = hour+':'+minute+' Yesterday';
	}else{
		out = hour+':'+minute+' '+month+' '+day+', '+year;
	}
	return out;
}
const statusParser = (status)=>{return status?(status>0?'accepted':'rejected'):'unknown'};

const acceptPost = (campaignId, userId, pageId, elementId, postId) => changePostStatus(campaignId, userId, pageId, elementId, postId, 1);
const rejectPost = (campaignId, userId, pageId, elementId, postId) => changePostStatus(campaignId, userId, pageId, elementId, postId, -1);

function CampaignModeration(props) {
	const dispatch = useDispatch();
	const {currentCampaign, currentCampaignId, unMappedUserResults} = useSelector(state => state.user);
	const [ugcPath, setUgcPath] = useState(null);
	const [inited, setInited] = useState(false);
	const [posts, setPosts] = useState([]);
	// Find UGC
	useEffect(() => {
		if(currentCampaign){
			for(let i=0;i<currentCampaign.pages.length;i++){
				for(let j=0;j<currentCampaign.pages[i].elements.length;j++){
					if(currentCampaign.pages[i].elements[j].type==="ugc"){
						setUgcPath(currentCampaign.pages[i].id+','+currentCampaign.pages[i].elements[j].id);
					}
				}
			}
		}else{
			setUgcPath(null);
		}
	}, [currentCampaign]);
	// Parse unmapped date
	useEffect(() => {
		let posts = [];
		if(ugcPath){
			const [pageId, ugcId] = ugcPath.split(',');
			for(const userId in unMappedUserResults){
				if(unMappedUserResults[userId][pageId]?.elements[ugcId]){
					const tmp = Object.values(unMappedUserResults[userId][pageId].elements[ugcId].value);
					for(let i=0;i<tmp.length;i++){
						tmp[i].userId = userId;
						tmp[i].pageId = pageId;
						tmp[i].elementId = ugcId;
					}
					posts = posts.concat(tmp)
				}
			}
			posts.sort((a,b)=>b.timestamp-a.timestamp);
		}
		setPosts(posts)
	}, [ugcPath, unMappedUserResults])
	useEffect( () => {
		getCampaign(currentCampaignId)
		.then( data => {
			dispatch(setCurrentCampaign({...data.campaign, name: data.campaignMeta.name}))
			getResponsesLive(currentCampaignId, (id, data) => {
				dispatch(addUnMappedResult({id, data}))
			}, (id, data) => {
				dispatch(addUnMappedResult({id, data}))
			}, (id) => {
				dispatch(deleteUnMappedResult({id}))
			})
		})
		.finally(() => setInited(true))
	}, [currentCampaignId]);

	return (
		<div className="CampaignModeration">
			{inited && currentCampaign &&
				posts.map(({id, caption, firstName, lastName, image, timestamp, status, userId, pageId, elementId})=>{
					let displayName = (firstName||"");
					if (displayName) displayName += " ";
					displayName += (lastName||"");
					return (<div key={id} className={"CampaignModeration__item "+statusParser(status)}>
						<div>
							{image && <div className="CampaignModeration__itemImage"><img src={image[image.length-1].src} alt=""/></div>}
							{caption && <div className="CampaignModeration__itemCaption" title={caption}>{caption}</div>}
							<div className="CampaignModeration__itemFooter">
								{displayName && <div className="CampaignModeration__itemDisplayName">{displayName}</div>}
								{timestamp && <div className="CampaignModeration__itemTimestamp">{dateParser(timestamp)}</div>}
							</div>
							<div className="CampaignModeration__itemControls">
								{!(status<0) && <button onClick={()=>rejectPost(currentCampaignId, userId, pageId, elementId, id)} className="material-icons text--danger">close</button>}
								{!(status>0) && <button onClick={()=>acceptPost(currentCampaignId, userId, pageId, elementId, id)} className="material-icons text--success">check</button>}
							</div>
						</div>
					</div>);
				})
			}
		</div>
	);
}

export default CampaignModeration;
