import { useState } from "react";
import ColorPallete from "../../../utils/ColorPallete";
import EditableInput from "../../../utils/EditableInput";
import {useDispatch, useSelector} from 'react-redux';
import {updateElement} from "../../../utils/UpdateFunctions";
import { editElement } from "../../../../actions/UserActions";

function OpenEndElementOptions(props) {
	const dispatch = useDispatch();
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const {background, marginTop} = elementCopy;
	const update = (key, value) => {
		if(key == 'marginTop') value += 'px';
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	return (
		<div>
			<div className="campaign__option">
				<label className="text--bold">Top Margin (pixels)</label>
				<div className="font--12 text--muted">The space the element has from the top</div>
				<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Background color</label>
				<div className="text--muted font--12">Preferable if you have a background image and the text is not visible</div>
				<ColorPallete item="background" value={background} type={'rgba'} updateAction={update} />
			</div>
		</div>
	);
}

export default OpenEndElementOptions;
