import {useState} from 'react'
import { useDispatch } from 'react-redux';
import { addOrganization } from '../../../firebaseActions.mjs'
import { Notification } from '../../utils/Misc';
import { storeAddOrganization } from '../../../actions/OrganizationsActions'

function AddOrganization({user}) {
	const dispatch = useDispatch();
	const [addModal, setAddModal] = useState(false);
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(null);
	const add = () => {
		setLoading(true)
		addOrganization(name)
		.then( data => {
			dispatch(storeAddOrganization(data))
			setAddModal(false);
			setName('')
		})
		.catch( e => {
			console.error(e)
			Notification('An error occured, Please try again later.', 'error');
		})
		.finally(() => setLoading(false))
	}
	return (
		<div>
			<div className="text--center">
				<button className="btn btn-primary btn-md" onClick={() => setAddModal(true)} disabled={addModal}>
					<span className="material-icons">
						add
					</span>
					Add Organization
				</button>
			</div>
			{addModal && 
				<div className="modal-overlay active">
					<div className="modal active animate__animated fadeIn modal--sm">
						<div className="modal__header">
							<div className="modal__header-title text--bold font--20 mtop--10 mleft--10">
								Create a new organization
							</div>
							<div className="close-modal modal--x" onClick={() => { setAddModal(false) }}>
								<svg viewBox="0 0 20 20">
									<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
								</svg>
							</div>
						</div>
						<div className="modal-content">
							<div className="modal__body">
								<div className="profile__add-role animate__animated animate__fadeIn">
									<div className="form-group mtop--20">
										<label>Name</label>
										<input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Type here..." />
									</div>
									<div className="form-group mtop--20">
										<button className="btn btn-primary btn-100" disabled={!name || loading} onClick={add}>
											{!loading && 
												<span>
													<span className="material-icons">add</span>
													Add
												</span>
											}
											{loading && <span className="loader loader__sm"></span>}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	)
}

export default AddOrganization