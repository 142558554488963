import { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import EditableInput from "../../../utils/EditableInput";
import {updateElement} from "../../../utils/UpdateFunctions";
import { editElement } from "../../../../actions/UserActions";

function SpacerElementOptions(props) {
	const dispatch = useDispatch();
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const {size} = elementCopy;
	const update = (key, value) => {
		if(key == 'size') value += 'px';
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	};
	return (
		<div>
			<div className="campaign__option">
				<label className="text--bold">Height (pixels)</label>
				<div className="font--12 text--muted"></div>
				<EditableInput item="size" type="number" startValue={size.substring(0, size.length - 2)} updateAction={update} placeholder="Type here..." />
			</div>
		</div>
	);
}

export default SpacerElementOptions;