import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
	acceptRequest,
	rejectRequest,
	getOrganizations
} from '../../../firebaseActions.mjs'
import { storeEditIncomingRoleRequest, storeAddOrganization, storeClearOrganizations } from '../../../actions/OrganizationsActions'
import placeholderImage from '../../../images/undraw_pull_request_re_aqi5.svg'

const IncomingRoleRequests = ({ user }) => {
	const dispatch = useDispatch()
	const [calculatedRequests, setCalclulatedResults] = useState([])
	const [loading, setLoading] = useState(null)
	const state = useSelector(state => state.organizations);

	const edit = (id, status) => {
		setLoading(id)
		let action = null;
		switch (status) {
			case 'accepted':
				action = acceptRequest(id)
				break;
			case 'rejected':
				action = rejectRequest(id)
				break;
			default:
				break;
		}
		if(action){
			action.then((data) => {
				const f = state.incomingRequests.find(r => r.id == id)
				if(f) f.status = status
				dispatch(storeEditIncomingRoleRequest(f))
				if(status === 'accepted'){
					dispatch(storeClearOrganizations())
					getOrganizations(user.uid)
					.then((data) => {
						if (data) {
							data.forEach(organization => {
								dispatch(storeAddOrganization(organization))
							})
						}
						setTimeout(()=>window.location.reload(), 500);
					})
					.catch(() => {
						console.error('here')
					})
				}
			})
			.finally(() => setLoading(null))
		}
	}
	useEffect(() => {
		setCalclulatedResults(
			state.incomingRequests.sort((a, b) => {
				const a1 = moment(a.timestamp.seconds * 1000).format("DD/MM/YYYY HH:mm");
				const b1 = moment(b.timestamp.seconds * 1000).format("DD/MM/YYYY HH:mm");
				return new Date(b1) - new Date(a1)
			})
		)
	}, [state.incomingRequests])
	const getStatus = (request) => {
		const o = {
			'pending': 'text--primary',
			'accepted': 'text--success',
			'rejected': 'text--danger',
			'canceled': 'text--warning',
		}
		return <div className={`font--11 ${o[request.status]}`}>{request.status}</div>
	}
	return (
		<div>
			<label className="text--bold">Requests</label>
			<div className="mtop--20">
				<label className="text--bold text--primary font--14">Incoming</label>
				<div className="font--12 text--muted">
					Requests from other users that want you to be a part of their organization.
				</div>
				<div className="card__secondary">
					{ calculatedRequests.length > 0 && 
						<div className="mtop--20">
							<div className="mtop--10">
								<table>
									<thead>
										<tr>
											<td className="text--bold requests__email">User</td>
											{/* <td className="text--bold">Gives access to</td> */}
											{/* <td className="text--bold requests__email">On behalf of</td> */}
											<td className="text--bold">Role</td>
											<td className="text--bold">Status</td>
											<td className="text--bold">Date</td>
											<td className="text--bold"></td>
										</tr>
									</thead>
									<tbody>
										{ calculatedRequests.map((request, idx) => {
											return (
												<tr key={`incoming__request${idx}`} className={`role__request-${request.status}`}>
													<td className="font--12 requests__email">
														{request?.granter?.email}
													</td>
													{/* <td className="font--12">
													{request?.grantee?.email}
													</td> */}
													{/* <td className="font--12 requests__email">
														{request?.onBehalf?.email == request?.granter?.email ? 'Himself' : request?.onBehalf?.email}
													</td> */}
													<td className="font--12 capitalize">
														{request?.role}
													</td>
													<td className="font--12 capitalize">
														{getStatus(request)}
													</td>
													<td className="font--12">
														{moment(request.timestamp.seconds * 1000).format("DD/MM/YYYY HH:mm")}
													</td>
													<td>
														{loading != request.id ?
															<>
																{request.status == 'pending' && <button className="btn btn-danger-soft btn-sm" onClick={() => edit(request.id, 'rejected')}>Reject</button>}
																{request.status == 'pending' && <button className="btn btn-success-pastel  btn-sm" onClick={() => edit(request.id, 'accepted')}>Accept</button>}
															</> : ''
														}
														{loading == request.id && <span className="loader loader__sm"></span>}
													</td>
												</tr>
											)
										})}								
									</tbody>
								</table>
							</div>
						</div>
					}
					{ calculatedRequests.length == 0 &&
						<div className="text--center">
							<img src={placeholderImage} width="200" />
							<h3>No <b className='text--primary'>Incoming</b> Requests yet</h3>
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default IncomingRoleRequests								