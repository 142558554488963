import {useSelector} from 'react-redux';
import "../../../scss/partials/topBar.scss";
import ThemeSwitch from "../../utils/ThemeSwitch";
import Profile from "../Profile/Profile";

function TopBarList() {
	const user = useSelector(state => state.user.user);
	return (
		<div className="top__bar">
			<div className="logo logo__campaign-list"></div>
			{ user &&
				<div className="top__bar-user float--right flex flex--row">
					<div className="text--right">
						<ThemeSwitch />
					</div>
					<Profile user={user} />
				</div>
			}
		</div>
	);
}

export default TopBarList;
