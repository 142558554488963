import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
	getOrganizations,
	getParsedToken,
} from '../../firebaseActions.mjs';
import { storeSetOrganizations } from '../../actions/OrganizationsActions'
import OrganizationSettingsModal from '../pages/Profile/OrganizationSettingsModal.jsx';

function OrganizationsDropdown({ user, value, setValue, isSidebar }) {
	const dispatch = useDispatch();
	const [selectedOrganization, setSelectedOrganization] = useState(value ? value : null)
	const {organizations} = useSelector((state) => state.organizations)
	const [organizationModal, setOrganizationModal] = useState(null);
	let hideHome = getParsedToken()?.hideHome;
	if(hideHome && !selectedOrganization && organizations?.length){
		setSelectedOrganization(organizations[0]);
	}
	const dropdownDisplayLabel = () => {
		if (!selectedOrganization) return 'Home'
		return selectedOrganization.data.name
	}
	useEffect(() => {
		if(setValue){
			if (selectedOrganization !== value) setValue(selectedOrganization)
		}
	}, [selectedOrganization])
	useEffect(() => {
		getOrganizations(user.uid)
		.then((data) => {
			if (data) {
				const organizations = data.map( (organization) => {
					return {
						id: organization.id,
						data: organization.data
					}
				})
				dispatch(storeSetOrganizations(organizations));
			}
		})
	}, [])
	return (
		<>	
			<div>
				{!hideHome&&<div className={`campaign__list-accountsItem ${dropdownDisplayLabel() === 'Home' && 'campaign__list-accountsItemActive'}`} onClick={() => setSelectedOrganization(null)}>
					<div className="campaign__list-accountsItemText">
						<span className="material-icons font--20 vm--align mright--10"> home </span>
						Home
					</div>
				</div>}
				{organizations.map((organization, index) => {
					return (
						<div className={`campaign__list-accountsItem ${dropdownDisplayLabel() == organization.data.name && 'campaign__list-accountsItemActive'}`} key={`account__${index}`} onClick={() => setSelectedOrganization(organization)}>
							<div className="campaign__list-accountsItemText">
								<span className="material-icons font--20 vm--align mright--10"> business </span>
								{organization?.data?.name}
								<span className="btn btn-transparent material-icons campaign__list-accountsItemOptions" onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setOrganizationModal(organization)
								}}>
									settings
								</span>
							</div>
						</div>
					)
				})}
			</div>
			{ organizationModal && <OrganizationSettingsModal closeCb={setOrganizationModal} organization={organizationModal} />}
		</>
	)
}
export default OrganizationsDropdown