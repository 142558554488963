import { useState, useMemo, useRef } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

function DetailedResultsTable({total, header, rows, searchTerm=null}) {
    const DEFAULTSIZE = 150;
	const [newPageSize, setNewPageSize] = useState(DEFAULTSIZE);
	const prevPageSize = useRef(0);
	
	const visibleRows = useMemo(() => {
		const l = [...rows];
		const rowsToLoad = newPageSize > l.length ? l.length : newPageSize;
		const out = l.splice(0, rowsToLoad);
		prevPageSize.current = out.length;
		return out;
	}, [rows, newPageSize, prevPageSize])
	
	const loadMore = () => {
		setNewPageSize(visibleRows.length + DEFAULTSIZE);
	}

	const cantLoadMore = () => {
		return searchTerm || (visibleRows.length >= rows.length);
	}
    return (
        <>
            <div className="total__results">
                <span className="material-icons">
                    how_to_vote
                </span>
                <span className='vm--align mtop--5 animate__animated animate__tada'>
                    {total}
                    <div className="font--15 text--muted" style={{marginTop: '-15px'}}>
                        Votes
                    </div>
                </span>
            </div>
            <Table>
                <Thead>
                    <Tr style={{ position: 'sticky', top: '0' }}>
                        <Th style={{ width: '70px' }}>#</Th>
                        {header.map((cell, index) => {
                            return <Th key={`header__${index}`}>{cell.value}</Th>
                        })}
                    </Tr>
                </Thead>
                <Tbody>
                    {visibleRows.map((row, index) => {
                        return (
                            <Tr key={`row__${index}`}>
                                <Td className="text--bold">{index + 1}</Td>
                                {
                                    Object.values(row).map((cell, i) => {
                                        return <Td style={{overflow: 'hidden', textOverflow: 'ellipsis'}} title={cell} key={`option__${i}`}>{cell}</Td>
                                    })
                                }
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
            <div className="text--center mtop--20">
				<button className="btn btn-primary" onClick={loadMore} disabled={cantLoadMore()}>
					Load more
					<span className="material-icons">expand_circle_down</span>
				</button>
			</div>
        </>
    )
}

export default DetailedResultsTable