import { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import ColorPallete from "../../../utils/ColorPallete";
import RichText from "../../../utils/RichText";
import EditableInput from "../../../utils/EditableInput";
import {genUUID, Notification} from "../../../utils/Misc";
import {updateElement} from "../../../utils/UpdateFunctions";
import { editElement } from "../../../../actions/UserActions";
import imgChoiceRow from '../../../../images/imageChoiceRow.png';
import imgChoiceColumn from '../../../../images/imageChoiceColumn.png';
import imageChoiceRowTextBelow from '../../../../images/imageChoiceRowTextBelow.png';
import imageChoiceRowTextAbove from '../../../../images/imageChoiceRowTextAbove.png';
import imageChoiceColTextBelow from '../../../../images/imageChoiceColTextBelow.png';
import imageChoiceColTextAbove from '../../../../images/imageChoiceColTextAbove.png';
import ImageUpload from "../../../utils/ImageUpload";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import '../../../../scss/pages/Poll.scss'

function ImageChoiceElementOptions() {
	const dispatch = useDispatch();
	const {currentCampaignId, currentPage, currentElement} = useSelector(state => state.user);
	const [elementCopy, setElementCopy] = useState(JSON.parse(JSON.stringify(currentElement)));
	const { background, question, marginTop, textColor, options, min, max, layout, textPosition } = elementCopy;
	const [dragLoader, setDragLoader] = useState(false);

	const update = (key, value) => {
		if(key == 'marginTop') value += 'px';
		elementCopy[key] = value;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const updateOption = (key, value, extra) => {
		for (let i = 0; i < options.length; i++) {
			if(options[i]['id'] == extra.option_id){
				elementCopy.options[i][key]  = value;
			}
		}
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const addOption = () => {
		const d = {
			'id': genUUID(),
			'text': 'New Option',
			'showText': true,
			'image': null
		}
		elementCopy.options.push(d);
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const deleteOption = option_id => {
		if (elementCopy.options.length == 1) {
			Notification('There should be at least one option in the image choice')
			return
		}
		const o = elementCopy.options.filter( current => current.id !== option_id )
		elementCopy.options = o;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
	}
	const computeTextPositionImage = target => {
		if(layout == 'row'){
			return target == 'above' ? imageChoiceRowTextAbove : imageChoiceRowTextBelow
		}else{
			return target == 'above' ? imageChoiceColTextAbove : imageChoiceColTextBelow
		}
	}
	const onSortEnd = (data) => {
		setDragLoader(true);
		const toBeMoved = options[data.oldIndex];
		let filteredOptions = options.filter((option) => option.id != toBeMoved.id)
		filteredOptions.splice(data.newIndex, 0, toBeMoved);
		elementCopy['options'] = filteredOptions;
		updateElement(currentCampaignId, currentPage, elementCopy.id, elementCopy, currentElement, setElementCopy, dispatch, editElement)
		setTimeout(() => {
			setDragLoader(false);
		}, 1000)
	}
	const DragHandle = SortableHandle(() => <span className="material-icons font--25 item pointer mtop--10">drag_indicator</span>);
	const SortableItem = SortableElement(({ option }) => (
		<li style={{ width: '100%' }}>
			<div className="poll__option">
				<DragHandle />
				<div className="poll__option-img">
					<ImageUpload extra={{ option_id: option.id }} campaign_id={currentCampaignId} file={option.image} item="image" updateAction={updateOption} />
				</div>
				<div className="poll__option-text">
					<div className="flex flex--row">
						<div>
							<EditableInput extra={{ option_id: option.id }} item="text" type="text" startValue={option.text} updateAction={updateOption} placeholder={'Type here...'} />
						</div>
						{ option?.showText ?
							<span className="material-icons pointer font--25 mleft--10 mtop--10" onClick={() => updateOption('showText', false, { option_id: option.id })}>
								visibility
							</span>
							: 
							<span className="material-icons pointer font--25 mleft--10 mtop--10" onClick={() => updateOption('showText', true,{ option_id: option.id})}>
								visibility_off
							</span>
						}
					</div>
					<span className="material-icons font--25 text--danger mtop--10 pointer poll__option-delete" onClick={() => deleteOption(option.id)}>
						delete
					</span>
				</div>
			</div>
		</li>
	));
	const SortableList = SortableContainer(({ items }) => {
		return (
			<ul className="sortable__container sortable__inline">
				{
					items.map((value, index) => (
						<SortableItem key={`imgChoiceOption_${index}`} i={index} index={index} option={value} />
					))
				}
			</ul>
		);
	});
	return (
		<div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Question</label>
				<RichText item={'question'} value={question} updateAction={update} />
			</div>
			<div className="campaign__option mtop--15">
				<label className="text--bold">Options</label>
				<div className="mtop--15 image__choice-options">
					{dragLoader && <div className="page__selector-loader text--center"><span className="loader"></span></div>}
					{!dragLoader && <SortableList axis="y" useDragHandle items={options} onSortEnd={onSortEnd} />}
					<button className="btn btn-primary-soft btn-100 mtop--10" onClick={() => addOption()}>
						<span className="material-icons font--20 vm--align">add</span>
						Add Option
					</button>
				</div>
				<div className="flex flex--row mtop--20">
					<div className="flex--50">
						<label className="text--bold">Minimum Selections</label>
						<EditableInput type="number" item="min" max={max} startValue={min} updateAction={update} />
					</div>
					<div className="flex--50 mleft--15">
						<label className="text--bold">Maximum Selections</label>
						<EditableInput type="number" item="max" max={options.length} startValue={max} updateAction={update} />
					</div>
				</div>
			</div>
			<div className="campaign__option mtop--20">
				<label className="text--bold">Answers layout</label>
				<div className="flex flex--row flex--wrap">
					<div className={`card__check-option ${layout == 'row' && 'card__check-optionActive'}`} onClick={() => update('layout', 'row')}>
						<div className="card__check-radio">
							<span className="material-icons">
								done
							</span>
						</div>
						<img src={imgChoiceRow} className="img__responsive img__rounded"/>
					</div>
					<div className={`card__check-option ${layout == 'column' && 'card__check-optionActive'}`} onClick={() => update('layout', 'column')}>
						<div className="card__check-radio">
							<span className="material-icons">
								done
							</span>
						</div>
						<img src={imgChoiceColumn} className="img__responsive img__rounded"/>
					</div>
				</div>

				<label className="text--bold mtop--20">Text position</label>
				<div className="flex flex--row flex--wrap mtop--10">
					<div className={`card__check-option ${textPosition == 'above' && 'card__check-optionActive'}`} onClick={() => update('textPosition', 'above')}>
						<div className="card__check-radio">
							<span className="material-icons">
								done
							</span>
						</div>
						<img src={computeTextPositionImage('above')} className="img__responsive img__rounded" />
					</div>
					<div className={`card__check-option ${textPosition == 'below' && 'card__check-optionActive'}`} onClick={() => update('textPosition', 'below')}>
						<div className="card__check-radio">
							<span className="material-icons">
								done
							</span>
						</div>
						<img src={computeTextPositionImage('below')} className="img__responsive img__rounded" />
					</div>
				</div>
			</div>
			<div className="campaign__option mtop--20">
				<label className="text--bold">Top Margin (pixels)</label>
				<div className="font--12 text--muted">The space the element has from the top</div>
				<EditableInput item="marginTop" type="number" startValue={marginTop.substring(0, marginTop.length - 2)} updateAction={update} placeholder="0" />
			</div>
			<div className="flex flex--row flex--wrap mtop--20">
				<div className="campaign__option campaign__option-50">
					<label className="text--bold">Text color</label>
					<div className="campaign__option-act">
						<ColorPallete item="textColor" value={textColor} type={'hex'} updateAction={update} />
					</div>
				</div>
				<div className="campaign__option campaign__option-50 mleft--5">
					<label className="text--bold">Background color</label>
					<div className="campaign__option-act">
						<ColorPallete item="background" value={background} type={'rgba'} updateAction={update} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default ImageChoiceElementOptions;
