import IncomingRoleRequests from "./IncomingRoleRequests"

function RequestsModal({ user, closeCb }) {
	const close = () => {
		localStorage.setItem('sportovivo_hasSeenRequestsModal', true)
		closeCb(false)
	}
	return (
		<div className="modal-overlay active">
			<div className="modal active animate__animated fadeIn modal--xl">
				<div className="modal__header">
					<div className="modal__header-title">
						<h3>You have some organization requests</h3>
					</div>
					<div className="close-modal modal--x" onClick={close}>
						<svg viewBox="0 0 20 20">
							<path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
						</svg>
					</div>
				</div>
				<div className="modal-content">
					<div className="modal__body">
						<IncomingRoleRequests user={user} />
						<div className="mtop--20 text--center text--success">
							<span className="material-icons font--30">info</span>
							<h4>You can always check your requests on the upper right corner profile icon!</h4>
						</div>
					</div>
					<div className="modal__footer text--right">
						<button className="btn btn-primary" onClick={close}>Done</button>
					</div>
				</div>
			</div>
		</div>	
	)
}

export default RequestsModal